import { Component } from '@angular/core'

@Component({
	selector: 'hum-collection-card-skeleton',
	template: `
		<div class="header">
			<div skeleton="light" width="40%" height="20px"></div>
		</div>
		<div class="content">
			<div class="articles">
				<div
					*ngFor="let a of articles"
					class="article"
					skeleton="dark"
					radius="8px"
					right="8px"
					width="148px"
					height="172px"
				></div>
			</div>
		</div>
	`,
	styleUrls: ['./collection-card.component.scss'],
})
export class CollectionCardSkeletonComponent {
	constructor() {}

	articles = Array.from({ length: 5 })
}
