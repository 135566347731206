<hum-top-gradient></hum-top-gradient>
<ion-content [fullscreen]="true">
	<div class="page hum-content">
		<div class="top">
			<div class="title">
				{{ screen?.title }}
			</div>
			<div class="subtitle">
				{{ screen?.subtitle }}
			</div>
			<div class="selector">
				<hum-selector-item
					class="selector-item"
					*ngFor="let level of screen?.choices"
					[text]="level.display_text"
					(click)="onLevelClick(level.id)"
					[checked]="isLevelActive(level.id)"
				>
				</hum-selector-item>
			</div>
		</div>
	</div>
	<div class="footer">
		<hum-button [disabled]="!isFormValid" class="button hum-content" (click)="navigateNext()">
			{{ screen?.button_text }}
		</hum-button>
	</div>
	<hum-bottom-gradient></hum-bottom-gradient>
</ion-content>
