import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
	providedIn: 'root',
})
export class PriceFormatService {
	constructor(private translate: TranslateService) {}

	formatPriceRaw(price: number, currencyCode: string) {
		return new Intl.NumberFormat(this.translate.currentLang, {
			style: 'currency',
			currency: currencyCode,
			minimumFractionDigits: 0,
		}).formatToParts(price)
	}

	formatPrice(price: number, currencyCode: string) {
		return this.formatPriceRaw(price, currencyCode)
			.map(p => p.value)
			.join('')
	}

	currencyByCode(currencyCode: string) {
		return this.formatPriceRaw(0, currencyCode).find(v => v.type === 'currency').value
	}
}
