import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { IBlurable, IFocusable } from 'app/core/interfaces'
import { AUTO_FOCUSABLE } from 'app/theme/directives'

@Component({
	selector: 'hum-search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SearchInputComponent),
			multi: true,
		},
		{
			provide: AUTO_FOCUSABLE,
			useExisting: forwardRef(() => SearchInputComponent),
		},
	],
})
export class SearchInputComponent implements OnInit, ControlValueAccessor, IBlurable, IFocusable {
	@ViewChild('input') input: ElementRef<HTMLInputElement>

	@Input() placeholder: string = ''
	@Input() loading = false

	@Output() submit = new EventEmitter()

	enterkeyhint = 'search'
	inputmode = 'search'

	value: string
	private onChange = (value: any) => {}
	private onTouched = () => {}
	constructor() {}

	ngOnInit(): void {}

	onSubmit(event: KeyboardEvent) {
		const key = event.key || event.keyCode
		if (key !== 'Enter' && key !== 13) return

		this.submit.emit()
	}

	blur() {
		this.input.nativeElement.blur()
	}

	setFocus(): void {
		this.input.nativeElement.focus()
	}

	registerOnChange(fn: any) {
		this.onChange = fn
	}

	registerOnTouched(fn: () => {}): void {
		this.onTouched = fn
	}

	writeValue(outsideValue: string) {
		this.value = outsideValue
	}

	updateValue(insideValue: string) {
		this.value = insideValue
		this.onChange(insideValue)
		this.onTouched()
	}
}
