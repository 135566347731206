import { map, switchMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router'
import { StorageService } from 'app/core/services'
import { Observable } from 'rxjs'
import { OnboardingService } from '../services/onboarding'

@Injectable({
	providedIn: 'root',
})
export class ContinueGuard implements CanActivate {
	constructor(
		private storage: StorageService,
		private onboardingService: OnboardingService,
		private router: Router,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (state.url !== this.onboardingService.rootUrl) return true

		return this.storage.get<string>('unfinished_step').pipe(
			switchMap(url => {
				console.debug(`[ContinueGuard] — unfinished_step: ${url}`)
				let urlTree: UrlTree

				if (url && this.onboardingService.isScreenExists(url)) {
					urlTree = this.router.createUrlTree([url])
				} else {
					urlTree = this.onboardingService.getFirstAvailableScreen()
				}

				return this.router.navigateByUrl(urlTree, { replaceUrl: true })
			}),
		)
	}

	canActivateChild() {
		return true
	}
}
