import {
	City,
	ConfigService,
	RemoteConfigService,
	StorageService,
	UsersService,
} from 'app/core/services'
import { Route, Router, UrlTree } from '@angular/router'
import { first, map, tap } from 'rxjs/operators'
import { BehaviorSubject, forkJoin, of } from 'rxjs'
import { ApiService } from 'app/core/api/api.service'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Capacitor } from '@capacitor/core'
import { DEFAULT_DATE } from 'app/contants'
import { formatISO9075, isValid, parse, parseISO } from 'date-fns'
import { isNative, toISO8601 } from 'app/core/utils'
import { determine } from 'jstimezonedetect'
import { onboardingRoutes } from '../../onboarding-routing.module'
import isEqual from 'lodash/isEqual'
import {
	DEFAULT_STATIC_URL,
	getDefaultFinishScreens,
	getDefaultIntroScreens,
	getDefaultRegistrationScreens,
	requiredRegistrationScreens,
} from './constants'

// TODO refactoring
// TODO remove stages

// TODO separate profile utils to profile service

@Injectable({
	providedIn: 'root',
})
export class OnboardingService {
	readonly rootUrl = '/onboarding'

	stage_order: Array<OnboardingStage> = ['intro', 'registration', 'finish']
	routes: Record<OnboardingStage, Route>
	screens: Array<{ stage: OnboardingStage; screen: string }>

	constructor(
		private api: ApiService,
		private storage: StorageService,
		private router: Router,
		private remoteConfigService: RemoteConfigService,
		private translate: TranslateService,
		private usersService: UsersService,
		private configService: ConfigService,
	) {}

	profile: Partial<RegistrationProfile> = {}

	intro_data: BehaviorSubject<null | Onboarding> = new BehaviorSubject(null)
	info: BehaviorSubject<null | OnboardingProfileInfo> = new BehaviorSubject(null)
	info_profile = null

	initialized = false

	isCompleted() {
		return this.storage.get<boolean>('onboarding').pipe(map(value => !!value))
	}

	get current_screen() {
		const { screen } = this.parseUrl(this.router.url)
		return screen
	}
	get current_stage(): OnboardingStage {
		const { stage } = this.parseUrl(this.router.url)
		return stage
	}

	async initOnboarding() {
		await this.initializeRouting()
		await Promise.all([
			this.initializeRemoteConfig(),
			this.fetchOnboardingData()
				.toPromise()
				.catch(() => null),
			this.restoreRegistration(),
		])

		console.debug(`[onboardingService] — initOnboarding. load profile`)
		const is_bodygraph_calculated = await this.usersService.hasBodygraph().pipe(first()).toPromise()
		console.debug(
			`[onboardingService] — initOnboarding. is_bodygraph_calculated=${is_bodygraph_calculated}`,
		)

		if (is_bodygraph_calculated) {
			console.debug(`[onboardingService] — initOnboarding. Try load intro`)
			await this.fetchIntro()
			console.debug(`[onboardingService] — initOnboarding. Intro loaded`)
		}
		this.initialized = true
	}

	async navigateNext() {
		const route = this.getNextRoute()
		if (route !== null) {
			return this.router.navigateByUrl(route)
		}

		await this.completeOnboarding()

		return this.router.navigateByUrl('/subscription')
	}

	updateCurrentRoute(url: string) {
		if (url === this.rootUrl) return null

		this.saveStep()
	}

	getNextRoute(): UrlTree | null {
		let current_index = this.screens.findIndex(({ screen }) => screen === this.current_screen)

		if (current_index + 1 < this.screens.length) {
			const { stage, screen } = this.screens[current_index + 1]
			return this.createUrlTree(stage, screen)
		}

		return null
	}

	getPrevRoute(): UrlTree | null {
		let current_index = this.screens.findIndex(({ screen }) => screen === this.current_screen)

		if (current_index - 1 >= 0) {
			const { stage, screen } = this.screens[current_index - 1]
			return this.createUrlTree(stage, screen)
		}

		return null
	}

	getFirstStageScreenRoute(stage: OnboardingStage) {
		return this.createUrlTree(stage, this.getStageScreens(stage)[0].screen)
	}

	getFirstAvailableScreen() {
		const stage = this.stage_order.find(s => this.getStageScreens(s).length > 0)
		return this.getFirstStageScreenRoute(stage)
	}

	async updateProfile(profile: Partial<RegistrationProfile>) {
		console.debug(`[onboardingService] — updateProfile.`, profile)

		this.profile = {
			...this.profile,
			...profile,
		}

		const profileToSave = this.serializeProfile(this.profile)

		console.debug(`[onboardingService] — updateProfile. saveProfile:`, profileToSave)

		await this.storage.set('registration_info', profileToSave).toPromise()
	}

	async fetchIntro() {
		const profile = this.convertProfileForBackend(this.profile)

		return this.api
			.post<OnboardingProfileInfo>(['onboarding', 'intro'], { ...profile })
			.pipe(
				tap(info => {
					this.info.next(info)
					this.info_profile = this.profile
				}),
			)
			.toPromise()
	}

	needFetchIntro() {
		if (!this.info.getValue()) return true

		return !isEqual(this.profile, this.info_profile)
	}

	getProgress() {
		let stage_screens = this.getStageScreens(this.current_stage)
		stage_screens = stage_screens.filter(
			s => !this.findScreen(s.stage, s.screen).data.progress_exclude,
		)
		let total = stage_screens.length
		let current = stage_screens.findIndex(s => s.screen === this.current_screen) + 1

		return { current, total }
	}

	videoLangs = ['en', 'es']

	getVideo(video: string) {
		const current = this.translate.currentLang
		const defaultLang = this.translate.defaultLang

		const static_url = this.intro_data.getValue()?.static_url || DEFAULT_STATIC_URL

		const lang = this.videoLangs.includes(current) ? current : defaultLang

		return `${static_url}/onboarding/tutors-videos/${lang}/${video}.mp4` //  `/assets/videos/${lang}/${video}.mp4`
	}

	async completeOnboarding() {
		return forkJoin([
			this.storage.clear('unfinished_step'),
			this.storage.set('onboarding', true),
			this.storage.set('registration', true),
		]).toPromise()
	}

	async uploadProfile() {
		const profile = this.convertProfileForBackend(this.profile)

		await this.usersService.updateProfile(profile).toPromise()
	}

	private async initializeRemoteConfig() {
		const [intro_screens, registration_screens, finish_screens] = await Promise.all([
			this.remoteConfigService.getJSON<any[] | null>('onboarding_intro_screens').catch(() => null),
			this.remoteConfigService
				.getJSON<any[] | null>('onboarding_registration_screens')
				.catch(() => null),
			this.remoteConfigService.getJSON<any[] | null>('onboarding_finish_screens').catch(() => null),
		])

		const onboardingGroup =
			isNative || this.configService.webOnboardingGroup === 'ios' ? 'ios' : 'web'

		let stages = {
			intro: intro_screens ?? getDefaultIntroScreens(onboardingGroup),
			registration: registration_screens ?? getDefaultRegistrationScreens(onboardingGroup),
			finish: finish_screens ?? getDefaultFinishScreens(onboardingGroup),
		}
		// Заполняем недостающие упущенные экраны, если такие есть
		if (requiredRegistrationScreens.some(s => !stages.registration.includes(s))) {
			const missingScreens = requiredRegistrationScreens.filter(
				s => !stages.registration.includes(s),
			)
			stages.registration.push(...missingScreens)
		}
		this.screens = this.getFlatScreensList(stages)
	}

	private async initializeRouting() {
		const routerConfig = this.getRouterConfig()

		const findStage = stage => routerConfig.children.find(r => r.data?.stage === stage)!

		this.routes = {
			intro: findStage('intro'),
			registration: findStage('registration'),
			finish: findStage('finish'),
		}
	}

	isScreenExists(url: string) {
		const { stage, screen } = this.parseUrl(url)
		return this.screens.some(s => s.screen === screen && s.stage === stage)
	}

	private parseUrl(url: string) {
		let urlArray = url.split(/[?#]/)[0].split('/')

		const screenPath = urlArray.pop()
		const stagePath = urlArray.pop()

		const stage = this.getRouterConfig().children.find(p => p.path === stagePath)

		const screen = stage.children.find(s => s.path === screenPath)

		return { stage: stage.data.stage, screen: screen.data.screen_name }
	}

	private getFlatScreensList(_stages: Record<OnboardingStage, string[]>) {
		let stages = Object.keys(_stages).sort(
			(a, b) =>
				this.stage_order.indexOf(a as OnboardingStage) -
				this.stage_order.indexOf(b as OnboardingStage),
		)

		let flatScreens = stages
			.reduce((all, stage) => [...all, ..._stages[stage].map(screen => ({ stage, screen }))], [])
			.filter(({ stage, screen }) => !!this.findScreen(stage, screen))
			.filter(({ stage, screen }) => {
				const config = this.findScreen(stage, screen)

				if (
					config.data.platforms &&
					Array.isArray(config.data.platforms) &&
					!config.data.platforms.includes(Capacitor.getPlatform())
				)
					return false

				return true
			})

		return flatScreens
	}

	private getRouterConfig() {
		return onboardingRoutes.find(r => r.data && 'root_config' in r.data && r.data.root_config)
	}

	private getStageScreens(stage: OnboardingStage) {
		return this.screens.filter(x => x.stage === stage)
	}

	private fetchOnboardingData() {
		return this.api.get<Onboarding>(['onboarding', 'welcome']).pipe(
			tap(onboarding => {
				this.intro_data.next(onboarding)
			}),
		)
	}

	private async restoreRegistration() {
		const saved_profile = await this.storage
			.get<Partial<RegistrationProfile> | null>('registration_info')
			.toPromise()

		console.debug(
			'[onboardingService] — restoreRegistration. Try restore saved_profile:',
			saved_profile,
		)

		const profile = this.deserializeProfile(saved_profile)

		this.profile = {
			...this.profile,
			...profile,
		}

		console.debug('[onboardingService] — restoreRegistration. Restored profile:', this.profile)
	}

	private async saveStep() {
		return this.storage.set('unfinished_step', this.router.url).toPromise()
	}

	private createUrlTree(stage: OnboardingStage, screen_name: string) {
		const stagePath = this.routes[stage].path
		const screenPath = this.findScreen(stage, screen_name).path

		return this.router.createUrlTree([`${this.rootUrl}/${stagePath}/${screenPath}`])
	}

	private findScreen(stage: OnboardingStage, screen_name: string) {
		return this.routes[stage].children.find(r => r.data?.screen_name === screen_name)!
	}

	/**
	 * Convert Profile to localStorage object for saving
	 */
	private serializeProfile(profile: Partial<RegistrationProfile>) {
		const birthdate = profile.birthdate

		// Exclude old deprecated fields
		const { birthday_date: _, birthday_time: __, ...clearProfile } = profile

		return {
			...clearProfile,
			birthdate: birthdate ? toISO8601(birthdate) : birthdate,
		}
	}

	/**
	 * Convert saved localStorage profile to Profile
	 */
	private deserializeProfile(saved_profile: Partial<RegistrationProfile> | null) {
		if (saved_profile === null) return {}

		let birthdate = this.profile.birthdate || DEFAULT_DATE

		if ('birthdate' in saved_profile) {
			birthdate = new Date(saved_profile.birthdate)
		} else if ('birthday_date' in saved_profile) {
			birthdate = parseISO(saved_profile.birthday_date)

			if (!isValid(birthdate)) {
				console.warn(
					`[onboardingService] — restoreRegistration. try parse date (${saved_profile.birthday_date}) using old format`,
				)
				birthdate = parse(saved_profile.birthday_date, 'dd.MM.yyyy', new Date()) // try old format
			}

			if ('birthday_time' in saved_profile) {
				const time = saved_profile.birthday_time.split(':')
				birthdate.setHours(+time[0], +time[1])
			}
		}
		let birthday_place = saved_profile.birthday_place || null
		if ('birthday_place' in saved_profile && saved_profile.birthday_place) {
			birthday_place = {
				id: saved_profile.birthday_place?.id,
				name:
					saved_profile.birthday_place?.name ??
					(saved_profile.birthday_place as City & { city?: string }).city,
			}
		}

		return {
			...saved_profile,
			birthdate,
			birthday_place,
		}
	}

	private convertProfileForBackend(profile: Partial<RegistrationProfile>) {
		try {
			return {
				first_name: profile?.first_name,
				birthdate: formatISO9075(profile?.birthdate),
				birthplace: profile?.birthday_place?.id,
				birthplace_id: profile?.birthday_place?.id,
				level: profile?.level ? +profile.level : null,
				goals: profile?.goals && profile.goals.length > 0 ? profile.goals : null,
				when_to_notify: profile?.when_to_notify || null,
				time_zone: determine().name(),
			}
		} catch (e) {
			console.error('[onboardingService] — convertProfileForBackend. Error:', e)
			return {}
		}
	}

	isProfileValid(profile: Partial<RegistrationProfile>) {
		return !!profile.first_name && !!profile.birthdate && !!profile.birthday_place?.id
	}
}

export interface RegistrationProfile {
	first_name: string
	birthdate: Date
	/**
	 * @deprecated
	 */
	birthday_time: string
	/**
	 * @deprecated
	 */
	birthday_date: string
	birthday_place: {
		id: string
		name: string
	}
	goals: number[]
	level: number
	when_to_notify: string
}

export interface Onboarding {
	welcome: {
		id: number
		button_text: string
		title: string
		subtitle: string
	}
	what_is_hd: {
		button_text: string
		image: string
		title: string
		text: string
	}
	hd_uniq: {
		id: number
		button_text: string
		image: string
		title: string
		text: string
	}
	goal: {
		id: number
		choices: Array<{
			id: number
			value: string
		}>
		button_text: string
		title: string
		subtitle: string
	}
	level: {
		id: number
		choices: Array<{
			id: number
			display_text: string
		}>
		button_text: string
		title: string
		subtitle: string
	}
	static_url: string
}

export interface OnboardingProfileInfo {
	tag_info: {
		id: number
		famous_people_title: string
		famous_people: Array<{
			id: number
			name: string
			image: string
		}>
		button_text: string
		title: string
		image: string
		text: string
		tag: number
	}
	profile_info: {
		id: number
		title: string
		profile: string
		text: string
		famous_people_title: string
		famous_people: Array<{
			id: number
			name: string
			image: string
		}>
		image: string
		button_text: string
	}
	summary: {
		tag_title: string
		profile_title: string
		text: string
		button_text: string
		image?: string
	}
}

export type OnboardingStage = 'intro' | 'registration' | 'finish'
