import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ConfigService } from 'app/core/services'

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(public http: HttpClient, private config: ConfigService) {}

	get<T>(
		urlParts: string[] | string,
		_params?: object,
		_headers?,
		options?: { reportProgress?: boolean },
	): Observable<T> {
		const headers = Object.assign({}, _headers)
		const params = Object.keys(_params || {})
			.filter(p => _params[p] !== undefined && _params[p] !== null)
			.reduce(
				(all, cur) => ({
					...all,
					[cur]: _params[cur].toString(),
				}),
				{},
			)

		return this.http.get<T>(this.getURL(urlParts), {
			headers,
			params,
			withCredentials: true,
			...options,
		})
	}

	post<T>(urlParts: string[] | string, data: any = {}): Observable<T> {
		return this.http.post<T>(this.getURL(urlParts), data, { withCredentials: true })
	}

	// unused for now
	// put<T>(urlParts: string[] | string, data: any = {}): Observable<T> {
	// 	return this.http
	// 		.put<T>(this.getURL(urlParts), data, { withCredentials: true })
	// 		.pipe(delayedRetry())
	// }

	patch<T>(urlParts: string[] | string, data: any = {}): Observable<T> {
		return this.http.patch<T>(this.getURL(urlParts), data, { withCredentials: true })
	}

	delete<T>(urlParts: string[] | string, params?, headers?): Observable<T> {
		return this.http.delete<T>(this.getURL(urlParts), {
			headers,
			params,
			withCredentials: true,
		})
	}

	private getURL(urlParts: string[] | string): string {
		const parts = [this.apiUrl, this.apiVersion].concat(urlParts)
		for (let i = 0; i < parts.length; i++) {
			parts[i] = parts[i].toString().replace(/^\/+|\/+$/gm, '')
		}
		return parts.join('/') + '/'
	}

	private get apiUrl() {
		return this.config.apiUrl
	}
	private get apiVersion() {
		return 'v' + this.config.apiVersion
	}
}
