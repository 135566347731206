import { OnboardingService } from 'app/modules/onboarding/services/onboarding'
import { Injectable, NgZone } from '@angular/core'
import { UsersService } from 'app/core/services/users.service'
import {
	AlertService,
	AnalyticsService,
	ConfigService,
	DeeplinkService,
	PushService,
	RemoteConfigService,
	SharingService,
	TooltipService,
} from 'app/core/services'
import * as Sentry from '@sentry/capacitor'
import { BillingService } from 'app/core/billing'
import { App } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import { DetectScreenCapture } from 'capacitor-detect-screen-capture'
import { LangService } from './i18n/lang.service'

export class StartupError extends Error {}

// TODO refactoring
@Injectable()
export class SetupClass {
	constructor(
		private pushService: PushService,
		private analyticsService: AnalyticsService,
		private usersService: UsersService,
		private remoteConfigService: RemoteConfigService,
		private configService: ConfigService,
		private zone: NgZone,
		private deeplinkService: DeeplinkService,
		private billingService: BillingService,
		private sharingService: SharingService,

		private lang: LangService,
		private detectScreenCapture: DetectScreenCapture,
		private alertService: AlertService,
		private onboardingService: OnboardingService,
		private tooltipService: TooltipService,
	) {}

	async initializeApp() {
		const error = await this._innerInitialize()

		if (error !== null) {
			throw new StartupError()
		}
	}

	private async _innerInitialize(): Promise<Error | null> {
		try {
			console.debug('[startup] — start')
			await this.configService.initialise()
			console.debug('[startup] — configService ok')
			await this.lang.init()
			console.debug('[startup] — i18n ok')
		} catch (err) {
			console.error(err)
			Sentry.captureException(err)

			this.alertService.alert(
				"Ehh, an unexpected error has occured. \nPlease try again, and send us a screenshot to support@humanify.app if didn't help \nCode: -1",
			)

			return err
		}

		try {
			await this.initRemoteConfig()
			console.debug('[startup] — remoteConfig ok')
		} catch (err) {
			return this.throwErrorWithCode(-2, err)
		}

		try {
			await this.analyticsService.initialise()
			console.debug('[startup] — analytics ok')
		} catch (err) {
			console.warn('[analyticsService initialise] error', err)
		}

		try {
			await this.initRequiredServices()
		} catch (err) {
			return this.throwErrorWithCode(-3, err)
		}

		console.debug('[startup] — required services ok')

		try {
			await this.initOptionalServices()

			console.debug('[startup] — optional services ok')
		} catch (err) {
			console.warn('[Init optional services] Error:', err)
		}

		try {
			if (this.configService.webFromOnboarding) {
				console.debug('[webFromOnboarding] — skip onboarding')
				await this.onboardingService.completeOnboarding()
				console.debug('[webFromOnboarding] — onboarding skipped')
			}
		} catch (err) {
			console.warn('[webFromOnboarding] Error:', err)
		}

		try {
			await this.loadOnboarding()
		} catch (err) {
			console.warn('[loadOnboarding] — failed with error', err)
		}

		console.debug('[startup] — hide splashScreen')
		await SplashScreen.hide()

		await this.addListeners()
		console.debug('[startup] — listeners ok')

		return null
	}

	private async throwErrorWithCode(code: number, err: Error): Promise<Error> {
		Sentry.captureException(err)

		this.alertService.alert(code)
		return err
	}

	private async initRequiredServices() {
		await this.usersService.initialise()
		console.debug('[startup] — usersService ok')
		await this.billingService.initialise()
		console.debug('[startup] — billingService ok')
		// TODO fetch profile
	}

	private async initOptionalServices() {
		return Promise.all([
			this.pushService
				.initialise()
				.catch(err => console.warn('[pushService initialise] error', err)),
			this.tooltipService
				.init()
				.catch(err => console.warn('[tooltipService initialise] error', err)),
		])
	}

	private async addListeners() {
		App.addListener('appUrlOpen', data => {
			this.zone.run(() => this.deeplinkService.handleDeeplink(data.url))
		})

		this.detectScreenCapture.addListener('didScreenshot', () => {
			try {
				this.zone.run(() => this.sharingService.onScreenshot())
			} catch (err) {
				console.warn(`[didScreenshot] — failed with error`, err)
			}
		})

		this.detectScreenCapture.addListener('didScreenRecording', () => {
			try {
				this.zone.run(() => this.sharingService.onScreenRecording())
			} catch (err) {
				console.warn(`[didScreenRecording] — failed with error`, err)
			}
		})
	}

	private async initRemoteConfig() {
		await this.remoteConfigService.initialise()
	}

	private async loadOnboarding() {
		const isOnboardingCompleted = await this.onboardingService.isCompleted().toPromise()
		console.debug(`[startup] — isOnboardingCompleted: ${isOnboardingCompleted}`)

		if (!isOnboardingCompleted) {
			console.debug(`[startup] — start init onboarding`)
			await this.onboardingService.initOnboarding()
			console.debug(`[startup] — init onboarding ok`)
		}
	}
}
