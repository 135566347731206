import { Component, HostBinding, Input, OnInit } from '@angular/core'

import spinner_small from '!!raw-loader!../../../../assets/icons/spinner_16.svg'
import spinner_large from '!!raw-loader!../../../../assets/icons/spinner_44.svg'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

type SpinnerSizes = 'small' | 'large'

@Component({
	selector: 'hum-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
	_size: SpinnerSizes = 'small'
	iconHtml: SafeHtml

	@HostBinding('innerHTML') get _iconHtml() {
		return this.iconHtml
	}

	constructor(private sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this._updateHtml()
	}

	@Input() set size(size: SpinnerSizes) {
		this._size = size
		this._updateHtml()
	}

	_updateHtml() {
		const icons: Record<SpinnerSizes, any> = {
			small: spinner_small,
			large: spinner_large,
		}
		this.iconHtml = this.sanitizer.bypassSecurityTrustHtml(icons[this._size])
	}
}
