<!-- TODO move to component -->
<div class="modal-header">
	<div class="modal-title"> {{ 'city_search_modal.title' | translate }} </div>
	<div class="modal-close" (click)="dismiss()">
		<hum-icon icon="close" size="16"></hum-icon>
	</div>
</div>

<hum-search-input
	class="search"
	[formControl]="search"
	[placeholder]="'city_search_modal.search_placeholder' | translate"
	[loading]="loading"
	autoFocus
></hum-search-input>

<ion-content>
	<div class="page">
		<ng-container [ngSwitch]="state">
			<div class="tips" *ngSwitchDefault>
				<div class="tip" *ngFor="let tip of tips" (click)="onTipClick(tip)">
					{{ tip.name }}
				</div>
			</div>

			<div class="placeholder" *ngSwitchCase="'placeholder'">
				<div class="placeholder-title">
					{{ 'city_search_modal.placeholder_title' | translate }}
				</div>
				<div class="placeholder-text">
					{{ 'city_search_modal.placeholder_text' | translate }}
				</div>
			</div>

			<div class="not-found" *ngSwitchCase="'not-found'">
				<div class="not-found-title">
					{{ 'city_search_modal.not_found_title' | translate }}
				</div>
				<div class="not-found-text">
					{{ 'city_search_modal.not_found_text' | translate }}
				</div>
			</div>
		</ng-container>
	</div>
</ion-content>
