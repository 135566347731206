import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	ElementRef,
	EventEmitter,
	Output,
	HostListener,
	Directive,
} from '@angular/core'
import { City, CitySearchService } from 'app/core/services'

@Directive({
	selector: '[city-search]',
	providers: [CitySearchService],
})
export class CitySearchDirective {
	constructor(private citySearch: CitySearchService, private elementRef: ElementRef<HTMLElement>) {}
	@Input('city-search') _

	@Input() city: City

	@Input() presentingElement: 'ion-page' | HTMLElement | null = 'ion-page'

	@Output() cityChange = new EventEmitter<City | null>()

	@HostListener('click')
	async onClick() {
		const data = await this.citySearch.openModal(this.city, {
			presentingElement: this.getPresentingElement(),
		})

		if (data) {
			this.cityChange.emit(data)
		}
	}

	private getPresentingElement() {
		if (!this.presentingElement) {
			return null
		}

		if (this.presentingElement === 'ion-page') {
			return this.elementRef.nativeElement.closest('.ion-page') as HTMLElement
		}

		return this.presentingElement
	}
}

@Component({
	selector: 'hum-city-search',
	templateUrl: './city-search.component.html',
	styleUrls: ['./city-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [CitySearchService],
})
export class CitySearchComponent extends CitySearchDirective {}
