import { DeeplinkService } from './deeplink.service'
import { AnalyticsService } from 'app/core/services'
import { catchError, switchMap } from 'rxjs/operators'
import { Injectable, NgZone } from '@angular/core'
import { PushNotificationSchema, Token } from '@capacitor/push-notifications'
import { ApiService } from 'app/core/api/api.service'
import { Platform } from '@ionic/angular'
import { AuthService } from './auth.service'
import { StorageService } from './storage.service'
import { of } from 'rxjs'
import { LogLevel, NotificationOpenedResult, OneSignal } from 'capacitor-onesignal'
import { ConfigService } from './config.service'
import { PushNotifications } from 'app/plugins'

@Injectable({
	providedIn: 'root',
})
export class PushService {
	constructor(
		private api: ApiService,
		private storageService: StorageService,
		private platform: Platform,
		private authService: AuthService,
		private zone: NgZone,
		private analyticsService: AnalyticsService,
		private deeplinkService: DeeplinkService,
		private oneSignal: OneSignal,
		private config: ConfigService,
		private pushNotifications: PushNotifications
	) {}

	async initialise() {
		this.subscribeToToken()
		this.addListeners()

		await this.oneSignal.setLogLevel(LogLevel.ERROR)
		await this.oneSignal.init(this.config.oneSignalAppId)

		await this.register()
	}
	async requestPermissions() {
		const granted = await this.oneSignal.promptForPushNotifications()
		this.analyticsService.logEvent('onb_notif_status', { opt_in: granted })
		if (granted) {
			await this.register()
		}
	}

	private async register() {
		try {
			await this.oneSignal.register()
		} catch (error) {
			console.warn('push_register_error', error, JSON.stringify(error))
		}
	}

	private subscribeToToken() {
		this.authService.isAuthenticated
			.pipe(
				switchMap(async (isAuthenticated) => {
					const token = await this.storageService.get<string>('fcm_token').toPromise()
					if (!token) return Promise.resolve()

					if (isAuthenticated) return this.registerFCM(token).toPromise()
					return this.deactivateFCM(token).toPromise()
				})
			)
			.subscribe(() => {})
	}

	private addListeners() {
		// TODO рефакторинг для избавления от плагина PushNotifications, тк OneSignal его полностью заменяет
		// On success, we should be able to receive notifications
		this.pushNotifications.addListener('registration', async (token: Token) => {
			await this.storageService.set('fcm_token', token.value).toPromise()
		})

		// Show us the notification payload if the app is open on our device
		this.pushNotifications.addListener(
			'pushNotificationReceived',
			(notification: PushNotificationSchema) => {
				this.zone.run(() => {
					console.log('pushNotificationReceived', JSON.stringify(notification))

					// if (
					// 	notification.data &&
					// 	notification.data.category === 'adaptyPromo' &&
					// 	notification.data.source === 'adapty'
					// ) {
					// 	console.log('Received adapty notification')
					// 	this.adapty.checkPromo()
					// }
				})
			}
		)

		this.oneSignal.addListener('notificationOpened', (notification: NotificationOpenedResult) => {
			console.log('[PushService] — Push action performed: ', JSON.stringify(notification))

			this.zone.run(() => {
				const link = notification?.notification?.launchURL

				this.analyticsService.logEvent('event_pushopen', {
					id: notification?.notification?.notificationId,
					title: notification?.notification?.title,
					text: notification?.notification?.body,
					link,
				})

				if (link) {
					this.deeplinkService.handleDeeplink(link)
				}
			})
		})
	}

	private deactivateFCM(device_id: string) {
		return this.api.post(['devices', 'deactivate'], { device_id }).pipe(
			catchError((err) => {
				console.warn('deactivateFCM Error', err)
				return of(null)
			})
		)
	}

	private registerFCM(device_id: string) {
		const type = this.platform.is('android') ? 'android' : 'ios'
		return this.api.post(['devices', 'register'], { device_id, type }).pipe(
			catchError((err) => {
				console.warn('registerFCM Error', err)
				return of(null)
			})
		)
	}
}
