<div class="wrapper">
	<div class="left" [ngSwitch]="isSharable">
		<svg
			*ngSwitchDefault
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			class="icon"
		>
			<path
				d="M5 20.002L7.1586 17.8434C7.91218 17.0899 8.93426 16.6665 9.99999 16.6665C11.0657 16.6665 12.0878 17.0899 12.8414 17.8434V17.8434L20 25.002"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.6729 33.3332L22.1626 22.8434C23.7318 21.2742 26.2761 21.2742 27.8454 22.8434L34.4106 29.4087"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M27.5522 13.3335L27.552 15.0002"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M27.5522 3.39259L27.552 5.05924"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M27.5521 3.3335V5.00015"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M22.5511 6.27938L23.9944 7.11287"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M22.4993 6.25016L23.9427 7.0835"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M31.1595 7.08342L32.6029 6.25024"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M22.5511 12.0541L23.9945 11.2209"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M22.4993 12.0835L23.9427 11.2502"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M35.0001 13.4373L31.1348 11.2051"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M35 20V26.6667C35 31.269 31.269 35 26.6667 35H13.3333C8.73096 35 5 31.269 5 26.6667V13.3333C5 8.73096 8.73096 5 13.3333 5H16.6667"
				stroke="white"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>

		<svg
			*ngSwitchCase="true"
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			class="icon"
		>
			<g clip-path="url(#clip0_8547_59483)">
				<path
					d="M13.3327 14.9995H9.99935C8.15768 14.9995 6.66602 16.4912 6.66602 18.3328V29.9995C6.66602 31.8412 8.15768 33.3328 9.99935 33.3328H29.9993C31.841 33.3328 33.3327 31.8412 33.3327 29.9995V18.3328C33.3327 16.4912 31.841 14.9995 29.9993 14.9995H26.666"
					stroke="white"
					stroke-width="2.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M19.9993 3.33301V21.6663"
					stroke="white"
					stroke-width="2.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M15.001 8.33301L19.9993 3.33301L24.9993 8.33301"
					stroke="white"
					stroke-width="2.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_8547_59483">
					<rect width="40" height="40" fill="white" />
				</clipPath>
			</defs>
		</svg>
	</div>
	<div class="middle" [ngSwitch]="isSharable">
		<div class="title">{{ 'screenshot_modal.title' | translate }}</div>

		<div *ngSwitchDefault class="text">{{ 'screenshot_modal.default_text' | translate }}</div>

		<ng-container *ngSwitchCase="true">
			<div class="text sharable-text">
				{{ 'screenshot_modal.try_sharing_text' | translate }}
			</div>
			<hum-button color="white" class="share-button" [loading]="sharingLoading" (click)="share()">
				{{ 'screenshot_modal.try_sharing_button' | translate }}
			</hum-button>
		</ng-container>
	</div>

	<div class="right">
		<div class="close-wrapper" (click)="dismiss()">
			<hum-icon size="16" [icon]="'close'"></hum-icon>
		</div>
	</div>
</div>
