import { ImpactOptions, NotificationOptions, VibrateOptions } from '@capacitor/haptics'
import { Haptics } from '../haptics'

export class HapticsWeb extends Haptics {
	/**
	 * Trigger a haptics "impact" feedback
	 *
	 * @since 1.0.0
	 */
	async impact(options?: ImpactOptions) {}
	/**
	 * Trigger a haptics "notification" feedback
	 *
	 * @since 1.0.0
	 */
	async notification(options?: NotificationOptions) {}
	/**
	 * Vibrate the device
	 *
	 * @since 1.0.0
	 */
	async vibrate(options?: VibrateOptions) {}
	/**
	 * Trigger a selection started haptic hint
	 *
	 * @since 1.0.0
	 */
	async selectionStart() {}
	/**
	 * Trigger a selection changed haptic hint. If a selection was
	 * started already, this will cause the device to provide haptic
	 * feedback
	 *
	 * @since 1.0.0
	 */
	async selectionChanged() {}
	/**
	 * If selectionStart() was called, selectionEnd() ends the selection.
	 * For example, call this when a user has lifted their finger from a control
	 *
	 * @since 1.0.0
	 */
	async selectionEnd() {}
}
