import { ApiService } from 'app/core/api'
import { Injectable } from '@angular/core'
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { TokenService, UsersService } from 'app/core/services'

import { catchError, switchMap, tap } from 'rxjs/operators'
import { Billing } from './billing-providers'
import { BillingResult, PaywallWithConfig, Subscription } from './interfaces'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable({
	providedIn: 'root',
})
export class BillingService {
	constructor(
		private api: ApiService,
		private billing: Billing,
		private tokenService: TokenService,
		private translate: TranslateService,
		private userService: UsersService,
	) {}

	private _subscription = new BehaviorSubject<Subscription | null>(null)

	getPaywall(paywallId?: string): Observable<PaywallWithConfig> {
		return from(this.billing.getPaywall(paywallId)) // TODO getPaywall to Observable
	}

	async purchase(
		productId: string,
		paywall: PaywallWithConfig,
		offerId?: string,
	): Promise<BillingResult> {
		const result = await this.billing.order(productId, paywall, offerId)

		if (result.status !== 'success') {
			return result
		}

		const subscription = await this.waitForPayment()

		if (subscription?.status !== 'active') {
			return {
				status: 'failed',
				error: {
					message: -15,
					original_error: null,
					code: null,
				},
			}
		}

		return { status: 'success', method: result.method  }
	}

	async restore(): Promise<BillingResult> {
		const result = await this.billing.restore()

		if (result.status !== 'success') {
			return result
		}
		try {
			await this.tokenService.set(result.data!.token).toPromise()

			const profile = await this.userService.fetchProfile().toPromise()
			if (profile.customer_id) {
				await this.userService.updateCustomerId(profile.customer_id)
			} else {
				console.warn('[billingService] — missing profile.customer_id')
			}

			const subscription = await this.fetchSubscription().toPromise()

			if (!subscription?.is_active) {
				return { status: 'expired' }
			}

			return { status: 'success' }
		} catch (error) {
			let code = error instanceof HttpErrorResponse ? error.status.toString() : null
			let message = error instanceof HttpErrorResponse ? error.message : null
			return {
				status: 'failed',
				error: {
					code,
					message,
					original_error: error,
				},
			}
		}
	}

	private async waitForPayment() {
		let subscription: Subscription | undefined | null

		for (let i = 0; i < 60; i++) {
			console.debug(`[billingService] — wait for payment... (${i})`)

			subscription = await this.fetchSubscription()
				.pipe(catchError(() => of(null)))
				.toPromise()

			console.debug(`[billingService] — status: ${subscription?.status}`)
			if (['active', 'payment_failed'].includes(subscription?.status)) {
				break
			}

			await new Promise(res => setTimeout(res, 1000))
		}

		return subscription
	}

	async initialise() {
		await this.fetchSubscription().toPromise()
	}

	fetchSubscription(): Observable<Subscription | null> {
		return this.api.get<Subscription>(['billing', 'subscription']).pipe(
			catchError(error => {
				if (error instanceof HttpErrorResponse && error.status === 404) {
					return of(null)
				}
				console.error(`[billingService] — fetchSubscription error.`, error)
				return throwError(error)
			}),
			tap(subscription => this._subscription.next(subscription)),
		)
	}

	getSubscription(force: boolean = false): Observable<Subscription> {
		if (!this._subscription.value || force) {
			return this.fetchSubscription().pipe(switchMap(() => this._subscription.asObservable()))
		}

		return this._subscription.asObservable()
	}

	cancelSubscription() {
		return this.api.post<{ status: 'success' }>(['billing', 'stripe', 'cancel-subscription'])
	}
}
