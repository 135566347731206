import { Component } from '@angular/core'

@Component({
	selector: 'hum-card-article-skeleton',
	template: `
		<div class="content">
			<div skeleton="dark" class="image"> </div>

			<div class="right">
				<div skeleton="light" width="100%" height="17px" bottom="5px"></div>
				<div skeleton="light" width="90%" height="17px" bottom="5px"></div>
				<div skeleton="light" width="70%" height="17px"></div>
			</div>
		</div>
	`,
	styleUrls: ['./card-article.component.scss'],
})
export class CardArticleSkeletonComponent {}
