import { Component, OnInit } from '@angular/core'
import { AnalyticsService } from 'app/core/services'
import { ViewDidEnter } from '@ionic/angular'
import { OnboardingService } from '../../../../services/onboarding'

@Component({
	selector: 'hum-tutorial2',
	templateUrl: './tutorial2.component.html',
	styleUrls: ['./tutorial2.component.scss'],
})
export class Tutorial2Component implements ViewDidEnter {
	constructor(private onboardingService: OnboardingService, private analytics: AnalyticsService) {}

	ionViewDidEnter() {
		this.analytics.logOnboardingEvent('onb_tutor2')
	}

	get videoSrc() {
		return this.onboardingService.getVideo('composite')
	}

	features = [
		'onboarding.tutorial-2.features.0',
		'onboarding.tutorial-2.features.1',
		'onboarding.tutorial-2.features.2',
	]

	async navigateNext() {
		this.onboardingService.navigateNext()
	}
}
