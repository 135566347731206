<hum-top-gradient></hum-top-gradient>
<ion-content [fullscreen]="true">
	<div class="hum-content back-button-wrapper">
		<hum-back-button class="back-button" [defaultHref]="defaultHref"></hum-back-button>
	</div>
	<div class="page hum-content">
		<div class="top">
			<div class="title">{{ 'onboarding.birthdayplace.title' | translate }}</div>
			<hum-city-search class="field" [(city)]="city"> </hum-city-search>
		</div>
	</div>
	<div class="footer">
		<hum-button [disabled]="!city" (click)="navigateNext()" class="hum-content">
			{{ 'onboarding.birthdayplace.next_button' | translate }}
		</hum-button>
	</div>
	<hum-bottom-gradient></hum-bottom-gradient>
</ion-content>
