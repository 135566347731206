<ion-content [fullscreen]="true">
	<div class="page hum-content">
		<div class="center">
			<hum-icon icon="logo_old" class="logo"></hum-icon>
			<div class="title">
				<ng-container *isNative>{{onboarding?.welcome?.title}}</ng-container>
				<ng-container *isWeb>{{ 'onboarding.welcome.title' | translate }}</ng-container>
			</div>
			<div class="subtitle">
				<ng-container *isNative>{{onboarding?.welcome?.subtitle}}</ng-container>
				<ng-container *isWeb>{{ 'onboarding.welcome.subtitle' | translate }}</ng-container>
			</div>
		</div>
		<hum-button (click)="navigateNext()">
			<ng-container *isNative>{{onboarding?.welcome?.button_text}}</ng-container>
			<ng-container *isWeb>{{ 'onboarding.welcome.button_text' | translate }}</ng-container>
		</hum-button>
	</div>
</ion-content>
