import { PluginListenerHandle } from '@capacitor/core'
import {
	ActionPerformed,
	Channel,
	DeliveredNotifications,
	ListChannelsResult,
	PermissionStatus,
	PushNotificationSchema,
	PushNotificationsPlugin,
	Token,
} from '@capacitor/push-notifications'

export abstract class PushNotifications implements PushNotificationsPlugin {
	/**
	 * Register the app to receive push notifications.
	 *
	 * This method will trigger the `'registration'` event with the push token or
	 * `'registrationError'` if there was a problem. It does not prompt the user for
	 * notification permissions, use `requestPermissions()` first.
	 *
	 * @since 1.0.0
	 */
	abstract register(): Promise<void>
	/**
	 * Get a list of notifications that are visible on the notifications screen.
	 *
	 * @since 1.0.0
	 */
	abstract getDeliveredNotifications(): Promise<DeliveredNotifications>
	/**
	 * Remove the specified notifications from the notifications screen.
	 *
	 * @since 1.0.0
	 */
	abstract removeDeliveredNotifications(delivered: DeliveredNotifications): Promise<void>
	/**
	 * Remove all the notifications from the notifications screen.
	 *
	 * @since 1.0.0
	 */
	abstract removeAllDeliveredNotifications(): Promise<void>
	/**
	 * Create a notification channel.
	 *
	 * Only available on Android O or newer (SDK 26+).
	 *
	 * @since 1.0.0
	 */
	abstract createChannel(channel: Channel): Promise<void>
	/**
	 * Delete a notification channel.
	 *
	 * Only available on Android O or newer (SDK 26+).
	 *
	 * @since 1.0.0
	 */
	abstract deleteChannel(channel: Channel): Promise<void>
	/**
	 * List the available notification channels.
	 *
	 * Only available on Android O or newer (SDK 26+).
	 *
	 * @since 1.0.0
	 */
	abstract listChannels(): Promise<ListChannelsResult>
	/**
	 * Check permission to receive push notifications.
	 *
	 * On Android the status is always granted because you can always
	 * receive push notifications. If you need to check if the user allows
	 * to display notifications, use local-notifications plugin.
	 *
	 * @since 1.0.0
	 */
	abstract checkPermissions(): Promise<PermissionStatus>
	/**
	 * Request permission to receive push notifications.
	 *
	 * On Android it doesn't prompt for permission because you can always
	 * receive push notifications.
	 *
	 * On iOS, the first time you use the function, it will prompt the user
	 * for push notification permission and return granted or denied based
	 * on the user selection. On following calls it will currect status of
	 * the permission without prompting again.
	 *
	 * @since 1.0.0
	 */
	abstract requestPermissions(): Promise<PermissionStatus>
	/**
	 * Called when the push notification registration finishes without problems.
	 *
	 * Provides the push notification token.
	 *
	 * @since 1.0.0
	 */
	abstract addListener(
		eventName: 'registration',
		listenerFunc: (token: Token) => void
	): Promise<PluginListenerHandle> & PluginListenerHandle
	/**
	 * Called when the push notification registration finished with problems.
	 *
	 * Provides an error with the registration problem.
	 *
	 * @since 1.0.0
	 */
	abstract addListener(
		eventName: 'registrationError',
		listenerFunc: (error: any) => void
	): Promise<PluginListenerHandle> & PluginListenerHandle
	/**
	 * Called when the device receives a push notification.
	 *
	 * @since 1.0.0
	 */
	abstract addListener(
		eventName: 'pushNotificationReceived',
		listenerFunc: (notification: PushNotificationSchema) => void
	): Promise<PluginListenerHandle> & PluginListenerHandle
	/**
	 * Called when an action is performed on a push notification.
	 *
	 * @since 1.0.0
	 */
	abstract addListener(
		eventName: 'pushNotificationActionPerformed',
		listenerFunc: (notification: ActionPerformed) => void
	): Promise<PluginListenerHandle> & PluginListenerHandle
	/**
	 * Remove all native listeners for this plugin.
	 *
	 * @since 1.0.0
	 */
	abstract removeAllListeners(): Promise<void>
}
