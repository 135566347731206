import { Routes } from '@angular/router'
import {
	SummaryNewComponent,
	TrackingComponent,
	Tutorial1Component,
	Tutorial2Component,
	Tutorial3Component,
	Tutorial4Component,
	WhenToNotifyComponent,
} from './pages'

export const FinishRoutes: Routes = [
	{
		path: 'summary-new',
		data: { screen_name: 'summary-new' },
		component: SummaryNewComponent,
	},
	{
		path: 'when-to-notify',
		data: { screen_name: 'notification', platforms: ['ios', 'android'] },
		component: WhenToNotifyComponent,
	},
	{
		path: 'tracking',
		data: { screen_name: 'tracking', platforms: ['ios'], progress_exclude: true },
		component: TrackingComponent,
	},
	{
		path: 'tutorial-1',
		data: { screen_name: 'tutorial-1' },
		component: Tutorial1Component,
	},
	{
		path: 'tutorial-2',
		data: { screen_name: 'tutorial-2' },
		component: Tutorial2Component,
	},
	{
		path: 'tutorial-3',
		data: { screen_name: 'tutorial-3' },
		component: Tutorial3Component,
	},
	{
		path: 'tutorial-4',
		data: { screen_name: 'tutorial-4' },
		component: Tutorial4Component,
	},
]
