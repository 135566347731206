import { AnalyticsService } from 'app/core/services'
import { Onboarding, OnboardingService } from '../../../../services/onboarding'
import { Component } from '@angular/core'
import { ViewDidEnter } from '@ionic/angular'

@Component({
	selector: 'hum-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements ViewDidEnter {
	onboarding: Onboarding | null
	constructor(private onboardingService: OnboardingService, private analytics: AnalyticsService) {
		this.onboardingService.intro_data.subscribe((onboarding) => {
			this.onboarding = onboarding
		})
	}

	ionViewDidEnter() {
		this.analytics.logOnboardingEvent('onb_welcome')
	}

	navigateNext() {
		this.onboardingService.navigateNext()
	}
}
