import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { AnalyticsService, SharablePage } from 'app/core/services'
import { filter, take } from 'rxjs/operators'
import { screenshotModalEnterAnimation, screenshotModalLeaveAnimation } from './animations'
import { ModalController } from '@ionic/angular'

const SCREENSHOT_MODAL_TIMEOUT = 5000

@Component({
	selector: 'hum-screenshot-modal',
	templateUrl: './screenshot-modal.component.html',
	styleUrls: ['./screenshot-modal.component.scss'],
})
export class ScreenshotModalComponent implements OnInit, OnDestroy {
	constructor(private modalController: ModalController, private analytics: AnalyticsService) {}

	@Input() sharablePage: SharablePage | null
	@Input() id?: string

	private timeout = null

	ngOnInit() {
		this.timeout = setTimeout(async () => {
			this.timeout = null
			this.dismiss()
		}, SCREENSHOT_MODAL_TIMEOUT)
	}

	ngOnDestroy(): void {
		this._clearTimeout()
	}

	dismiss() {
		if (this.id) {
			this.modalController.dismiss(null, null, this.id).catch(() => {
				// modal already dismissed
			})
		}
		this._clearTimeout()
	}

	private _clearTimeout() {
		if (this.timeout) {
			clearTimeout(this.timeout)
			this.timeout = null
		}
	}

	sharingLoading = false

	get isSharable() {
		return !!this.sharablePage && (this.sharablePage.canShare ? this.sharablePage.canShare() : true)
	}

	async share() {
		if (!this.isSharable) return

		this._clearTimeout()

		this.analytics.logEvent('sharing_try_share', {
			source: this.sharablePage.SHARING_SOURCE || null,
		})

		this.sharingLoading = true
		if (!!this.sharablePage.ready$) {
			await this.sharablePage.ready$
				.pipe(
					filter(v => !!v),
					take(1),
				)
				.toPromise()
		}
		await this.sharablePage.share(true)
		this.dismiss()
	}

	static enterAnimation = screenshotModalEnterAnimation
	static leaveAnimation = screenshotModalLeaveAnimation
}
