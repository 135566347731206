import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core'
import { AnimationOptions } from 'ngx-lottie'

type Size = 'small' | 'normal' | 'big'

@Component({
	selector: 'hum-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
	constructor(private cdr: ChangeDetectorRef) {}
	_size: Size = 'small'
	@Input()
	set size(size: Size) {
		this._size = size
		this.cdr.markForCheck()
	}
	get size() {
		return this._size
	}

	options: AnimationOptions = {
		path: '/assets/animations/loading.json',
		loop: true,
		autoplay: true,
	}
}
