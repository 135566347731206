import { Injectable } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { of, timer } from 'rxjs'
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators'
import { ApiService } from '../api'
import { AnalyticsService } from './analytics.service'

export type City = {
	id: string
	name: string
}

@Injectable()
export class CitySearchService {
	constructor(
		private api: ApiService,
		private analytics: AnalyticsService,
		private translate: TranslateService,
		private modalController: ModalController,
	) {}

	loading: boolean
	cityLoadingTimer

	tips: City[] = []
	notFound = false

	async openModal(
		city: City,
		options: { presentingElement?: HTMLElement } = {},
	): Promise<null | City> {
		const modalComponent = await import('app/theme/modals/city-search-modal').then(
			m => m.CitySearchModalComponent,
		)

		const modal = await this.modalController.create({
			component: modalComponent,
			presentingElement: options.presentingElement,
			keyboardClose: false,
			canDismiss: true,
			componentProps: {
				id: city?.id,
				name: city?.name,
			},
		})

		await modal.present()
		const { data } = await modal.onWillDismiss<City | null>()

		if (data && data.id && data.name) {
			return data
		}

		return null
	}

	search(location: string) {
		this.notFound = false
		if (!location?.length) {
			this.tips = []
		}

		return timer(300).pipe(
			filter(() => location.length >= 2),
			tap(() => this.showLoading()),
			switchMap(() =>
				this.api
					.get<Array<{ id: string; city: string }>>(['onboarding', 'city'], { location })
					.pipe(catchError(() => of(null))),
			),
			map(cities => cities?.map(city => ({ id: city.id, name: city.city }))),
			tap(cities => {
				this.hideLoading()
				if (!cities || cities.length === 0) {
					this.notFound = true
					this.analytics.logEvent('onb_place_placenotfound', {
						city: location,
						lang: this.translate.currentLang,
					})
				}
				this.tips = cities || []
			}),
			finalize(() => this.hideLoading()),
		)
	}

	showLoading() {
		this.cityLoadingTimer = setTimeout(() => {
			this.loading = true
		}, 300)
	}

	hideLoading() {
		if (this.cityLoadingTimer) {
			clearTimeout(this.cityLoadingTimer)
		}
		this.loading = false
	}
}
