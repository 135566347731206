import { StorageService } from './storage.service'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { filter, tap } from 'rxjs/operators'

export const TOKEN_STORAGE_KEY = 'token'

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	tokenState: BehaviorSubject<boolean> = new BehaviorSubject(false)
	private token: BehaviorSubject<string> = new BehaviorSubject(null)

	constructor(private storageService: StorageService) {
		this.get()
			.pipe(filter((value) => this.isValidToken(value)))
			.subscribe((value) => this.set(value).subscribe())

		this.token.subscribe((value) => this.tokenState.next(this.isValidToken(value)))
	}

	get(): Observable<string> {
		return this.storageService.get(TOKEN_STORAGE_KEY)
	}

	set(value: string): Observable<string> {
		return this.storageService.set(TOKEN_STORAGE_KEY, value).pipe(
			tap((value) => {
				this.token.next(value)
			})
		)
	}

	clear(): Observable<string> {
		return this.storageService.clear(TOKEN_STORAGE_KEY).pipe(
			tap(() => {
				this.token.next(undefined)
			})
		)
	}

	private isValidToken(value: string) {
		return !!value
	}
}
