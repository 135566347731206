import { FactoryProvider } from '@angular/core'
import { isWeb } from 'app/core/utils'
import { BillingResult, PaywallWithConfig, TokenResponse } from '../interfaces'
import { AdaptyBilling } from './adapty'
import { StripeBilling } from './stripe'

export abstract class Billing {
	abstract getPaywall(paywallId?: string): Promise<PaywallWithConfig>
	abstract order(productId: string, paywall: PaywallWithConfig, offerId?: string): Promise<BillingResult>
	abstract restore(): Promise<BillingResult<TokenResponse>>
}

export const BillingFactoryProvider: FactoryProvider = {
	provide: Billing,
	deps: [AdaptyBilling, StripeBilling],
	useFactory: (adaptyBilling: AdaptyBilling, stripeBilling: StripeBilling) =>
		isWeb ? stripeBilling : adaptyBilling,
}
