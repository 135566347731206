import { RemoteConfig } from 'capacitor-remote-config'
import { Injectable } from '@angular/core'
import { from } from 'rxjs'
import { timeout } from 'rxjs/operators'
import { ConfigService } from '.'

@Injectable({
	providedIn: 'root',
})
export class RemoteConfigService {
	constructor(private remoteConfig: RemoteConfig, private config: ConfigService) {}

	async initialise() {
		const minimumFetchIntervalInSeconds = this.config.isTesting ? 0 : 3600

		const initializeAndActivate = async () => {
			await this.remoteConfig.initialize(minimumFetchIntervalInSeconds)
			await this.remoteConfig.fetchAndActivate()
		}

		try {
			await from(initializeAndActivate()).pipe(timeout(5_000)).toPromise()
		} catch (err) {
			console.error('Firebase initialise error', err)
			// TODO analytics request
		}
	}

	async getJSON<T = object>(key: string) {
		const res = await this.remoteConfig.getJSON<T>(key)
		return res.value
	}

	async getBool(key: string) {
		const { value } = await this.remoteConfig.getBoolean(key)
		return value
	}
}
