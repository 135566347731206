import { HttpClient } from '@angular/common/http'
import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	OnDestroy,
	ElementRef,
	SecurityContext,
	Renderer2,
	Input,
	Output,
	EventEmitter,
	HostListener,
} from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { BehaviorSubject, of, ReplaySubject } from 'rxjs'
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators'

// List of disallowed SVG elements
// Adjusted from https://github.com/cure53/DOMPurify/blob/f6fcdb9f1c13b3559697db0038744a0a327d46ab/src/tags.js#L201
const svgDisallowed = [
	'a',
	'animate',
	'color-profile',
	'cursor',
	'discard',
	'fedropshadow',
	'font-face',
	'font-face-format',
	'font-face-name',
	'font-face-src',
	'font-face-uri',
	'foreignobject',
	'hatch',
	'hatchpath',
	'mesh',
	'meshgradient',
	'meshpatch',
	'meshrow',
	'missing-glyph',
	'script',
	'set',
	'solidcolor',
	'unknown',
	'use',
]

@Component({
	selector: 'hum-dynamic-svg',
	templateUrl: './dynamic-svg.component.html',
	styleUrls: ['./dynamic-svg.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicSvgComponent implements OnInit, OnDestroy {
	private destroyed$ = new ReplaySubject(1)
	constructor(
		private host: ElementRef<HTMLElement>,
		private http: HttpClient,
		private sanitizer: DomSanitizer,
		private renderer: Renderer2,
	) {}

	// private src$ = new BehaviorSubject<string | null>(null)

	// @Input()
	// set src(src: string) {
	// 	this.src$.next(src)
	// }

	@Input()
	set html(svgText: string) {
		const sanitizedSvg = this.sanitizeSVG(svgText)
		this.renderer.setProperty(this.host.nativeElement, 'innerHTML', sanitizedSvg)
		this.loaded.emit()
	}

	@Output() loaded = new EventEmitter<void>()

	ngOnInit() {}

	private sanitizeSVG = (svgText: string) => {
		const playground = window.document.createElement('template')
		playground.innerHTML = svgText
		const svgEl = playground.content.firstElementChild!
		const attributes = Array.from(svgEl.attributes).map(({ name }) => name)
		const hasScriptAttr = !!attributes.find(attr => attr.startsWith('on'))
		const disallowedSvgElements = svgEl.querySelectorAll(svgDisallowed.join(','))
		return disallowedSvgElements.length === 0 && !hasScriptAttr ? svgText : null
	}

	ngOnDestroy() {
		this.destroyed$.next(true)
		this.destroyed$.complete()
	}
}
