<!-- карточка сосиска -->
<div class="content">
	<div class="image" *ngIf="!!article?.thumbnail" [humBackgroundImage]="article?.thumbnail">
		<div class="badge" *ngIf="!!article?.is_new">{{ 'article_card.new_label' | translate }}</div>
	</div>

	<div class="right">
		<div class="title">{{ article.title }}</div>
	</div>
</div>
