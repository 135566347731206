import { Routes } from '@angular/router'
import {
	HumanDesignComponent,
	LevelComponent,
	UniquenessComponent,
	WelcomeComponent,
	GoalComponent,
} from './pages'

export const IntroRoutes: Routes = [
	{
		path: 'welcome',
		data: { screen_name: 'welcome', progress_exclude: true },
		component: WelcomeComponent,
	},
	{
		path: 'human-design',
		data: { screen_name: 'what-is-hd' },
		component: HumanDesignComponent,
	},
	{
		path: 'uniqueness',
		data: { screen_name: 'hd-uniqueness' },
		component: UniquenessComponent,
	},
	{
		path: 'level',
		data: { screen_name: 'level' },
		component: LevelComponent,
	},
	{
		path: 'goal',
		data: { screen_name: 'goal' },
		component: GoalComponent,
	},
]
