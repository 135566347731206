import { Injectable } from '@angular/core'
import { CanActivate, CanActivateChild, Router } from '@angular/router'
import { map } from 'rxjs/operators'
import { BillingService } from 'app/core/billing'

@Injectable({
	providedIn: 'root',
})
export class SubscriptionGuard implements CanActivate, CanActivateChild {
	constructor(private billingService: BillingService, private router: Router) {}

	canActivate() {
		return this.billingService.getSubscription().pipe(
			map(subscription => {
				if (!subscription) {
					return this.router.createUrlTree(['/subscription'])
				}
				if (!subscription.is_active) {
					return this.router.createUrlTree(['/subscription/expired'])
				}
				return true
			}),
		)
	}
	canActivateChild() {
		return this.canActivate()
	}

	canLoad() {
		return this.canActivate()
	}
}
