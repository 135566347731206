import { SafariViewControllerOptions } from '@ionic-native/safari-view-controller'
import { throwError } from 'rxjs'

const NOT_AVAILABLE = 'Not available'

export class SafariViewControllerWeb {
	isAvailable = () => Promise.resolve(false)
	show = (_?: SafariViewControllerOptions) => throwError(NOT_AVAILABLE)
	hide = () => Promise.reject(NOT_AVAILABLE)
	asyncconnectToService = () => Promise.reject(NOT_AVAILABLE)
	warmUp = () => Promise.reject(NOT_AVAILABLE)
	mayLaunchUrl = (_: string) => Promise.reject(NOT_AVAILABLE)
}
