async function GetWebResult(key: string) {
	return {
		key,
		value: null,
		source: 'web',
	}
}

export class RemoteConfigWeb {
	async initialize(minimumFetchIntervalInSeconds?: number) {}
	async fetch() {}
	async activate() {}
	async fetchAndActivate() {}
	async getBoolean(key: string) {
		return GetWebResult(key)
	}
	async getNumber(key: string) {
		return GetWebResult(key)
	}
	async getString(key: string) {
		return GetWebResult(key)
	}
	async getJSON<T = object>(key: string) {
		return GetWebResult(key)
	}
}
