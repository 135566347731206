<input
	class="input"
	[placeholder]="placeholder"
	[ngModel]="value"
	(ngModelChange)="updateValue($event)"
	[attr.enterkeyhint]="enterkeyhint"
	[attr.inputmode]="inputmode"
	[class.loading]="loading !== undefined"
	(keypress)="onSubmit($event)"
	#input
/>

<hum-spinner *ngIf="loading" class="spinner"></hum-spinner>
