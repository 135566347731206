import { AbstractControl, ValidationErrors } from '@angular/forms'
import { isAfter, isValid, parse, startOfDay, startOfToday } from 'date-fns'

const MIN_YEAR = 1900

export function requiredWithTrimValidator(control: AbstractControl): ValidationErrors | null {
	const value = control.value as string
	const isValid = (value || '').trim().length !== 0

	return isValid ? null : { empty: true }
}

export function birthdayDateValidator(control: AbstractControl): ValidationErrors | null {
	const date = parse(control.value, 'dd.MM.yyyy', new Date())

	if (!isValid(date)) {
		return { invalid: true }
	}

	if (date.getFullYear() < MIN_YEAR) {
		return { min: true }
	}

	if (isAfter(startOfDay(date), startOfToday())) {
		return { max: true }
	}

	return null
}
