import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { HttpClient } from '@angular/common/http'
import { TranslateHttpLoader } from './loader'
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler'

export const I18N_ALLOWED_LANGS = ['en', 'ru', 'es']
export const I18N_DEFAULT_LANG = 'en'

@NgModule({
	declarations: [],
	imports: [CommonModule],
})
export class I18nModule {
	static forRoot(): ModuleWithProviders<I18nModule> {
		return TranslateModule.forRoot({
			defaultLanguage: I18N_DEFAULT_LANG,
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
				deps: [HttpClient],
			},
			compiler: {
				provide: TranslateCompiler,
				useClass: TranslateMessageFormatCompiler,
			},
		})
	}
}
