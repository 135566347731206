import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'hum-bottom-gradient',
	templateUrl: './bottom-gradient.component.html',
	styleUrls: ['./bottom-gradient.component.scss'],
})
export class BottomGradientComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
