import { Injectable } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { DatepickerModalComponent, DatepickerOptions } from '../modals'

@Injectable()
export class DatePickerService {
	constructor(private modalController: ModalController) {}

	async open(
		date: Date | string,
		presentation: string = 'date-time',
		options?: DatepickerOptions,
	): Promise<Date | null> {
		const value = typeof date === 'string' ? new Date(date) : date

		const modal = await this.modalController.create({
			component: DatepickerModalComponent,
			showBackdrop: false,
			keyboardClose: true,
			cssClass: 'hum-datepicker-modal',
			componentProps: { value, presentation, options },
		})

		await modal.present()

		const { data } = await modal.onDidDismiss()

		if (data && data.value) {
			return data.value
		} else {
			return null
		}
	}

	async openTime(date: Date, options?: DatepickerOptions) {
		return this.open(date, 'time', options)
	}

	async openDate(date: Date, options?: DatepickerOptions) {
		return this.open(date, 'date', options)
	}

	async openDateTime(date: Date, options?: DatepickerOptions) {
		return this.open(date, 'date-time', options)
	}
}
