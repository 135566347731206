import { AfterContentInit, Directive, Inject, InjectionToken, Input } from '@angular/core'
import { IFocusable } from 'app/core/interfaces'

export const AUTO_FOCUSABLE = new InjectionToken<IFocusable>('AUTO_FOCUSABLE')

@Directive({
	selector: '[autoFocus]',
})
export class AutoFocusDirective implements AfterContentInit {
	constructor(@Inject(AUTO_FOCUSABLE) private el: IFocusable) {}

	@Input('autoFocus') _: unknown

	public ngAfterContentInit() {
		setTimeout(() => {
			this.el.setFocus()
		}, 300)
	}
}
