import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ApiService } from 'app/core/api'
import { ConfigService } from 'app/core/services'
import { isCapacitorError } from 'app/core/utils'
import { Adapty, AdaptyErrors, AdaptyProduct } from 'capacitor-adapty'
import { PaywallWithConfig, BillingResult, TokenResponse, Product } from '../interfaces'
import { Billing } from './billing-factory'

@Injectable({ providedIn: 'root' })
export class AdaptyBilling implements Billing {
	constructor(
		private api: ApiService,
		private adapty: Adapty,
		private config: ConfigService,
		private translate: TranslateService,
	) {}

	async order(
		productId: string,
		paywall: PaywallWithConfig,
		offerId?: string,
	): Promise<BillingResult> {
		try {
			const { receipt } = await this.adapty.makePurchase(productId, paywall.id, offerId)

			await this.api.post<{}>(['billing', 'assign-purchase'], { receipt }).toPromise()

			return { status: 'success' }
		} catch (err) {
			return this.parseError(err, -7)
		}
	}

	async restore(): Promise<BillingResult<TokenResponse>> {
		try {
			const { receipt } = await this.adapty.restorePurchases()

			const { token } = await this.api
				.post<TokenResponse>(['billing', 'restore'], { receipt })
				.toPromise()

			return { status: 'success', data: { token } }
		} catch (err) {
			return this.parseError(err, -6)
		}
	}

	async getPaywall(_paywallId?: string): Promise<PaywallWithConfig> {
		const paywallId = _paywallId || this.config.adapty.paywallId
		const paywalls = await this.adapty.getPaywalls({ forceUpdate: true })

		const paywall = paywalls.paywalls.find(p => p.developerId === paywallId)

		let config = this.tryParseConfig(paywall.customPayloadString)
		const products = paywall.products
			.filter(p => !!p.subscriptionPeriod)
			.map(p => ({
				id: p.vendorProductId,
				title: p.localizedTitle,

				price: p.price,
				currency: p.currencySymbol,
				currency_code: p.currencyCode,
				price_localized: p.localizedPrice,

				period_unit: this.getPeriodUnit(p.subscriptionPeriod.unit),
				period_number_of_units: p.subscriptionPeriod.numberOfUnits,
				period_localized: p.localizedSubscriptionPeriod,
				discount: this.getProductDiscount(p),
			}))

		return {
			id: paywall?.variationId,
			config,
			products,
		} as PaywallWithConfig
	}

	private getProductDiscount(product: AdaptyProduct): Product['discount'] | null {
		const trial = product.introductoryDiscount
		const promo = product.discounts[0]

		const hasTrial = !!trial && !!product.introductoryOfferEligibility
		const hasPromo = !!promo && !!product.promotionalOfferEligibility

		if (!hasTrial && !hasPromo) return null

		const discount = trial || promo

		// shared
		const sharedDiscount = {
			period_unit: this.getPeriodUnit(discount.subscriptionPeriod.unit),
			period_number_of_units: discount.subscriptionPeriod.numberOfUnits,
			period_localized: discount.localizedSubscriptionPeriod,
			price: discount.price,
			price_localized: discount.localizedPrice,
			currency: product.currencySymbol,
			currency_code: product.currencyCode,
		}

		if (hasTrial) {
			return {
				...sharedDiscount,
				type: 'trial',
			}
		} else {
			return {
				...sharedDiscount,
				type: 'promo',
				identifier: promo.identifier,
				old_price: product.price,
				old_price_localized: product.localizedPrice,
			}
		}
	}
	// add lifetime support
	private getPeriodUnit(adaptyUnit: number) {
		return ['day', 'week', 'month', 'year'][adaptyUnit]
	}

	private parseError(
		error: unknown,
		default_message: string | number,
	): BillingResult<TokenResponse> {
		let message: string | number | null = default_message
		let status: BillingResult['status'] = 'failed'

		let code: string | null
		if (typeof error === 'object' && !!error && 'code' in error) {
			code = error['code'].toString()
		}

		if (isCapacitorError(error)) {
			switch (error.code) {
				case AdaptyErrors.authenticationError:
					message = 2002
					break
				case AdaptyErrors.cloudServiceNetworkConnectionFailed:
					message = 7
					break
				case AdaptyErrors.noPurchasesToRestore:
					message = 1004
					status = 'no_purchases'
					break
				case AdaptyErrors.cantMakePayments:
					message = 1003
					break
				case AdaptyErrors.paymentCancelled:
					message = 2
					status = 'canceled'
					break
				case '0':
					message = 0
					break
			}
		}

		return { status, error: { code, message, original_error: error } }
	}

	private tryParseConfig(payload: string) {
		let config: object | null = null
		try {
			config = JSON.parse(payload)
		} catch (err) {
			console.warn('[BillingService] — Failed to parse Adapty paywall config.', payload)
		}
		return config || {}
	}
}
