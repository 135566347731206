<!-- Квадратная карточка -->
<div
	class="background"
	*ngIf="!!article?.thumbnail"
	[humBackgroundImage]="article?.thumbnail"
></div>
<div class="badge" *ngIf="!!article?.is_new">{{ 'article_card.new_label' | translate }}</div>
<div class="header">
	<div class="header-text">{{ article?.title }}</div>
</div>
