import { Component } from '@angular/core'
import { AnalyticsService } from 'app/core/services'
import { Onboarding, OnboardingService } from '../../../../services/onboarding'
import { ViewDidEnter } from '@ionic/angular'

@Component({
	selector: 'hum-human-design',
	templateUrl: './human-design.component.html',
	styleUrls: ['./human-design.component.scss'],
})
export class HumanDesignComponent implements ViewDidEnter {
	onboarding: Onboarding | null

	constructor(private onboardingService: OnboardingService, private analytics: AnalyticsService) {
		this.onboardingService.intro_data.subscribe((onboarding) => {
			this.onboarding = onboarding
		})
	}

	ionViewDidEnter() {
		this.analytics.logOnboardingEvent('onb_whatis')
	}

	get screen() {
		return this.onboarding?.what_is_hd
	}

	navigateNext() {
		this.onboardingService.navigateNext()
	}
}
