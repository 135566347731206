<ng-content select="hum-bodygraph-details-left"></ng-content>

<div class="root-wrapper" [style.margin-left.px]="rootMargin" [style.margin-right.px]="rootMargin">
	<hum-bodygraph-root
		class="root"
		[groups]="groups"
		[color]="color"
		[gates]="gates"
		[centers]="centers"
		[style.max-width]="maxWidth"
		[style.transition]="getTransition()"
	></hum-bodygraph-root>
</div>

<ng-content select="hum-bodygraph-details-right"></ng-content>
