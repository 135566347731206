import {
	Component,
	Input,
	OnInit,
	forwardRef,
	EventEmitter,
	Output,
	ViewChild,
	ElementRef,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { IBlurable, IFocusable } from 'app/core/interfaces'
import { AUTO_FOCUSABLE } from 'app/theme/directives'

@Component({
	selector: 'hum-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputComponent),
			multi: true,
		},
		{
			provide: AUTO_FOCUSABLE,
			useExisting: forwardRef(() => InputComponent),
		},
	],
})
export class InputComponent implements OnInit, ControlValueAccessor, IFocusable, IBlurable {
	@ViewChild('input') input: ElementRef<HTMLInputElement>

	@Input() name: string
	@Input('placeholder') _placeholder = ''
	@Input() centered = true
	@Input() label: string
	@Input() type: string
	@Input() focused: boolean
	@Input() error: boolean = false
	@Input() enterkeyhint?: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send'
	@Input() inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
	/**
	 * @deprecated
	 */
	@Input('mask') _mask: 'date' | 'time'
	@Input() loading = false
	@Output() submit = new EventEmitter()
	@Output() next = new EventEmitter()

	value: string
	private onChange = (value: any) => {}
	private onTouched = () => {}
	constructor() {}

	ngOnInit(): void {}

	get isShowLabel() {
		return !!this.label
	}

	get active() {
		return !!this.value
	}

	get placeholder() {
		if (this.isShowLabel) {
			return ''
		}

		return this._placeholder
	}

	onSubmit(event: KeyboardEvent) {
		const key = event.key || event.keyCode
		if (key !== 'Enter' && key !== 13) return
		if (this.enterkeyhint === 'next') {
			this.next.emit()
		} else {
			this.submit.emit()
		}
	}

	public setFocus() {
		this.input.nativeElement.focus()
	}

	blur() {
		this.input.nativeElement.blur()
	}

	registerOnChange(fn: any) {
		this.onChange = fn
	}

	registerOnTouched(fn: () => {}): void {
		this.onTouched = fn
	}

	writeValue(outsideValue: string) {
		this.value = outsideValue
	}

	updateValue(insideValue: string) {
		this.value = insideValue
		this.onChange(insideValue)
		this.onTouched()
	}
}
