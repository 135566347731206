import { ViewDidEnter, ViewWillEnter } from '@ionic/angular'
import { Component } from '@angular/core'
import { OnboardingService } from '../../../../services/onboarding'
import { PushService, AnalyticsService } from 'app/core/services'

@Component({
	selector: 'hum-when-to-notify',
	templateUrl: './when-to-notify.component.html',
	styleUrls: ['./when-to-notify.component.scss'],
})
export class WhenToNotifyComponent implements ViewWillEnter, ViewDidEnter {
	constructor(
		private onboardingService: OnboardingService,
		private pushService: PushService,
		private analytics: AnalyticsService
	) {}

	ionViewWillEnter(): void {}

	ionViewDidEnter() {
		this.analytics.logOnboardingEvent('onb_notif')
	}

	times = [
		{
			value: '10:00',
			text: 'morning',
		},
		{
			value: '15:00',
			text: 'day',
		},
		{
			value: '20:00',
			text: 'evening',
		},
	]

	get isFormValid() {
		return this.activeTime !== null
	}

	activeTime: string | null = this.times[0].value

	async navigateNext() {
		await this.onboardingService.updateProfile({ when_to_notify: this.activeTime })
		try {
			await this.pushService.requestPermissions()
		} catch {}
		this.onboardingService.navigateNext()
	}

	isTimeActive(time: string) {
		return time === this.activeTime
	}

	onTimeClick(time: string) {
		this.activeTime = time
	}
}
