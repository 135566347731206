import { AnalyticsService } from 'app/core/services/analytics.service'
import {
	ApplicationRef,
	ComponentFactoryResolver,
	EmbeddedViewRef,
	Injectable,
	InjectionToken,
	Injector,
	NgZone,
	OnDestroy,
	RendererFactory2,
	StaticProvider,
} from '@angular/core'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { Sharing } from '@rediska1114/capacitor-sharing'
import { ConfigService } from './config.service'
import { v4 as uuid } from 'uuid'

import { ComponentType } from '../interfaces'
import { from, ReplaySubject } from 'rxjs'
import { take } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { Bodygraph, TransitPlanet } from '../models'
import { CanvasRenderService } from './canvas-render.service'
import { StoryWithAssets } from 'app/modules/home/services'

export const TEMPLATE_DATA = new InjectionToken('SHARING_TEMPLATE_DATA')

@Injectable({
	providedIn: 'root',
})
export class SharingService {
	constructor(
		private analytics: AnalyticsService,
		private router: Router,
		private sharing: Sharing,
		private config: ConfigService,
		private resolver: ComponentFactoryResolver,
		private injector: Injector,
		private rendererFactory: RendererFactory2,
		private zone: NgZone,
		private translate: TranslateService,
		private canvasRender: CanvasRenderService,
	) {}

	IMAGE_HEIGHT = 960 // 1920
	IMAGE_WIDTH = 540 //1080

	REQUIRED_FONTS = ['Peachi', 'TT Hoves']

	activeSharablePage: SharablePage | null = null

	screenshotModal: HTMLIonModalElement | null = null

	async onScreenshot() {
		this.analytics.logEvent('sharing_screenshot', {
			url: this._getCurrentUrl(),
			sharing: this.activeSharablePage?.SHARING_SOURCE || null,
		})

		const modalCtrl = this.injector.get(ModalController)
		const { ScreenshotModalComponent } = await import('app/theme/modals/screenshot-modal')

		this._dismissScreenshotModal()

		const modalId = uuid()

		this.screenshotModal = await modalCtrl.create({
			component: ScreenshotModalComponent,
			cssClass: 'hum-screenshot-modal',
			enterAnimation: ScreenshotModalComponent.enterAnimation,
			leaveAnimation: ScreenshotModalComponent.leaveAnimation,
			backdropDismiss: true,
			id: modalId,
			componentProps: {
				sharablePage: this.activeSharablePage,
				id: modalId,
			},
		})

		await this.screenshotModal.present()
	}

	private _dismissScreenshotModal() {
		if (this.screenshotModal) {
			this.screenshotModal.dismiss()
		}
	}

	onScreenRecording() {
		this.analytics.logEvent('sharing_screen_record', { url: this._getCurrentUrl() })
	}

	private _getCurrentUrl() {
		return this.router.url
	}

	async openSharingDialog(content: SharingContent) {
		const modalCtrl = this.injector.get(ModalController)
		const sharingModalComponent = await import('app/theme/modals/sharing-modal').then(
			m => m.SharingModalComponent,
		)
		const targets = await this.getAvailableSharingTargets()

		const image$ = new ReplaySubject<string>(1)
		const imageGenerationSub = from(content.imageGenerator()).pipe(take(1)).subscribe(image$)

		if (targets.length === 1) {
			await this.shareToTarget(targets[0], content, image$)
		} else {
			const share$ = new ReplaySubject<SharingTarget>(1)

			const shareSub = share$.subscribe(async target => {
				await this.shareToTarget(target, content, image$)
				modal.dismiss()
			})

			const modalId = uuid()

			const modal = await modalCtrl.create({
				component: sharingModalComponent,
				cssClass: 'hum-sharing-modal',
				backdropDismiss: true,
				id: modalId,
				componentProps: {
					targets,
					content,
					id: modalId,
					share: (target: SharingTarget) => share$.next(target),
				},
			})

			this._dismissScreenshotModal()

			await modal.present()

			await modal.onDidDismiss()

			shareSub.unsubscribe()
			share$.complete()
		}

		if (!imageGenerationSub.closed) {
			imageGenerationSub.unsubscribe()
		}
		if (!image$.complete) {
			image$.complete()
		}
	}

	private async shareToTarget(
		target: SharingTarget,
		content: SharingContent,
		image$: ReplaySubject<string>,
	) {
		const image = await image$.toPromise()

		const text = await this.translate
			.get('sharing.share_text', { link: this.config.sharingUrl })
			.toPromise()

		this.analytics.logEvent('sharing_share', {
			source: content.source,
			target,
			content_id: content.content_id,
			content_type: content.content_type,
			from_screenshot: content.from_screenshot,
		})

		switch (target) {
			case 'instagramStories':
				this.sharing.shareToInstagramStories({
					facebookAppId: this.config.facebookAppId,
					backgroundImageBase64: image,
				})
				break
			case 'native':
				this.sharing.share({
					title: content.title,
					text,
					imageBase64: image,
				})
				break
		}
	}

	private async getAvailableSharingTargets(): Promise<SharingTarget[]> {
		const [instagramStories] = await Promise.all([
			this.sharing.canShareToInstagramStories({ facebookAppId: this.config.facebookAppId }),
		])

		const targets: Record<SharingTarget, boolean> = {
			instagramStories,
			native: true,
		}

		return Object.keys(targets).filter(key => targets[key]) as SharingTarget[]
	}

	async imageFromArticle(data: SharingArticleTemplateData): Promise<string> {
		return this.renderTemplateToDataUrl(m => m.ArticleTemplateComponent, data)
	}

	async imageFromBodygraph(data: SharingBodygraphTemplateData): Promise<string> {
		return this.renderTemplateToDataUrl(m => m.BodygraphTemplateComponent, data)
	}

	async imageFromComposite(data: SharingCompositeTemplateData): Promise<string> {
		return this.renderTemplateToDataUrl(m => m.CompositeTemplateComponent, data)
	}

	async imageFromTransit(data: SharingTransitTemplateData): Promise<string> {
		return this.renderTemplateToDataUrl(m => m.TransitTemplateComponent, data)
	}

	async imageFromPlanet(data: SharingPlanetTemplateData): Promise<string> {
		return this.renderTemplateToDataUrl(m => m.PlanetTemplateComponent, data)
	}

	async imageFromStory(data: SharingStoryTemplateData): Promise<string> {
		return this.renderTemplateToDataUrl(m => m.StoryTemplateComponent, data)
	}

	private async renderTemplateToDataUrl(
		templateSelector: (m: typeof import('app/share-images-templates')) => ComponentType<unknown>,
		data: any = {},
	) {
		const template = await import('app/share-images-templates').then(templateSelector)

		const id = uuid().replace(/-/g, '')

		const appRef = this.injector.get(ApplicationRef)
		const renderer = this.rendererFactory.createRenderer(document.body, null)

		const injector = this._createInjector(data)
		const componentRef = this.resolver
			.resolveComponentFactory<TemplateInstance>(template)
			.create(injector)

		appRef.attachView(componentRef.hostView)

		componentRef.changeDetectorRef.detectChanges()

		const element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement

		const wrapperNode = document.createElement('div')
		wrapperNode.appendChild(element)

		renderer.addClass(wrapperNode, 'hum-image-generation-root-node')
		renderer.setAttribute(wrapperNode, 'id', id)
		renderer.setStyle(wrapperNode, 'width', `${this.IMAGE_WIDTH}px`)
		renderer.setStyle(wrapperNode, 'height', `${this.IMAGE_HEIGHT}px`)

		document.body.appendChild(wrapperNode)

		if (componentRef.instance.onReady) {
			await componentRef.instance.onReady
		}

		await new Promise(resolve => setTimeout(resolve, 100))

		const dataUrl = await this.zone.runOutsideAngular(() => {
			return this.canvasRender.elementToDataUrl(wrapperNode, this.REQUIRED_FONTS)
		})

		appRef.detachView(componentRef.hostView)
		componentRef.destroy()
		wrapperNode.remove()

		// this.tempShowImage(dataUrl)

		return dataUrl
	}

	private tempShowImage(dataUrl: string) {
		const image = document.createElement('img')
		image.src = dataUrl
		image.style.position = 'absolute'
		image.style.top = '80px'
		image.style.left = '40px'
		image.style.width = 'calc(100% - 80px)'
		image.style.border = '1px solid red'
		document.body.appendChild(image)
	}

	private _createInjector(data?: any) {
		const providers: StaticProvider[] = [{ provide: TEMPLATE_DATA, useValue: data || null }]

		return Injector.create({
			providers,
			parent: this.injector,
		})
	}
}

@Injectable()
export class SharablePageService implements OnDestroy {
	constructor(private sharing: SharingService) {}

	page?: SharablePage

	willEnter(page: SharablePage) {
		this.page = page
		this.sharing.activeSharablePage = page
	}

	willLeave() {
		this.sharing.activeSharablePage = null
	}

	ngOnDestroy(): void {
		this.page?.ready$?.complete()
	}
}

export abstract class SharablePage {
	abstract SHARING_SOURCE?: string
	abstract share(from_screenshot?: boolean): Promise<void>

	abstract ready$?: ReplaySubject<boolean>

	abstract canShare?(): boolean

	abstract ionViewWillEnter(): void

	abstract ionViewWillLeave(): void
}

export type SharingTarget = 'instagramStories' | 'native'

export type SharingContent = {
	// image: string
	imageGenerator: () => Promise<string>
	title: string

	source: string

	content_id?: string | number
	content_type?: string

	from_screenshot?: boolean
}

export interface SharingArticleTemplateData {
	title: string
	text: string
	image: string
}

export interface SharingDailyTipTemplateData {
	text: string
	image: string
}

export interface SharingBodygraphTemplateData {
	name: string
	type: string
	profile: string
	authority: string
	bodygraph: Bodygraph
}

export interface SharingTransitTemplateData {
	date: Date
	user_name: string
	userBodygraph: Bodygraph
	transitBodygraph: Bodygraph
	compositeBodygraph: Bodygraph
}

export interface SharingPlanetTemplateData {
	planet: TransitPlanet
}

export interface SharingStoryTemplateData {
	story: StoryWithAssets
}

export interface SharingCompositeTemplateData {
	compatibility_percentage: number

	user: {
		name: string
		type: string
		profile: string
		authority: string
		strategy: string
	}
	partner: {
		name: string
		type: string
		profile: string
		authority: string
		strategy: string
	}
	composite_bodygraph: {
		red_gates: number[]
		black_gates: number[]
	}
}

export type SharingShareToTarget = (target: SharingTarget | null) => void

export type TemplateInstance = {
	onReady?: Promise<void>
}
