<!-- TODO move to component -->
<div class="modal-header">
	<div class="modal-title">{{ 'contact_us.title' | translate }}</div>
	<div class="modal-close" (click)="dismiss()">
		<hum-icon icon="close" size="16"></hum-icon>
	</div>
</div>

<form [formGroup]="feedbackForm" class="page">
	<div class="top">
		<div class="faq" *ngIf="showFaq">
			<div class="faq-text">{{ 'contact_us.faq' | translate }}</div>
			<div class="faq-button" (click)="goToFaq()">{{ 'contact_us.faq_button' | translate }}</div>
		</div>
		<div class="title">{{ 'contact_us.text' | translate }}</div>
		<hum-textarea
			formControlName="text"
			enterkeyhint="next"
			(next)="nextInput(email)"
			[placeholder]="'contact_us.text_placeholder' | translate"
			rows="6"
			class="field"
		></hum-textarea>
		<div class="title email-title">{{ 'contact_us.email' | translate }}</div>
		<hum-input
			inputmode="email"
			enterkeyhint="done"
			type="email"
			formControlName="email"
			[placeholder]="'contact_us.email_placeholder' | translate"
			(submit)="submit()"
			[centered]="false"
			class="field"
			#email
		></hum-input>
	</div>

	<hum-button (click)="submit()" type="button" [disabled]="!feedbackForm.valid || loading">
		{{ (loading ? 'contact_us.loading' : 'contact_us.send_button') | translate }}
	</hum-button>
</form>
