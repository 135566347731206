import { ConfigService, RemoteConfigService, StorageService } from 'app/core/services'
import { Injectable } from '@angular/core'
import { ApiService } from 'app/core/api/api.service'
import { map } from 'rxjs/operators'
import { forkJoin, from } from 'rxjs'
import { add, differenceInMinutes } from 'date-fns'
import { Device } from '../../plugins'
import { App } from '@capacitor/app'
import { StoreReview } from 'capacitor-store-review'
import { isNative, isWeb } from '../utils'
import { environment } from '@env'

type LocalFeedbackStatus =
	| 'ignore'
	| 'positive'
	| 'negative_with_reaction'
	| 'negative_without_reaction'
interface LocalFeedback {
	status: LocalFeedbackStatus
	date: string
}

@Injectable({
	providedIn: 'root',
})
export class FeedbackService {
	constructor(
		private api: ApiService,
		private device: Device,
		private storage: StorageService,
		private config: ConfigService,
		private storeReview: StoreReview,
		private remoteConfig: RemoteConfigService,
	) {}

	async sendFeedback(text: string, email: string) {
		const deviceInfo = await this.device.getInfo()

		const app_version = await this.getAppVersion()

		const device = deviceInfo.manufacturer + ' ' + deviceInfo.model
		const os = deviceInfo.operatingSystem + ' ' + deviceInfo.osVersion

		return this.api
			.post(['accounts', 'feedback', 'submit'], {
				text,
				email,
				device,
				os,
				app_version,
			})
			.toPromise()
	}

	async shouldShowFeedback() {
		return forkJoin({
			backend: this.api.get<boolean>(['accounts', 'feedback', 'display']),
			saved_status: this.storage.get<LocalFeedback | null>('feedback_show'),
			remote_config: from(this.remoteConfig.getBool('show_review_card')),
		})
			.pipe(
				map(({ backend, saved_status, remote_config }) => {
					if (!backend || !remote_config) return false
					if (!saved_status) return true

					const date = new Date(saved_status.date)
					const hold_duration = this.getWaitTime(saved_status.status)
					const diff = differenceInMinutes(add(date, hold_duration), new Date())

					if (diff <= 0) {
						return true
					} else {
						return false
					}
				}),
			)
			.toPromise()
	}

	async openStoreReview() {
		await this.storeReview.navigateToAppStore()
	}

	async saveReaction(status: LocalFeedbackStatus) {
		return this.storage
			.set<LocalFeedback>('feedback_show', {
				status,
				date: new Date().toISOString(),
			})
			.toPromise()
	}

	private getWaitTime(status: LocalFeedbackStatus): Duration {
		const isTest = this.config.isTesting

		if (status === 'ignore') {
			return isTest ? { minutes: 3 } : { weeks: 1 }
		} else if (status === 'negative_with_reaction') {
			return isTest ? { minutes: 10 } : { months: 3 }
		} else if (status === 'negative_without_reaction') {
			return isTest ? { minutes: 7 } : { months: 2 }
		} else if (status === 'positive') {
			return isTest ? { minutes: 10 } : { months: 3 }
		}
	}

	private async getAppVersion() {
		if (isNative) {
			const appInfo = await App.getInfo()
			return appInfo.version + ' (' + appInfo.build + ')'
		} else {
			return environment.appVersion
		}
	}
}
