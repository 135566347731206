import { AuthService } from './../services/auth.service'
import { Injectable } from '@angular/core'
import {
	CanActivate,
	CanActivateChild,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	Router,
	CanLoad,
} from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
	constructor(private authService: AuthService, private router: Router) {}

	canActivate(
		_: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.isAuthorized(state.url)
	}

	canActivateChild(
		_: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.isAuthorized(state.url)
	}

	canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const navigation = this.router.getCurrentNavigation()
		let next = '/'
		if (navigation) {
			next = navigation.extractedUrl.toString()
		}

		return this.isAuthorized(next)
	}

	private isAuthorized(next: string = '/') {
		return this.authService.isAuthenticated.pipe(
			map((isAuthorized) => {
				if (!isAuthorized) {
					return this.router.createUrlTree(['/subscription'], { queryParams: { next } })
				}
				return true
			})
		)
	}
}
