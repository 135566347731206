import { Pipe, PipeTransform } from '@angular/core'
import { DateFormatService } from 'app/i18n'

@Pipe({
	name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
	constructor(private dateFormatService: DateFormatService) {}

	transform(value: string | Date | undefined | null, format_string: string): string {
		return this.dateFormatService.format(value, format_string)
	}
}
