<div class="content" [ngSwitch]="stage">
	<ng-container *ngSwitchCase="'initial'">
		<div class="title">{{ 'review_card.like_title' | translate }}</div>
		<div class="actions">
			<hum-button class="action" (click)="initial_no()" color="white">
				{{ 'review_card.actions.no' | translate }}
			</hum-button>
			<hum-button class="action" (click)="initial_yes()" color="gold">
				{{ 'review_card.actions.yes' | translate }}
			</hum-button>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="'agree'">
		<div class="title">{{ 'review_card.rate_title' | translate }}</div>
		<div class="actions">
			<hum-button class="action" (click)="agree_no()" color="white">
				{{ 'review_card.actions.disagree' | translate }}
			</hum-button>
			<hum-button class="action" (click)="agree_yes()" color="gold">
				{{ 'review_card.actions.agree' | translate }}
			</hum-button>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="'disagree'">
		<div class="title">{{ 'review_card.feedback_title' | translate }}</div>
		<div class="actions">
			<hum-button class="action" (click)="disagree_no()" color="white">
				{{ 'review_card.actions.disagree' | translate }}
			</hum-button>
			<hum-button class="action" (click)="disagree_yes()" color="gold">
				{{ 'review_card.actions.agree' | translate }}
			</hum-button>
		</div>
	</ng-container>
</div>
