import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	forwardRef,
} from '@angular/core'
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms'
import { toISO8601 } from 'app/core/utils'

@Component({
	selector: 'hum-datetime',
	templateUrl: './datetime.component.html',
	styleUrls: ['./datetime.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DatetimeComponent),
			multi: true,
		},
	],
})
export class DatetimeComponent implements OnInit, ControlValueAccessor {
	constructor(private cdr: ChangeDetectorRef) {}

	_date = this._toString(new Date())

	private onChange = (value: any) => {}
	private onTouched = () => {}

	ngOnInit(): void {}

	@Input()
	set date(value: Date | undefined | null) {
		this.writeValue(value)
	}

	@Input() presentation = 'date-time'
	@Input() max?: Date = undefined

	@Output() dateChange = new EventEmitter<Date>()

	ionChange(event: CustomEvent) {
		this.updateValue(event.detail.value)
	}

	registerOnChange(fn: any) {
		this.onChange = fn
	}

	registerOnTouched(fn: () => {}): void {
		this.onTouched = fn
	}

	writeValue(outsideValue: Date) {
		if (outsideValue) {
			this._date = this._toString(outsideValue)
		}
	}

	updateValue(insideValue: string) {
		this._date = insideValue
		this.onChange(this._toDate(insideValue))
		this.dateChange.emit(this._toDate(insideValue))
		this.onTouched()
	}

	private _toDate(date: string) {
		return new Date(date)
	}

	private _toString(date: Date) {
		return toISO8601(date)
	}
}
