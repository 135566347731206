import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'hum-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
	@Input() corners = true
	constructor() {}

	ngOnInit(): void {}
}
