import { DeeplinkService } from 'app/core/services'
import {
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	NgZone,
	Output,
} from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
	selector: 'hum-text',
	templateUrl: './text.component.html',
	styleUrls: ['./text.component.scss'],
})
export class TextComponent {
	textHTML: SafeHtml

	@HostBinding('innerHTML') get _textHTML() {
		return this.textHTML
	}

	@Input() set text(value: string) {
		if (!value) {
			this.textHTML = value
		} else {
			this.textHTML = this.sanitizer.bypassSecurityTrustHtml(value)
		}
	}

	@Output() deeplink = new EventEmitter<string>()

	constructor(
		private sanitizer: DomSanitizer,
		private zone: NgZone,
		private deeplinkService: DeeplinkService,
	) {}

	@HostListener('click', ['$event'])
	public handleClick(event: Event): void {
		const element = event.target
		if (element instanceof HTMLAnchorElement) {
			event.preventDefault()
			let route = element.getAttribute('href')

			if (route) {
				this.zone.run(() => {
					this.deeplinkService.handleDeeplink(route)
					this.deeplink.emit(route)
				})
			}
		}
	}
}
