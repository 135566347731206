import { Animation, ModalAnimationOptions, createAnimation } from '@ionic/core'

export const screenshotModalEnterAnimation = (
	baseEl: HTMLElement,
	opts: ModalAnimationOptions,
	duration = 500,
): Animation => {
	const root = getElementRoot(baseEl)

	const backdropAnimation = createAnimation()
		.addElement(root.querySelector('ion-backdrop')!)
		.fromTo('opacity', 0.01, 'var(--backdrop-opacity)')
		.beforeStyles({ 'pointer-events': 'none' })
		.afterClearStyles(['pointer-events'])

	const wrapperAnimation = createAnimation()
		.addElement(root.querySelectorAll('.modal-wrapper, .modal-shadow')!)
		.beforeStyles({ opacity: 1 })
		.fromTo('transform', 'translateY(-100%)', 'translateY(0%)')

	return createAnimation('entering-from-top')
		.addElement(baseEl)
		.easing('cubic-bezier(0.32,0.72,0,1)')
		.duration(duration)
		.addAnimation(wrapperAnimation)
		.addAnimation(backdropAnimation)
}

export const screenshotModalLeaveAnimation = (
	baseEl: HTMLElement,
	opts: ModalAnimationOptions,
	duration = 500,
): Animation => {
	const root = getElementRoot(baseEl)

	const backdropAnimation = createAnimation()
		.addElement(root.querySelector('ion-backdrop')!)
		.fromTo('opacity', 'var(--backdrop-opacity)', 0.01)
		.beforeStyles({ 'pointer-events': 'none' })
		.afterClearStyles(['pointer-events'])

	const wrapperAnimation = createAnimation()
		.addElement(root.querySelectorAll('.modal-wrapper, .modal-shadow')!)
		.beforeStyles({ opacity: 1 })
		.fromTo('transform', 'translateY(0%)', 'translateY(-100%)')

	return createAnimation('leaving-to-top')
		.addElement(baseEl)
		.easing('cubic-bezier(0.32,0.72,0,1)')
		.duration(duration)
		.addAnimation(wrapperAnimation)
		.addAnimation(backdropAnimation)
}

const getElementRoot = (el: HTMLElement, fallback: HTMLElement = el) => {
	return el.shadowRoot || fallback
}
