function PromiseStub(...args: any[]) {
	return Promise.resolve<any>({})
}

export class PushNotificationsWeb {
	/**
	 * Register the app to receive push notifications.
	 * Will trigger registration event with the push token
	 * or registrationError if there was some problem.
	 * Doesn't prompt the user for notification permissions, use requestPermission() first.
	 */
	register = PromiseStub
	/**
	 * On iOS it prompts the user to allow displaying notifications
	 * and return if the permission was granted or not.
	 * On Android there is no such prompt, so just return as granted.
	 */
	requestPermission = PromiseStub
	/**
	 * Returns the notifications that are visible on the notifications screen.
	 */
	getDeliveredNotifications = PromiseStub
	/**
	 * Removes the specified notifications from the notifications screen.
	 * @param delivered list of delivered notifications.
	 */
	removeDeliveredNotifications = PromiseStub
	/**
	 * Removes all the notifications from the notifications screen.
	 */
	removeAllDeliveredNotifications = PromiseStub
	/**
	 * On Android O or newer (SDK 26+) creates a notification channel.
	 * @param channel to create.
	 */
	createChannel = PromiseStub
	/**
	 * On Android O or newer (SDK 26+) deletes a notification channel.
	 * @param channel to delete.
	 */
	deleteChannel = PromiseStub
	/**
	 * On Android O or newer (SDK 26+) list the available notification channels.
	 */
	listChannels = PromiseStub
	/**
	 * Event called when the push notification registration finished without problems.
	 * Provides the push notification token.
	 * @param eventName registration.
	 * @param listenerFunc callback with the push token.
	 */
	addListener = () => {
		return {
			remove: () => {},
		}
	}

	/**
	 * Remove all native listeners for this plugin.
	 */
	removeAllListeners() {}
}
