import { ReplaySubject } from 'rxjs'
import {
	AfterViewInit,
	Directive,
	HostBinding,
	Inject,
	Input,
	OnDestroy,
	Optional,
	PLATFORM_ID,
} from '@angular/core'
import type { ISourceOptions } from 'tsparticles-engine'
import { ParticlesComponent, PARTICLES_PARENT } from './particles.component'
import { switchMap, take, takeUntil } from 'rxjs/operators'
import { isPlatformServer } from '@angular/common'

let nextUniqueId = 0

@Directive({
	selector: '[hum-particle]',
})
export class ParticleDirective implements AfterViewInit, OnDestroy {
	private destroyed$ = new ReplaySubject(1)

	@Input()
	@HostBinding('id')
	id = `hum-particle-${nextUniqueId++}`

	@Input('hum-particle') particleOptions?: ISourceOptions

	constructor(
		@Inject(PARTICLES_PARENT) @Optional() private parent: ParticlesComponent,
		@Inject(PLATFORM_ID) private platformId: string,
	) {}

	ngAfterViewInit() {
		if (isPlatformServer(this.platformId)) {
			return
		}

		this.parent?.engine
			.pipe(
				take(1),
				switchMap(e => e.load(this.id, this.particleOptions)),
				takeUntil(this.destroyed$),
			)
			.subscribe()
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true)
		this.destroyed$.complete()
	}
}
