import { config as development } from './development'
import { config as production } from './production'

export type ConfigName = 'development' | 'production'

export interface IConfig {
	production: boolean
	apiUrl: string
	isTesting: boolean
	apiVersion: string | number
	sentry: {
		dsn: string
	}
	oauth2: {
		vk: {
			appId: number
			version: string
			androidPackage: string
		}
	}
	appMetricaApiKey: string
	analyticsEnabled: boolean
	adapty: {
		apiKey: string
		paywallId: string
	}
	deeplinkSchema: string
	amplitudeApiKey: string
	oneSignalAppId: string
	facebookAppId: string

	sharingUrl: string
}

export interface IServerConfig {
	apiUrl: string
	environment: string
}

const configs: Record<ConfigName, IConfig> = {
	development,
	production,
}

export default configs
