import p from './points'

export type LinePoint = {
	x: number
	y: number
	id: number
}

const lines: Array<{
	p1: LinePoint
	p2: LinePoint
}> = [
	{
		p1: {
			id: 47,
			x: p[62].x,
			y: p[47].y,
		},
		p2: {
			id: 64,
			x: p[62].x,
			y: p[64].y,
		},
	},
	{
		p1: p[24],
		p2: p[61],
	},
	{
		p1: {
			id: 4,
			x: p[56].x,
			y: p[4].y,
		},
		p2: {
			id: 63,
			x: p[56].x,
			y: p[63].y,
		},
	},
	// -----------------
	{
		p1: {
			id: 17,
			x: p[62].x,
			y: p[17].y,
		},
		p2: p[62],
	},
	{
		p1: p[23],
		p2: p[43],
	},
	{
		p1: {
			id: 11,
			x: p[56].x,
			y: p[11].y,
		},
		p2: p[56],
	},
	// -----------------
	{
		p1: p[7],
		p2: p[31],
	},
	{
		p1: p[1],
		p2: p[8],
	},
	{
		p1: p[13],
		p2: p[33],
	},
	// --------------
	{
		p1: p[21],
		p2: p[45],
	},
	{
		p1: p[25],
		p2: p[51],
	},
	// -------
	{
		p1: {
			id: 35,
			x: p[35].x + 4,
			y: p[35].y - 11,
		},
		p2: p[36],
	},
	{
		p1: {
			id: 16,
			x: p[16].x - 4,
			y: p[16].y - 17,
		},
		p2: p[48],
	},
	//-------
	{
		p1: p[26],
		p2: p[44],
	},
	//------
	{
		p1: p[5],
		p2: p[15],
	},
	{
		p1: p[2],
		p2: p[14],
	},
	{
		p1: p[29],
		p2: p[46],
	},
	// -----
	{
		p1: p[34],
		p2: {
			id: 34,
			x: p[44].x,
			y: p[44].y - 29,
		},
	},
	{
		p1: p[10],
		p2: {
			id: 10,
			x: p[10].x - 45,
			y: p[10].y,
		},
	},
	// ------
	{
		p1: {
			id: 20,
			x: p[20].x,
			y: p[20].y - 10,
		},
		p2: p[57],
	},
	{
		p1: p[37],
		p2: p[40],
	},
	{
		p1: p[6],
		p2: p[59],
	},
	{
		p1: p[12],
		p2: p[22],
	},
	// -----
	{
		p1: {
			id: 19,
			x: p[19].x,
			y: p[19].y - 2,
		},
		p2: {
			id: 49,
			x: p[49].x,
			y: p[49].y + 7,
		},
	},
	{
		p1: {
			id: 39,
			x: p[39].x,
			y: p[39].y + 1,
		},
		p2: {
			id: 55,
			x: p[55].x,
			y: p[55].y + 3,
		},
	},
	{
		p1: p[30],
		p2: {
			id: 41,
			x: p[41].x,
			y: p[41].y + 4,
		},
	},
	// ---------------
	{
		p1: {
			id: 32,
			x: p[32].x,
			y: p[32].y + 7,
		},
		p2: {
			id: 54,
			x: p[54].x,
			y: p[54].y - 2,
		},
	},
	{
		p1: {
			id: 28,
			x: p[28].x,
			y: p[28].y + 3,
		},
		p2: {
			id: 38,
			x: p[38].x,
			y: p[38].y + 1,
		},
	},
	{
		p1: p[18],
		p2: {
			id: 58,
			x: p[58].x,
			y: p[58].y + 4,
		},
	},
	// -----
	{
		p1: p[42],
		p2: p[53],
	},
	{
		p1: p[3],
		p2: p[60],
	},
	{
		p1: p[9],
		p2: p[52],
	},
	// ----
	{
		p1: {
			id: 27,
			x: p[27].x,
			y: p[27].y + 10,
		},
		p2: {
			id: 50,
			x: p[50].x,
			y: p[50].y - 5,
		},
	},

]

export default lines
