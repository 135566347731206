import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Capacitor } from '@capacitor/core'

@Injectable()
export class PlatformInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let platform = Capacitor.getPlatform()

		request = request.clone({
			headers: request.headers.set('X-User-Platform', platform),
		})
		return next.handle(request)
	}
}
