import { Article } from 'app/core/models';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hum-card-article',
  templateUrl: './card-article.component.html',
  styleUrls: ['./card-article.component.scss']
})
export class CardArticleComponent implements OnInit {
  @Input() article: Article // TODO type
  constructor() { }

  ngOnInit(): void {
  }

}
