import { AnalyticsService } from 'app/core/services'
import { Component } from '@angular/core'
import {
	AppTrackingTransparency,
	AppTrackingTransparencyStatus,
} from 'capacitor-app-tracking-transparency'
import { ViewDidEnter } from '@ionic/angular'
import { OnboardingService } from './../../../../services/onboarding'
import { Facebook } from 'capacitor-facebook'

@Component({
	selector: 'hum-tracking',
	templateUrl: './tracking.component.html',
	styleUrls: ['./tracking.component.scss'],
})
export class TrackingComponent implements ViewDidEnter {
	constructor(
		private analytics: AnalyticsService,
		private att: AppTrackingTransparency,
		private onboarding: OnboardingService,
		private facebook: Facebook,
	) {}

	ionViewDidEnter() {
		this.analytics.logOnboardingEvent('onb_idfa')
	}

	// https://ionicframework.com/blog/answering-app-privacy-questions-in-app-store-connect/
	// https://stackoverflow.com/a/63558727
	async requestTracking() {
		const status = await this.att.getStatus()
		if (status !== AppTrackingTransparencyStatus.authorized) {
			const new_status = await this.att.requestPermission()

			const isAuthorized = new_status === AppTrackingTransparencyStatus.authorized

			this.analytics.logOnboardingEvent('onb_idfa_status', { opt_in: isAuthorized })

			if (isAuthorized) {
				try {
					await this.facebook.setAutoLogAppEvents(true)
					await this.facebook.setAdvertiserIDCollection(true)
					await this.facebook.setAdvertiserTracking(true)
					await this.facebook.setAudienceNetworkAdvertiserTracking(true)
				} catch (err) {
					console.warn(`[tracking] — this.facebook.setAdvertiserTracking error`, err)
				}
			}
		}

		await this.onboarding.navigateNext()
	}
}
