import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'

export const OUTSIDE_CLICK_IGNORE_ATTR = 'data-outside-click-ignore'

@Directive({
	selector: '[humOutsideClickIgnore]',
})
export class OutsideClickIgnoreDirective implements OnInit {
	constructor(private host: ElementRef<HTMLElement>, private renderer: Renderer2) {}
 
  @Input('humOutsideClickIgnore') _

	ngOnInit() {
		this.renderer.setAttribute(this.host.nativeElement, OUTSIDE_CLICK_IGNORE_ATTR, 'true')
	}


}
