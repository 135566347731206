import { OUTSIDE_CLICK_IGNORE_ATTR } from './../../directives/outside-click-ignore.directive'
import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
} from '@angular/core'
import type { CupertinoPane } from 'cupertino-pane'
import { v4 as uuid } from 'uuid'
import { BehaviorSubject } from 'rxjs'
import { TooltipAwaiter } from 'app/core/services'

@Component({
	selector: 'hum-bottom-drawer',
	templateUrl: './bottom-drawer.component.html',
	styleUrls: ['./bottom-drawer.component.scss'],
	providers: [TooltipAwaiter],
})
export class BottomDrawerComponent implements OnInit, OnDestroy {
	pane?: CupertinoPane
	constructor(
		private host: ElementRef<HTMLElement>,
		private renderer: Renderer2,
		private tooltipAwaiter: TooltipAwaiter,
	) {}

	@ViewChild('wrapper', { static: true }) wrapper?: ElementRef<HTMLElement>

	@Input() breakTop = true
	@Input() breakMiddle = true
	@Input() breakMiddleHeight = 300
	@Input() breakBottom = true
	@Input() breakBottomHeight = 64
	@Input() fitHeight = false

	@Input() closeOnOutsideClick: boolean = false
	@Input() closeOnOutsideClickScope: 'page' | 'window' = 'page'
	@Input() wrapperClass = ''

	private hidden$ = new BehaviorSubject(false)

	@Input('hidden')
	set setHidden(value: boolean) {
		this.hidden$.next(value)

		if (value) {
			this.pane?.hide()
		} else {
			this.pane?.present()
		}
	}

	@Output() initialized = new EventEmitter<CupertinoPane>()

	scrollElement?: HTMLElement

	ngOnInit(): void {
		this.scrollElement = this.wrapper?.nativeElement

		this.init()
	}

	ngOnDestroy() {
		this.destroy()
	}

	moveToBreak(breakName: 'top' | 'middle' | 'bottom') {
		this.pane.moveToBreak(breakName)
	}

	async present() {
		if (!this.pane) return

		await this.pane.present({ animate: true })
	}

	private async destroy() {
		if (!this.pane) return

		await this.pane.destroy({ animate: true })
	}

	private async init() {
		const ionContent = this.scrollElement?.closest('ion-content')
		if (ionContent && !ionContent.id) {
			ionContent.id = uuid().replace(/-/g, '')
		}

		const parentElementSelector = ionContent ? `ion-content[id="${ionContent.id}"]` : 'body'

		const ionTabs = this.scrollElement?.closest('ion-tabs')
		let bottomTabsOffset = 0

		if (ionTabs) {
			const tabBar = ionTabs.querySelector('ion-tab-bar')
			bottomTabsOffset = tabBar.getBoundingClientRect().height
		}

		const topBreak = window.innerHeight - (this.deviceOffset + 84) // хз что за константа. Скорее всего размер хэдэра + смещение

		const cuperinoPane = await import('cupertino-pane').then(m => m.CupertinoPane)

		this.renderer.addClass(this.scrollElement, 'initialized')

		this.pane = new cuperinoPane(this.scrollElement, {
			parentElement: parentElementSelector,
			breaks: {
				top: { enabled: this.breakTop, height: topBreak },
				middle: { enabled: this.breakMiddle, height: this.breakMiddleHeight },
				bottom: { enabled: this.breakBottom, height: this.breakBottomHeight },
			},
			initialBreak: 'middle',
			buttonDestroy: false,
			showDraggable: false,
			draggableOver: false,
			backdrop: true,
			backdropOpacity: 0,
			fitHeight: this.fitHeight,
			maxFitHeight: topBreak,
			bottomOffset: bottomTabsOffset,
			events: {
				onTransitionEnd: e => {
					if (ionContent && this.pane.overflowEl.style.overflow == 'hidden') {
						ionContent.style.setProperty('--overflow', 'auto')
					}
				},
				onDidPresent: e => {
					const paneEl = this.scrollElement.closest('.pane')
					paneEl.addEventListener('touchstart', () => {
						if (ionContent) {
							ionContent.style.setProperty('--overflow', 'hidden')
						}
					})
					this.tooltipAwaiter.ready()
				},
			},
		})

		if (!this.hidden$.getValue()) {
			this.present()
		}

		this.initialized.emit(this.pane)
	}

	@HostListener('window:touchstart', ['$event'])
	@HostListener('window:mousedown', ['$event'])
	onTouch(event: Event) {
		if (
			!this.closeOnOutsideClick ||
			!this.pane ||
			this.hidden$.getValue() ||
			this.pane.currentBreak() === 'bottom'
		)
			return

		const checkScope = (target: HTMLElement) => {
			switch (this.closeOnOutsideClickScope) {
				case 'window': {
					return true
				}
				case 'page': {
					const ionPage = this.scrollElement?.closest('.ion-page')
					return ionPage?.contains(target)
				}
				default: {
					return true
				}
			}
		}

		const checkIgnore = (target: HTMLElement) => {
			return !target.closest(`[${OUTSIDE_CLICK_IGNORE_ATTR}]`)
		}

		const target = event.target as HTMLElement
		if (!this.pane.wrapperEl?.contains(target) && checkScope(target) && checkIgnore(target)) {
			this.moveToBreak('bottom')
		}
	}

	private get deviceOffset() {
		const style = getComputedStyle(document.documentElement)
		return Math.max(
			parseFloat(style.getPropertyValue('--ion-safe-area-top')),
			parseFloat(style.getPropertyValue('--default-safe-area-top')),
		)
	}
}
