import { Injectable } from '@angular/core'
import { IonRouterOutlet, ModalController } from '@ionic/angular'
import { ArticleModalComponent } from 'app/theme/modals'

@Injectable()
export class ArticleModalService {
	constructor(private modal: ModalController, private routerOutlet: IonRouterOutlet) {}

	async open(articleId: string | number) {
		const modal = await this.modal.create({
			component: ArticleModalComponent,
			componentProps: { articleId },
			presentingElement: this.routerOutlet.nativeEl,
			canDismiss: true,
			animated: true,
		})

		await modal.present()
	}
}
