import { Component } from '@angular/core'
import { AnalyticsService } from 'app/core/services'
import { Onboarding, OnboardingService } from '../../../../services/onboarding'
import { ViewDidEnter } from '@ionic/angular'

@Component({
	selector: 'hum-goal',
	templateUrl: './goal.component.html',
	styleUrls: ['./goal.component.scss'],
})
export class GoalComponent implements ViewDidEnter {
	onboarding: Onboarding | null

	constructor(private onboardingService: OnboardingService, private analytics: AnalyticsService) {
		this.onboardingService.intro_data.subscribe((onboarding) => {
			this.onboarding = onboarding
		})
	}

	ionViewDidEnter() {
		this.analytics.logOnboardingEvent('onb_goal')
	}

	get screen() {
		return this.onboarding?.goal
	}

	get isFormValid() {
		return this.activeGoals.length > 0
	}

	navigateNext() {
		if (!this.isFormValid) return

		this.onboardingService.updateProfile({ goals: this.activeGoals })

		this.onboardingService.navigateNext()

		this.analytics.logOnboardingEvent('onb_goal_selection', {
			// TODO слать slug вместо текста
			titles: this.screen.choices
				.filter((g) => this.activeGoals.includes(g.id))
				.map((g) => g.value),
		})
	}

	activeGoals: number[] = []

	isGoalActive(goal: number) {
		return this.activeGoals.includes(goal)
	}

	onGoalClick(goal: number) {
		if (this.isGoalActive(goal)) {
			this.activeGoals = this.activeGoals.filter((r) => r !== goal)
		} else {
			this.activeGoals.push(goal)
		}
	}
}
