import { PluginListenerHandle } from '@capacitor/core'
import {
	KeyboardInfo,
	KeyboardPlugin,
	KeyboardResizeOptions,
	KeyboardStyleOptions,
} from '@capacitor/keyboard'

export abstract class Keyboard implements KeyboardPlugin {
	/**
	 * Show the keyboard. This method is alpha and may have issues.
	 *
	 * This method is only supported on Android.
	 *
	 * @since 1.0.0
	 */
	abstract show(): Promise<void>
	/**
	 * Hide the keyboard.
	 *
	 * @since 1.0.0
	 */
	abstract hide(): Promise<void>
	/**
	 * Set whether the accessory bar should be visible on the keyboard. We recommend disabling
	 * the accessory bar for short forms (login, signup, etc.) to provide a cleaner UI.
	 *
	 * This method is only supported on iPhone devices.
	 *
	 * @since 1.0.0
	 */
	abstract setAccessoryBarVisible(options: { isVisible: boolean }): Promise<void>
	/**
	 * Programmatically enable or disable the WebView scroll.
	 *
	 * This method is only supported on iOS.
	 *
	 * @since 1.0.0
	 */
	abstract setScroll(options: { isDisabled: boolean }): Promise<void>
	/**
	 * Programmatically set the keyboard style.
	 *
	 * This method is only supported on iOS.
	 *
	 * @since 1.0.0
	 */
	abstract setStyle(options: KeyboardStyleOptions): Promise<void>
	/**
	 * Programmatically set the resize mode.
	 *
	 * This method is only supported on iOS.
	 *
	 * @since 1.0.0
	 */
	abstract setResizeMode(options: KeyboardResizeOptions): Promise<void>
	/**
	 * Listen for when the keyboard is about to be shown.
	 *
	 * @since 1.0.0
	 */
	abstract addListener(
		eventName: 'keyboardWillShow',
		listenerFunc: (info: KeyboardInfo) => void
	): Promise<PluginListenerHandle> & PluginListenerHandle
	/**
	 * Listen for when the keyboard is shown.
	 *
	 * @since 1.0.0
	 */
	abstract addListener(
		eventName: 'keyboardDidShow',
		listenerFunc: (info: KeyboardInfo) => void
	): Promise<PluginListenerHandle> & PluginListenerHandle
	/**
	 * Listen for when the keyboard is about to be hidden.
	 *
	 * @since 1.0.0
	 */
	abstract addListener(
		eventName: 'keyboardWillHide',
		listenerFunc: () => void
	): Promise<PluginListenerHandle> & PluginListenerHandle
	/**
	 * Listen for when the keyboard is hidden.
	 *
	 * @since 1.0.0
	 */
	abstract addListener(
		eventName: 'keyboardDidHide',
		listenerFunc: () => void
	): Promise<PluginListenerHandle> & PluginListenerHandle
	/**
	 * Remove all native listeners for this plugin.
	 *
	 * @since 1.0.0
	 */
	abstract removeAllListeners(): Promise<void>
}
