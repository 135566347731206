import { AfterContentInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core'

@Directive({
	selector: 'span[humTextFill]',
})
export class TextFillDirective implements AfterContentInit, OnDestroy {
	constructor(private renderer: Renderer2, private host: ElementRef<HTMLElement>) {
		this.ourText = this.host.nativeElement
	}

	private processing = false
	private observer = new MutationObserver(() => setTimeout(() => this.resizeText()))

	@Input('humTextFill') _: unknown
	@Input() maxFontPixels = 48
	@Input() minFontPixels = 14
	// @Input() innerTag = 'span';
	@Input() widthOnly = false

	@Input() explicitWidth = null
	@Input() explicitHeight = null
	@Input() changeLineHeight = true
	@Input() truncateOnFail = false
	@Input() allowOverflow = false // If true, text will stay at minFontPixels but overflow container w/out failing

	ourText: HTMLElement

	// Let's get it started (yeah)!

	ngAfterContentInit() {
		setTimeout(() => {
			this.resizeText()
			this.registerListenerForDomChanges()
		})
	}

	ngOnDestroy() {
		this.observer.disconnect()
	}

	private registerListenerForDomChanges() {
		this.observer.observe(this.host.nativeElement, {
			characterData: true,
			subtree: true,
			childList: true,
		})
	}

	private getHeight(el: HTMLElement) {
		return el.getBoundingClientRect().height
	}
	private getWidth(el: HTMLElement) {
		return el.getBoundingClientRect().width
	}

	resizeText() {
		if (this.processing) return

		this.processing = true
		// Will resize to this dimensions.
		// Use explicit dimensions when specified

		let getMaxWidth = () => this.getWidth(this.ourText.parentElement)
		let getMaxHeight = () => this.getHeight(this.ourText.parentElement)

		const styles = window.getComputedStyle(this.ourText, null)
		let oldFontSize = styles.fontSize

		console.debug(`Old font size: ${oldFontSize}`)

		let lineHeight = (parseFloat(styles.lineHeight) / parseFloat(oldFontSize)) * 100

		let minFontPixels = this.minFontPixels

		// Remember, if this `maxFontPixels` is negative,
		// the text will resize to as long as the container
		// can accomodate
		let maxFontPixels = this.maxFontPixels <= 0 ? getMaxHeight() : this.maxFontPixels

		// Let's start it all!

		// 1. Calculate which `font-size` would
		//    be best for the Height
		let fontSizeHeight = undefined

		if (this.widthOnly) {
			// We need to measure with nowrap otherwise wrapping occurs and the measurement is wrong
			this.renderer.setStyle(this.ourText, 'white-space', 'nowrap')
		} else {
			fontSizeHeight = this._sizing(
				this.getHeight.bind(this),
				() => this.explicitHeight || getMaxHeight(),
				minFontPixels,
				maxFontPixels,
				lineHeight,
			)
		}

		// 2. Calculate which `font-size` would
		//    be best for the Width
		let fontSizeWidth = this._sizing(
			this.getWidth.bind(this),
			() => this.explicitHeight || getMaxWidth(),
			minFontPixels,
			maxFontPixels,
			lineHeight,
		)

		// 3. Actually resize the text!

		let fontSizeFinal = fontSizeWidth

		console.debug(`fontSizeFinal: ${fontSizeFinal}`)

		console.debug(`fontSizeHeight: ${fontSizeHeight}, fontSizeWidth: ${fontSizeWidth}`)

		if (!this.widthOnly) {
			fontSizeFinal = Math.min(fontSizeHeight, fontSizeWidth)
		}

		console.debug(`fontSizeFinal: ${fontSizeFinal}`)

		// console.debug(`resize font from ${oldFontSize} to ${fontSizeFinal} px`)

		this.renderer.setStyle(this.ourText, 'fontSize', fontSizeFinal + 'px')

		if (this.changeLineHeight) {
			this.renderer.setStyle(this.ourText, 'line-height', lineHeight + '%')
			this.renderer.setStyle(this.ourText, 'display', 'inline-block')
		}

		// Oops, something wrong happened!
		// If font-size increasing, we weren't supposed to exceed the original size
		// If font-size decreasing, we hit minFontPixels, and still won't fit
		if (
			(this.ourText.getBoundingClientRect().width > getMaxWidth() && !this.allowOverflow) ||
			(this.ourText.getBoundingClientRect().height > getMaxHeight() &&
				!this.widthOnly &&
				!this.allowOverflow)
		) {
			console.debug('Failed to resize text')
			this.renderer.setStyle(this.ourText, 'fontSize', oldFontSize)

			// fail
		}
		// okay!

		this.processing = false
	}

	/**
	 * Calculates which size the font can get resized,
	 * according to constrains.
	 *
	 * @param {Object} ourText The DOM element to resize,
	 *                         that contains the text.
	 * @param {function} func Function called on `ourText` that's
	 *                        used to compare with `max`.
	 * @param {number} maxFunc Maximum value, that gets compared with
	 *                     `func` called on `ourText`.
	 * @param {number} minFontPixels Minimum value the font can
	 *                               get resized to (in pixels).
	 * @param {number} maxFontPixels Maximum value the font can
	 *                               get resized to (in pixels).
	 *
	 * @return Size (in pixels) that the font can be resized.
	 */
	_sizing(
		curSizeFunc: (el: HTMLElement) => number,
		maxSizeFunc: () => number,
		minFontPixels: number,
		maxFontPixels: number,
		lineHeight: number,
	) {
		/**
		The kernel of the whole plugin, take most attention
		on this part.
		
		This is a loop that keeps increasing the `font-size`
		until it fits the parent element.
		
		- Start from the minimal allowed value (`minFontPixels`)
		- Guesses an average font size (in pixels) for the font,
		- Resizes the text and sees if its size is within the
		  boundaries (`minFontPixels` and `maxFontPixels`).
		  - If so, keep guessing until we break.
		  - If not, return the last calculated size.
		
		I understand this is not optimized and we should
		consider implementing something akin to
		Daniel Hoffmann's answer here:
		
			http://stackoverflow.com/a/17433451/1094964
		*/

		while (minFontPixels < Math.floor(maxFontPixels) - 1) {
			// debug info
			console.debug(`_sizing start ${minFontPixels} < ${maxFontPixels}`)
			let fontSize = Math.floor((minFontPixels + maxFontPixels) / 2)

			this.renderer.setStyle(this.ourText, 'fontSize', fontSize + 'px')
			if (this.changeLineHeight) {
				this.renderer.setStyle(this.ourText, 'display', 'inline-block')
				this.renderer.setStyle(this.ourText, 'line-height', lineHeight + '%')
			}

			let curSize = curSizeFunc.call(this, this.ourText) // TODO FIX
			let maxSize = maxSizeFunc.call(this)

			if (curSize <= maxSize) {
				minFontPixels = fontSize

				// if (curSize == maxSize) {
				// 	console.debug('perfecto!')
				// 	break
				// }
			} else {
				maxFontPixels = fontSize
			}
			console.debug(
				`_sizing stop curSize=${curSize}, maxSize=${maxSize} ${minFontPixels} < ${maxFontPixels}`,
			)
		}

		this.renderer.setStyle(this.ourText, 'fontSize', maxFontPixels + 'px')
		let maxSize = maxSizeFunc.call(this)

		if (curSizeFunc.call(this, this.ourText) <= maxSize) {
			minFontPixels = maxFontPixels
		}
		console.debug(`_sizing set style fontSize=${minFontPixels} px`)
		return minFontPixels
	}

	// return this
}
