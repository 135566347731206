<hum-top-gradient></hum-top-gradient>
<ion-content [scrollY]="false" [fullscreen]="true">
	<div class="hum-content back-button-wrapper">
		<hum-back-button class="back-button" [defaultHref]="defaultHref"></hum-back-button>
	</div>
	<div class="page hum-content">
		<div class="top">
			<div class="title">{{ 'onboarding.birthdaydate.title' | translate }}</div>

			<hum-datetime
				[formControl]="birthday_date"
				[presentation]="'date'"
				[max]="maxDate"
				class="field"
			></hum-datetime>
		</div>
	</div>
	<div class="footer">
		<hum-button (click)="navigateNext()" class="hum-content">
			{{ 'onboarding.birthdaydate.next_button' | translate }}
		</hum-button>
	</div>
	<hum-bottom-gradient></hum-bottom-gradient>
</ion-content>
