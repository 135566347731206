import { OnboardingGuard } from './core/guards/onboarding.guard'
import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from 'app/core/guards'
import { SubscriptionGuard, HasBodygraphGuard } from 'app/core/guards'

const routes: Routes = [
	// ================== ONBOARDING ==================
	{
		path: 'onboarding',
		loadChildren: () =>
			import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule),
	},
	{
		path: 'subscription',
		canActivate: [OnboardingGuard],
		loadChildren: () =>
			import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule),
	},
	{
		path: 'registration',
		canActivate: [OnboardingGuard, AuthGuard, SubscriptionGuard],
		canLoad: [OnboardingGuard, AuthGuard, SubscriptionGuard],
		loadChildren: () =>
			import('./modules/registration/registration.module').then(m => m.RegistrationModule),
	},
	// ================== APP ==================
	{
		path: 'settings',
		canActivate: [OnboardingGuard, AuthGuard, SubscriptionGuard],
		canLoad: [OnboardingGuard, AuthGuard, SubscriptionGuard],
		loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
	},
	{
		path: 'home',
		canActivate: [OnboardingGuard, AuthGuard, SubscriptionGuard, HasBodygraphGuard],
		canLoad: [OnboardingGuard, AuthGuard, SubscriptionGuard, HasBodygraphGuard],
		loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
	},
	{
		path: 'deeplink',
		canActivate: [OnboardingGuard, AuthGuard],
		canLoad: [OnboardingGuard, AuthGuard],
		loadChildren: () => import('./modules/deeeplink/deeeplink.module').then(m => m.DeeeplinkModule),
	},
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
]

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
