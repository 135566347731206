export const PLANETS = [
	'sun',
	'earth',
	'north_node',
	'south_node',
	'moon',
	'mercury',
	'venus',
	'mars',
	'jupiter',
	'saturn',
	'uranus',
	'neptune',
	'pluto',
]
