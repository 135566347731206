function PromiseStub(...args: any[]) {
	return Promise.resolve()
}

export class OneSignalWeb {
	addListener = PromiseStub
	setLogLevel = PromiseStub
	init = PromiseStub
	setProvidesNotificationSettingsView = PromiseStub
	getDeviceState = PromiseStub
	setLanguage = PromiseStub
	promptForPushNotifications = PromiseStub
	register = PromiseStub
	disablePush = PromiseStub
	setExternalUserId = PromiseStub
	removeExternalUserId = PromiseStub
	setLaunchURLsInApp = PromiseStub
}
