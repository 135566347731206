import { Adapty } from 'capacitor-adapty'
import { TokenService } from './token.service'
import { Injectable } from '@angular/core'
import { switchMap, tap } from 'rxjs/operators'
import { StorageService } from './storage.service'
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private tokenService: TokenService,
		private storageService: StorageService,
		private adapty: Adapty
	) {}

	get isAuthenticated() {
		return this.tokenService.tokenState
	}

	logout() {
		return this.tokenService.clear().pipe(
			switchMap(() => {
				return Promise.all([
					this.adapty.logout(),
					this.storageService.clear('user'),
					this.storageService.clear('user_id'),
				])
			}),
			tap(() => {
				window.location.href = '/'
			})
		)
	}
}
