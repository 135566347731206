import { Pipe, PipeTransform } from '@angular/core'

const GRADIENTS_COUNT = 73

@Pipe({
	name: 'gradientImage',
})
export class GradientImagePipe implements PipeTransform {
	transform(seed: number): string {
		return `/assets/gradients/gradient_${((seed - 1) % GRADIENTS_COUNT) + 1}.jpg`
	}
}
