import { Component, Input, OnInit } from '@angular/core'
import { Article } from 'app/core/models'

@Component({
	selector: 'hum-article-card',
	templateUrl: './article-card.component.html',
	styleUrls: ['./article-card.component.scss'],
})
export class ArticleCardComponent implements OnInit {
	@Input() article: Article
	constructor() {}

	ngOnInit(): void {}
}
