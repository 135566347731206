import { Router } from '@angular/router'
import { ConfigService } from 'app/core/services'
import { Injectable } from '@angular/core'
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'

@Injectable({
	providedIn: 'root',
})
export class DeeplinkService {
	constructor(
		private config: ConfigService,
		private router: Router,
		private safariViewController: SafariViewController,
		private inAppBrowser: InAppBrowser
	) {}

	handleDeeplink(link: string) {
		if (!link) return

		let route = link

		if (route.startsWith(`${this.config.deeplinkSchema}://`)) {
			route = route.slice(`${this.config.deeplinkSchema}://`.length)
		}

		this.router.navigateByUrl(route).catch((err) => {
			console.warn('Bad deeplink:', route, err)
		})
	}

	async openExternalUrl(url: string) {
		try {
			const isAvailable = await this.safariViewController.isAvailable()

			if (isAvailable) {
				await this.safariViewController
					.show({
						url,
						hidden: false,
						animated: false,
						transition: 'curl',
						enterReaderModeIfAvailable: true,
					})
					.toPromise()
			} else {
				this.inAppBrowser.create(url, '_system')
			}
		} catch (error) {
			console.error(error)
		}
	}
}
