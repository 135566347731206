import { Injectable } from '@angular/core'
import {
	CanActivate,
	CanActivateChild,
	CanLoad,
	Router,
	RouterStateSnapshot,
} from '@angular/router'
import { UsersService } from '../services'
import { map } from 'rxjs/operators'

@Injectable({
	providedIn: 'root',
})
export class HasBodygraphGuard implements CanActivate, CanActivateChild, CanLoad {
	constructor(private usersService: UsersService, private router: Router) {}

	canActivate(_: unknown, state: RouterStateSnapshot) {
		return this._checkBodygraph(state.url)
	}
	canActivateChild(_: unknown, state: RouterStateSnapshot) {
		return this._checkBodygraph(state.url)
	}

	canLoad() {
		const navigation = this.router.getCurrentNavigation()
		let next = '/'
		if (navigation) {
			next = navigation.extractedUrl.toString()
		}

		return this._checkBodygraph(next)
	}

	private _checkBodygraph(next?: string) {
		return this.usersService.hasBodygraph().pipe(
			map(hasBodygraph => {
				if (hasBodygraph) return true

				return this.router.createUrlTree(['/settings/profile'], {
					queryParams: { empty_data: true, next },
				})
			}),
		)
	}
}
