import { Component, HostListener, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ModalController } from '@ionic/angular'
import { toISO8601 } from 'app/core/utils'

@Component({
	selector: 'hum-datepicker-modal',
	templateUrl: './datepicker-modal.component.html',
	styleUrls: ['./datepicker-modal.component.scss'],
})
export class DatepickerModalComponent {
	constructor(private modalController: ModalController) {}

	// The `ion-modal` element reference.
	modal: HTMLElement

	date = new FormControl(this._toString(new Date()))

	@Input()
	set value(value: Date | null) {
		if (value) {
			this.date.setValue(this._toString(value))
		}
	}

	@Input() format = undefined

	@Input() options?: DatepickerOptions

	@HostListener('click')
	onHostClick() {
		this.cancel()
	}

	@Input() presentation = 'date-time'

	done() {
		this.modalController.dismiss({ value: this._toDate(this.date.value) })
	}

	cancel() {
		this.modalController.dismiss()
	}

	stopPropagation(event: Event) {
		event.stopPropagation()
	}

	_toDate(date?: string) {
		if (!date) return undefined

		return new Date(date)
	}

	_toString(date?: Date) {
		if (!date) return undefined

		return toISO8601(date)
	}
}

export type DatepickerOptions = {
	min?: Date
	max?: Date
	minuteValues?: number | number[] | string
}
