import { Component, NgZone } from '@angular/core'
import { FormControl } from '@angular/forms'
import { OnboardingService } from '../../../../services/onboarding'
import { ViewDidEnter } from '@ionic/angular'
import { AnalyticsService } from 'app/core/services'
import { TranslateService } from '@ngx-translate/core'
import { DEFAULT_DATE } from 'app/contants'

@Component({
	selector: 'hum-birthday-time',
	templateUrl: './birthday-time.component.html',
	styleUrls: ['./birthday-time.component.scss'],
})
export class BirthdayTimeComponent implements ViewDidEnter {
	constructor(
		private analytics: AnalyticsService,
		private onboardingService: OnboardingService,
		private zone: NgZone,
	) {}

	birthday_date = new FormControl(this.onboardingService.profile.birthdate || DEFAULT_DATE)

	ionViewDidEnter(): void {
		this.analytics.logOnboardingEvent('onb_time')
	}

	navigateNext() {
		this.zone.run(async () => {
			await this.onboardingService.updateProfile({ birthdate: this.birthday_date.value })
			await this.onboardingService.navigateNext()
		})
	}

	get defaultHref() {
		return this.onboardingService.getPrevRoute()
	}
}
