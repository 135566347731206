<div class="wrapper" (click)="stopPropagation($event)">
	<!-- TODO move to component -->
	<div class="modal-header">
		<div class="modal-title">{{ 'sharing.modal.title' | translate }}</div>
		<div class="modal-close" (click)="dismiss()">
			<hum-icon icon="close" size="16"></hum-icon>
		</div>
	</div>

	<div class="content">
		<div
			class="target"
			*ngFor="let target of targets"
			(click)="shareTo(target)"
			[ngSwitch]="shareLoadingTarget === target"
		>
			<hum-spinner *ngSwitchCase="true" size="small" class="icon"></hum-spinner>
			<hum-icon *ngSwitchDefault [icon]="targetsMap[target].icon" class="icon" size="24"></hum-icon>

			<div class="target-title">{{ 'sharing.modal.targets.' + target | translate }}</div>
		</div>
	</div>
</div>
