import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core'

@Component({
	selector: 'hum-bodygraph-gate',
	templateUrl: './bodygraph-gate.component.html',
	styleUrls: ['./bodygraph-gate.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodygraphGateComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}

	@Input() gate?: number
	@Input() line?: number
}
