export const DEFAULT_STATIC_URL = 'https://d166u4pbhen2b0.cloudfront.net'

export const requiredRegistrationScreens = [
	'name',
	'birthday-date',
	'birthday-time',
	'birthday-place',
]

const IOS_DEFAULT_INTRO_SCREENS = ['welcome', 'what-is-hd', 'hd-uniqueness', 'level', 'goal']
const WEB_DEFAULT_INTRO_SCREENS = ['welcome']

export const IOS_DEFAULT_REGISTRATION_SCREENS = [
	'name',
	'birthday-date',
	'birthday-time',
	'birthday-place',
	'confirm',
]
export const WEB_DEFAULT_REGISTRATION_SCREENS = []

export const IOS_DEFAULT_FINISH_SCREENS = [
	'summary-new',
	'notification',
	'tracking',
	'tutorial-1',
	'tutorial-2',
	'tutorial-3',
]

export function getDefaultIntroScreens(onboardingGroup: 'ios' | 'web') {
	if (onboardingGroup === 'ios') {
		return IOS_DEFAULT_INTRO_SCREENS
	}

	return WEB_DEFAULT_INTRO_SCREENS
}

export function getDefaultRegistrationScreens(onboardingGroup: 'ios' | 'web') {
	if (onboardingGroup === 'ios') {
		return IOS_DEFAULT_REGISTRATION_SCREENS
	}

	return WEB_DEFAULT_REGISTRATION_SCREENS
}

export function getDefaultFinishScreens(onboardingGroup: 'ios' | 'web') {
	if (onboardingGroup === 'ios') {
		return IOS_DEFAULT_FINISH_SCREENS
	}

	return []
}
