import { map, tap } from 'rxjs/operators'
import { ApiService } from './../api/api.service'
import { Injectable } from '@angular/core'
import { IPaginated, Article, DetailedArticle } from 'app/core/models'
import { BehaviorSubject } from 'rxjs'
import { Collection } from 'app/core/models/Collection'
import { Tag } from 'app/core/models/Tag'

@Injectable({
	providedIn: 'root',
})
export class ArticlesService {
	constructor(private api: ApiService) {}

	favorites = new BehaviorSubject<Article[]>([])

	getArticles(page: number = 1, search?: string) {
		return this.api.get<IPaginated<Article>>(['articles'], { page, search })
	}

	getMyCollections(offset: number) {
		return this.api.get<IPaginated<Collection>>(['collections'], { type: 'personal', offset })
	}

	getCollection(id: number) {
		return this.api.get<Collection>(['collections', `${id}`])
	}

	getFavoriteArticles(page: number = 1) {
		return this.api.get<IPaginated<Article>>(['favorite_articles'], { page }).pipe(
			tap(articles => {
				if (Array.isArray(articles))
					this.favorites.next([...this.favorites.getValue(), ...articles])
			}),
		)
	}

	getArticlesByTag(page: number = 1, tag: number) {
		return this.api.get<IPaginated<Article>>(['articles'], { page, tag })
	}
	getArticlesByCollection(page: number = 1, collection?: number) {
		return this.api.get<IPaginated<Article>>(['articles'], { page, collection })
	}

	getArticle(id: string | number) {
		return this.api.get<DetailedArticle>(['articles', `${id}`])
	}

	getTags() {
		return this.api.get<IPaginated<Tag>>(['articles', 'tags'])
	}
	getTag(id: number) {
		return this.api.get<Tag>(['articles', 'tags', id.toString()])
	}

	like(id: number) {
		return this.api.post(['articles', 'vote'], {
			action: 'up',
			id,
		})
	}

	read(id: number) {
		return this.api.post(['articles', id.toString(), 'read'])
	}

	favorite(id: number, status: boolean) {
		if (!status) {
			const favorites = this.favorites.value.filter(s => s.id !== id)
			this.favorites.next(favorites)
		}
		return this.api.post<Article>(['articles', id.toString(), 'favorite'], { status })
	}
}
