<svg [humPitchToZoom2] [attr.viewBox]="viewBox" class="svg">
	<defs>
		<linearGradient [id]="getGradientId('black-red')">
			<stop offset="50%" class="stop-black" />
			<stop offset="50%" class="stop-red" />
		</linearGradient>

		<linearGradient [id]="getGradientId('black')">
			<stop class="stop-black" />
		</linearGradient>

		<linearGradient [id]="getGradientId('red')">
			<stop class="stop-red" />
		</linearGradient>

		<linearGradient [id]="getGradientId('none')">
			<stop class="stop-none" />
		</linearGradient>
	</defs>
	<g #scalable>
		<g name="bodygraph">
			<g name="lines">
				<g *ngFor="let line of lines">
					<line
						stroke="white"
						stroke-width="8"
						[attr.x1]="line.p1.x + 0.001"
						[attr.y1]="line.p1.y + 0.001"
						[attr.x2]="line.p1.x"
						[attr.y2]="line.p1.y + line.len"
						[attr.transform]="getTransform(line.angle, line.p1.x, line.p1.y)"
					></line>
					<line
						[attr.stroke]="getGradientUrl(getGradient(line.p1.red, line.p1.black))"
						stroke-width="6"
						[attr.x1]="line.p1.x + 0.001"
						[attr.y1]="line.p1.y + 0.001"
						[attr.x2]="line.p1.x"
						[attr.y2]="line.p1.y + line.len / 2 + 0.1"
						[attr.transform]="getTransform(line.angle, line.p1.x, line.p1.y)"
					></line>
					<line
						[attr.stroke]="getGradientUrl(getGradient(line.p2.red, line.p2.black))"
						stroke-width="6"
						[attr.x1]="line.mx + 0.001"
						[attr.y1]="line.my + 0.001"
						[attr.x2]="line.mx"
						[attr.y2]="line.my + line.len / 2"
						[attr.transform]="getTransform(line.angle, line.mx, line.my)"
					></line>
				</g>
			</g>
			<g name="centers">
				<path
					*ngFor="let center of centers"
					[attr.d]="center.d"
					[attr.fill]="getCenterFill(center)"
				></path>
			</g>
			<g name="points">
				<g *ngFor="let point of points">
					<circle
						[attr.cx]="point.x"
						[attr.cy]="point.y"
						[attr.r]="point.R"
						[attr.stroke]="point.stroke"
						[attr.fill]="getGradientUrl(point.fillGradient)"
						[attr.stroke-width]="point.strokeWidth"
					/>
					<text
						[attr.x]="point.x"
						[attr.y]="point.y"
						[attr.fill]="point.textColor"
						text-anchor="middle"
						alignment-baseline="middle"
						class="point-text"
					>
						{{ point.id }}
					</text>
				</g>
			</g>
		</g>

		<g *ngIf="!!getGroupsBlocks().length" name="details">
			<g *ngFor="let detail of getGroupsBlocks()" class="svg-detail position-{{detail.position}}">
				<svg
					width="72"
					height="48"
					viewBox="0 0 72 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g name="color" class="svg-detail-type-color">
						<path
							d="M18.364 29.636C21.8787 33.1508 21.8787 38.8492 18.364 42.3639C14.8493 45.8787 9.1508 45.8787 5.6361 42.3639C2.12138 38.8492 2.12138 33.1507 5.6361 29.636C9.15082 26.1213 14.8493 26.1213 18.364 29.636"
							class="svg-detail-stroke"
						/>
						<text x="12" y="37" class="svg-detail-text">{{detail.color}}</text>
					</g>
					<g name="tone" class="svg-detail-type-tone">
						<path
							d="M44.25 39.7051V32.2951C44.25 31.6401 43.901 31.0351 43.333 30.7071L36.916 27.0021C36.349 26.6751 35.65 26.6751 35.083 27.0021L28.667 30.7081C28.099 31.0351 27.75 31.6401 27.75 32.2951V39.7041C27.75 40.3591 28.099 40.9641 28.667 41.2921L35.084 44.9971C35.651 45.3241 36.35 45.3241 36.917 44.9971L43.334 41.2921C43.901 40.9651 44.25 40.3601 44.25 39.7051Z"
							class="svg-detail-stroke"
						/>
						<text x="36" y="37" class="svg-detail-text">{{detail.tone}}</text>
					</g>
					<g name="base" class="svg-detail-type-base">
						<path
							d="M62.5788 26.9923L68.7276 37.533C68.906 37.8388 69 38.1866 69 38.5407V42C69 43.1046 68.1046 44 67 44H53C51.8954 44 51 43.1046 51 42V38.5407C51 38.1866 51.094 37.8388 51.2724 37.533L57.4212 26.9923C57.7796 26.3778 58.4374 26 59.1487 26H60.8513C61.5626 26 62.2204 26.3778 62.5788 26.9923Z"
							class="svg-detail-stroke"
						/>
						<text x="60" y="37" class="svg-detail-text">{{detail.base}}</text>
					</g>
					<g
						name="arrow"
						transform-origin="36 12"
						class="svg-detail-arrow svg-detail-arrow-{{detail.arrow}}"
					>
						<path
							d="M28.01 11.98H43"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M34.013 5.98779L28.002 11.9998L34.013 18.0118"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</g>
				</svg>
			</g>
		</g>
	</g>
</svg>
