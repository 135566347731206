interface IdLike {
	id: string | number | null
}

export function trackById<T extends IdLike>(_: number, item: T) {
	return item.id
}

export function trackByIndex(index: number) {
	return index
}
