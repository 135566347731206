import { animate, state, style, transition, trigger } from '@angular/animations'
import { easeOutCubic } from 'app/theme/animations'

export const timing = easeOutCubic

const rightPosition = style({ width: 0 })
const defaultPosition = style({ width: '*' })
const leftPosition = style({ width: 0 })

export const animations = {
	details: trigger('detailsHide', [
		state('hide', style({ width: 0 })),

		transition('hide => right', [rightPosition, animate(timing, defaultPosition)]),
		transition('right => hide', [defaultPosition, animate(timing, rightPosition)]),

		transition('hide => left', [leftPosition, animate(timing, defaultPosition)]),
		transition('left => hide', [defaultPosition, animate(timing, leftPosition)]),

		transition('void => right', [rightPosition, animate(timing, defaultPosition)]),
		transition('right => void', [defaultPosition, animate(timing, rightPosition)]),

		transition('void => left', [leftPosition, animate(timing, defaultPosition)]),
		transition('left => void', [defaultPosition, animate(timing, leftPosition)]),
	]),
}
