import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ThemeModule } from 'app/theme/theme.module'
import {
	BodygraphDetailsComponent,
	BodygraphViewComponent,
	BodygraphGateComponent,
	BodygraphRootComponent,
} from './components'

const COMPONENTS = [BodygraphViewComponent, BodygraphDetailsComponent]

const DIRECTIVES = []

@NgModule({
	declarations: [...COMPONENTS, ...DIRECTIVES, BodygraphGateComponent, BodygraphRootComponent],
	imports: [CommonModule, ThemeModule],
	exports: [...COMPONENTS, ...DIRECTIVES],
})
export class BodygraphModule {}
