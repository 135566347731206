<hum-top-gradient></hum-top-gradient>
<ion-content [fullscreen]="true">
	<div class="page hum-content">
		<div class="top">
			<div class="image-container">
				<img class="image" [src]="screen?.image" />
			</div>
			<div class="content">
				<div class="title">{{ screen?.title }}</div>
				<div class="text">{{screen?.text}}</div>
			</div>
		</div>
	</div>
	<div class="footer">
		<hum-button class="button hum-content" (click)="navigateNext()">
			{{ screen?.button_text }}
		</hum-button>
	</div>
	<hum-bottom-gradient></hum-bottom-gradient>
</ion-content>
