import { Component } from '@angular/core'

@Component({
	selector: 'hum-short-card-skeleton',
	template: `
		<div class="header">
			<div class="skeleton-card-image" skeleton="dark" width="32px" height="32px"></div>
			<div style="width: 100%">
				<div skeleton="light" width="40%" height="20px"></div>
			</div>
		</div>
		<div class="content" skeleton="dark" height="56px"></div>
	`,
	styleUrls: ['./short-card.component.scss'],
})
export class ShortCardSkeletonComponent {}
