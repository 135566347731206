<hum-top-gradient [progress]="false"></hum-top-gradient>
<ion-content [fullscreen]="true">
	<div class="page hum-content">
		<div class="top">
			<div class="name">{{ 'onboarding.confirm.name' | translate: profile }}</div>
			<div class="birthday-date">
				{{ 'onboarding.confirm.date' | translate }} {{ profile?.birthdate | dateFormat: date_format
				}}
			</div>
			<div class="birthday-place">{{ profile?.birthday_place?.name }}</div>
		</div>
	</div>
	<div class="footer-wrapper">
		<div class="footer hum-content">
			<hum-button class="button" color="white" (click)="onError()">
				{{ 'onboarding.confirm.error_button' | translate }}
			</hum-button>
			<hum-button class="button" (click)="save()">
				{{ 'onboarding.confirm.ok_button' | translate }}
			</hum-button>
		</div>
	</div>
</ion-content>

<div *ngIf="loading" class="spinner-wrapper hum-spinner-wrapper hum-spinner-wrapper-fullscreen">
	<hum-loading class="spinner" size="big"></hum-loading>
	<div class="spinner-text">{{ 'onboarding.finish_text' | translate }}</div>
</div>
