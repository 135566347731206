import { Routes } from '@angular/router'
import {
	BirthdayDateComponent,
	BirthdayPlaceComponent,
	BirthdayTimeComponent,
	ConfirmComponent,
	NameComponent,
} from './pages'

export const RegistrationRoutes: Routes = [
	{
		path: 'name',
		data: { screen_name: 'name' },
		component: NameComponent,
	},
	{
		path: 'birthday-date',
		data: { screen_name: 'birthday-date' },
		component: BirthdayDateComponent,
	},
	{
		path: 'birthday-time',
		data: { screen_name: 'birthday-time' },
		component: BirthdayTimeComponent,
	},
	{
		path: 'birthday-place',
		data: { screen_name: 'birthday-place' },
		component: BirthdayPlaceComponent,
	},
	{
		path: 'confirm',
		data: { screen_name: 'confirm', progress_exclude: true },
		component: ConfirmComponent,
	},
]
