import { ConfigService } from 'app/core/services'
import { SentryErrorHandler } from '@sentry/angular'
import { HttpErrorResponse } from '@angular/common/http'

export class GlobalErrorHandler extends SentryErrorHandler {
	constructor(private config: ConfigService) {
		super()
	}

	handleError(error) {
		if (error instanceof HttpErrorResponse && error.status === 0) {
			return
		}
		if (this.config?.production) {
			super.handleError(error)
		} else {
			console.error(error)
		}
	}
}
