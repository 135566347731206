import { Collection } from 'app/core/models/Collection'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { trackById } from 'app/core/utils'
import { Article } from 'app/core/models'

@Component({
	selector: 'hum-collection-card',
	templateUrl: './collection-card.component.html',
	styleUrls: ['./collection-card.component.scss'],
})
export class CollectionCardComponent {
	@Input() collection: Collection

	@Output() articleClick = new EventEmitter<Article>()
	@Output() moreClick = new EventEmitter<Collection>()

	constructor() {}

	get hasMore() {
		return this.collection.articles_count > this.collection.articles.length
	}

	trackById = trackById
}
