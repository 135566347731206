import { IconType } from './../icon/icon.component'
import { Component, HostBinding, Input, OnInit } from '@angular/core'

@Component({
	selector: 'hum-selector-item',
	templateUrl: './selector-item.component.html',
	styleUrls: ['./selector-item.component.scss'],
})
export class SelectorItemComponent implements OnInit {
	@Input() text: string
	@Input() checked = false
	constructor() {}

	ngOnInit(): void {}

	get icon(): IconType {
		return this.checked ? 'checkbox_active' : 'checkbox'
	}

	@HostBinding('class')
	get hostClass() {
		return `${this.checked && 'checked'}`
	}
}
