<div class="wrapper" (click)="stopPropagation($event)">
	<div class="toolbar-wrapper">
		<div class="toolbar hum-content">
			<div class="toolbar-button">
				<button class="button" (click)="cancel()">{{ 'datepicker.cancel' | translate }}</button>
			</div>
			<div class="toolbar-button">
				<button class="button" (click)="done()">{{ 'datepicker.done' | translate }}</button>
			</div>
		</div>
	</div>

	<!-- <hum-datepicker [pickerFormat]="format" class="picker"></hum-datepicker> -->
	<ion-datetime
		[formControl]="date"
		[presentation]="presentation"
		[preferWheel]="true"
		class="picker"
		size="cover"
		[min]="_toString(options?.min)"
		[max]="_toString(options?.max)"
		[minuteValues]="options?.minuteValues || undefined"
	></ion-datetime>
</div>
