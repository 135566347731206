import { Profile } from 'app/core/models'
import { Adapty } from 'capacitor-adapty'
import { FactoryProvider, Injectable } from '@angular/core'
import { FirebaseAnalytics } from 'capacitor-plugin-firebase-analytics'
// import { Facebook } from '@ionic-native/facebook/ngx'
import { ConfigService } from './config.service'
import { Appsflyer } from 'capacitor-appsflyer'
import { NavigationEnd, Router, RouterEvent } from '@angular/router'
import { filter } from 'rxjs/operators'
import { Amplitude } from 'capacitor-amplitude'
import { OneSignal } from 'capacitor-onesignal'
import { isNative, isWeb } from '../utils'
import { Identify } from 'capacitor-amplitude/dist/esm/identify'
import { environment } from '@env'

export declare type ValidIdentifyType =
	| number
	| string
	| boolean
	| Array<string | number>
	| {
			[key: string]: ValidIdentifyType
	  }

export abstract class AnalyticsService {
	abstract initialise(): Promise<void>
	abstract trackScreen(url: string): void
	abstract updateCustomerId(customerId: string): Promise<void>
	abstract setProfile(profile: Profile): Promise<void>
	abstract conversion(price: number, currency: string, is_trial: boolean): Promise<void>
	abstract logEvent(name: string, params?: object): void
	abstract logOnboardingEvent(name: string, params?: Record<string, unknown>): void

	abstract updateUserProperties(properties: Record<string, ValidIdentifyType>): void
}

@Injectable({
	providedIn: 'root',
})
export class AnalyticsServiceNative implements AnalyticsService {
	constructor(
		private firebaseAnalytics: FirebaseAnalytics,
		// private fb: Facebook,
		private adapty: Adapty,
		private config: ConfigService,
		private appsflyer: Appsflyer,
		private router: Router,
		private amplitude: Amplitude,
		private oneSignal: OneSignal,
	) {}

	async initialise() {
		console.debug('[analyticsService] — initialise')
		if (!this.config.analyticsEnabled) return

		let amplitudeDeviceId

		try {
			await this.amplitude.init(this.config.amplitudeApiKey)
			amplitudeDeviceId = await this.amplitude.getDeviceId()
		} catch (error) {
			console.warn('[amplitude init] error:', error)
		}

		this.appsflyer.addListener('onConversionDataSuccess', async installData => {
			let networkUserId = null
			try {
				networkUserId = await this.appsflyer.getAppsFlyerUID()
			} catch (error) {
				console.warn('[appsflyer getAppsFlyerUID] error:', error)
			}

			if (!networkUserId) return

			try {
				this.adapty.updateAttribution(installData, 'AppsFlyer', networkUserId)
			} catch (error) {
				console.warn('[adapty updateAttribution] error:', error)
			}
		})

		// this.fb.activateApp()
		this.firebaseAnalytics
			.setUserProperty('environment', this.config.name)
			.catch((error: any) => console.warn('[firebaseAnalytics setUserProperty] error:', error))

		this.adapty
			.updateProfile({
				amplitudeDeviceId,
				customAttributes: {
					environment: this.config.name,
				},
			})
			.catch((error: any) => console.warn('[adapty updateProfile] error:', error))

		this.router.events
			.pipe(filter((e: RouterEvent) => e instanceof NavigationEnd))
			.subscribe((e: RouterEvent) => {
				this.trackScreen(e.url)
			})
	}

	logOnboardingEvent(name: string, params?: Record<string, unknown>) {
		this.logEvent(name, params)
	}

	logEvent(name: string, params?: Record<string, unknown>) {
		console.debug('[analyticsService] — logEvent', name, JSON.stringify(params))

		if (!this.config.analyticsEnabled) return

		this.firebaseAnalytics
			.logEvent(name, params)
			.catch((error: any) => console.warn('[firebaseAnalytics logEvent] error:', error))

		this.appsflyer
			.logEvent(name, params)
			.catch((error: any) => console.warn('[appsflyer logEvent] error:', error))

		this.amplitude
			.logEvent(name, params)
			.catch((error: any) => console.warn('[amplitude logEvent] error:', error))

		// this.fb.logEvent(name, params).catch((err) => console.error(err))

		if (name == 'event_paywall_open' && isNative) {
			if (!params || !params.hasOwnProperty('variationId') || !params.variationId) {
				console.error('[analyticsService] — logEvent: missing variationId param')
			}
			this.adapty
				.logShowPaywall(params.variationId as string)
				.catch((error: any) => console.warn('[adapty logShowPaywall] error:', error))
		}
	}

	async conversion(price: number, currency: string) {}

	async setProfile(profile: Profile) {
		console.debug('[analyticsService] — setProfile', JSON.stringify(profile))

		if (!this.config.analyticsEnabled) return

		this.firebaseAnalytics
			.setUserProperty('first_name', profile?.first_name)
			.catch((error: any) => console.warn('[firebaseAnalytics setUserProperty] error:', error))

		this.adapty
			.updateProfile({
				firstName: profile?.first_name,
				birthday: profile?.birthdate || undefined,
			})
			.catch((error: any) => console.warn('[adapty updateProfile] error:', error))
	}

	async updateUserProperties(properties: Record<string, unknown>) {
		console.debug('[analyticsService] — updateUserProperties', JSON.stringify(properties))

		if (!this.config.analyticsEnabled) return
		try {
			const identify = new Identify()
			Object.keys(properties).forEach(key => {
				identify.set(key, properties[key])
			})
			this.amplitude.identify(identify)
		} catch (error) {
			console.warn('[amplitude identify] error:', error)
		}
	}

	async updateCustomerId(customerId: string) {
		console.debug('[analyticsService] — updateCustomerId', customerId)

		if (!this.config.analyticsEnabled) return

		this.firebaseAnalytics
			.setUserId(customerId)
			.catch((error: any) => console.warn('[firebaseAnalytics setUserId] error:', error))

		this.adapty
			.identify(customerId)
			.catch((error: any) => console.warn('[adapty identify] error:', error))

		this.amplitude
			.setUserId(customerId)
			.catch((error: any) => console.warn('[amplitude setUserId] error:', error))

		this.oneSignal
			.setExternalUserId(customerId)
			.catch((error: any) => console.warn('[oneSignal setExternalUserId] error:', error))
	}

	trackScreen(screen: string) {
		console.debug('[analyticsService] — setScreen', screen)

		if (!this.config.analyticsEnabled) return

		this.firebaseAnalytics
			.setScreen(screen)
			.catch((error: any) => console.warn('[firebaseAnalytics setScreen] error:', error))
	}
}

@Injectable({
	providedIn: 'root',
})
export class AnalyticsServiceWeb implements AnalyticsService {
	private
	constructor(private config: ConfigService, private router: Router) {}

	private async getAmplitude() {
		const {
			track,
			init,
			setGroup,
			setUserId,
			identify,
			Identify: IdentifyWeb,
		} = await import('@amplitude/analytics-browser')
		return { track, init, setGroup, setUserId, identify, IdentifyWeb }
	}

	async initialise() {
		console.debug('[analyticsService] — initialise')
		if (!this.config.analyticsEnabled) return

		try {
			const amplitude = await this.getAmplitude()
			await amplitude.init(this.config.amplitudeApiKey, undefined, {
				appVersion: environment.appVersion,
			}).promise

			amplitude.setGroup('origin', window.location.hostname).promise

			amplitude.setGroup('subscription_group', this.config.webSubscriptionGroup || 'default')
				.promise
			console.debug(`[analyticsService] — setGroup subscription_group: ${this.config.webSubscriptionGroup || 'default'}`)
			amplitude.setGroup('web_onboarding_group', this.config.webOnboardingGroup || 'default')
				.promise
			console.debug(`[analyticsService] — setGroup web_onboarding_group: ${this.config.webOnboardingGroup || 'default'}`)
		} catch (error) {
			console.warn(`[analyticsService] — initialise error.`, error)
		}

		this.router.events
			.pipe(filter((e: RouterEvent) => e instanceof NavigationEnd))
			.subscribe((e: RouterEvent) => {
				this.trackScreen(e.url)
			})
	}

	trackScreen(url: string): void {
		console.debug('[analyticsService] — trackScreen', url)
	}

	async updateCustomerId(customerId: string): Promise<void> {
		console.debug('[analyticsService] — updateCustomerId', customerId)
		if (!this.config.analyticsEnabled) return

		try {
			const amplitude = await this.getAmplitude()
			await amplitude.setUserId(customerId)
		} catch (error) {
			console.warn(`[analyticsService] — updateCustomerId error.`, error)
		}

		try {
			this._pushToDataLayer({
				event: 'setUserId',
				userId: customerId,
			})
		} catch (err) {
			console.warn(`[analyticsService] — updateCustomerId error.`, err)
		}
	}

	async setProfile(profile: Profile): Promise<void> {
		console.debug('[analyticsService] — setProfile', profile)
	}

	async conversion(price: number, currency: string, is_trial: boolean): Promise<void> {
		console.debug('[analyticsService] — conversion', price, currency)
		if (!this.config.analyticsEnabled) return

		console.debug(`[analyticsService] — conversion. (price=${price}, currency=${currency})`)

		try {
			this._pushToDataLayer({
				event: 'conversion',
				conversion_price: price,
				conversion_currency: currency,
				conversion_type: is_trial ? 'trial' : 'subscribe',
			})
		} catch (error) {
			console.warn('[analyticsService] — conversion error.', error)
		}
	}

	async updateUserProperties(properties: Record<string, ValidIdentifyType>) {
		console.debug('[analyticsService] — updateUserProperties', properties)
		if (!this.config.analyticsEnabled) return

		try {
			const amplitude = await this.getAmplitude()
			const identify = new amplitude.IdentifyWeb()
			Object.keys(properties).forEach(key => {
				identify.set(key, properties[key])
			})
			amplitude.identify(identify)
		} catch (error) {
			console.warn('[analyticsService] — updateUserProperties error.', error)
		}
	}

	async logOnboardingEvent(name: string, params?: object) {
		this.logEvent(name, params)

		try {
			this._pushToDataLayer({
				event: 'onboarding_event',
				event_name: name,
				...params,
			})
		} catch (error) {
			console.warn('[analyticsService] — conversion error.', error)
		}
	}

	async logEvent(name: string, params?: object) {
		console.debug('[analyticsService] — logEvent', name, params)
		if (!this.config.analyticsEnabled) return

		try {
			const amplitude = await this.getAmplitude()
			await amplitude.track(name, params).promise
		} catch (error) {
			console.warn(`[analyticsService] — logEvent error.`, error)
		}
	}

	private _pushToDataLayer(params: Object) {
		if (window.dataLayer) {
			window.dataLayer.push(params)
		}
	}
}

export const AnalyticsFactoryProvider: FactoryProvider = {
	provide: AnalyticsService,
	deps: [AnalyticsServiceNative, AnalyticsServiceWeb],
	useFactory: (native: AnalyticsServiceNative, web: AnalyticsServiceWeb) => (isWeb ? web : native),
}
