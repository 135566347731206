import { APIErrorResponse } from 'app/core/interfaces'

export function isApiError(x: unknown): x is APIErrorResponse {
	return (
		x &&
		typeof x === 'object' &&
		'status' in x &&
		'error' in x &&
		(x as APIErrorResponse).error &&
		typeof (x as APIErrorResponse).error === 'object' &&
		'error_message' in (x as APIErrorResponse).error
	)
}
