import { IConfig } from './index'

export const config: IConfig = {
	production: true,
	apiUrl: 'https://api.humanify.app/api',
	apiVersion: 2,
	sentry: {
		dsn: 'https://0a5b059e770b491eaac268b940b0e8c7@o928588.ingest.sentry.io/5879492',
	},
	isTesting: false,
	oauth2: {
		vk: {
			appId: 7779058,
			version: '5.130',
			androidPackage: 'com.vkontakte.android',
		},
	},
	appMetricaApiKey: 'a49af289-89fa-4408-aa76-706c61d5667b',
	analyticsEnabled: true,
	adapty: {
		apiKey: 'public_live_1xoXoMjV.1KJzffSJzcThudZcPoSJ',
		paywallId: 'Paywall_1_NT_global',
	},
	deeplinkSchema: 'humanify',
	amplitudeApiKey: 'fadefe4cb86b2c54f0cbfc4958f73162',
	oneSignalAppId: '555f42b8-f710-4fa1-b7dc-27c48d57e8d2',
	facebookAppId: '117544400308861',
	sharingUrl: 'https://open.humanify.app/orLg/664uqlo3',
}
