export interface CapacitorError {
	message: string
	code: string
	errorMessage: string
}

export function isCapacitorError(error: unknown): error is CapacitorError {
	return (
		typeof error === 'object' &&
		'code' in (error as CapacitorError) &&
		'message' in (error as CapacitorError)
	)
}
