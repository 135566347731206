import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class CanvasRenderService {
	constructor() {}

	async elementToDataUrl(element: HTMLElement, requiredFonts: string[]): Promise<string> {
		const html2canvas = await import('html2canvas').then(m => m.default)

		const canvas = await html2canvas(element, {
			onclone: async (clonedDoc, ref) => {
				const clonedRoot = clonedDoc.getElementById(ref.id)

				clonedRoot.style.position = undefined
				clonedRoot.style.left = '0'

				await this.loadFonts(clonedDoc, requiredFonts)
			},
			useCORS: true,
			scale: 2,
		})

		return canvas.toDataURL('image/png')
	}

	private async loadFonts(doc: Document, requiredFonts: string[]) {
		let unloadedFonts = this._getUnloadedFonts(doc, requiredFonts)

		let tries = 1

		while (unloadedFonts.length && tries <= 3) {
			console.warn(`unloaded required fonts:`, unloadedFonts, `try load it (try ${tries})`)

			await this._loadFonts(doc, unloadedFonts)

			unloadedFonts = this._getUnloadedFonts(doc, requiredFonts)
		}

		if (!unloadedFonts.length) {
			console.debug(`all required fonts loaded`)
		} else {
			console.warn(`Still unloaded required fonts:`, unloadedFonts)
		}
	}

	private _getUnloadedFonts(doc: Document, requiredFonts: string[]) {
		return Array.from(doc.fonts).filter(fontFace => {
			return requiredFonts.includes(fontFace.family) && fontFace.status !== 'loaded'
		})
	}

	private async _loadFonts(doc: Document, fonts: FontFace[]) {
		return Promise.all(
			fonts.map(fontFace => doc.fonts.load(`${fontFace.weight} 16px ${fontFace.family}`)),
		)
	}
}
