import { Component, ContentChild, Input, OnInit } from '@angular/core'
import { CompositeLabel } from 'app/modules/home/services'
import { LabelComponent } from '../label/label.component'

@Component({
	selector: 'hum-short-card',
	templateUrl: './short-card.component.html',
	styleUrls: ['./short-card.component.scss'],
})
export class ShortCardComponent implements OnInit {
	@Input() icon: string // TODO type
	@Input() title: string
	@Input() badge: boolean = false
	@ContentChild(LabelComponent) label: LabelComponent
	constructor() {}

	ngOnInit(): void {}

	get hasLabel() {
		return !!this.label
	}
}
