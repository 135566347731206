import {
	BatteryInfo,
	DeviceId,
	DeviceInfo,
	GetLanguageCodeResult,
	DevicePlugin,
} from '@capacitor/device'

export abstract class Device implements DevicePlugin {
	/**
	 * Return information about the underlying device/os/platform
	 */
	abstract getInfo(): Promise<DeviceInfo>
	/**
	 * Return information about the battery
	 */
	abstract getBatteryInfo(): Promise<BatteryInfo>
	/**
	 * Get the device's current language locale code
	 */
	abstract getLanguageCode(): Promise<GetLanguageCodeResult>
	/**
	 * Return an unique identifier for the device.
	 *
	 * @since 1.0.0
	 */
	abstract getId(): Promise<DeviceId>
}
