import { IonicModule } from '@ionic/angular'
import { OnboardingGuard } from './guards/onboarding.guard'
import { SubscriptionGuard } from './guards/subscription.guard'
import { AuthGuard } from 'app/core/guards/auth.guard'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ApiModule } from './api/api.module'
import { AnalyticsFactoryProvider } from './services'

@NgModule({
	imports: [CommonModule, ApiModule, IonicModule],
	exports: [ApiModule],
	providers: [AuthGuard, SubscriptionGuard, OnboardingGuard, AnalyticsFactoryProvider],
})
export class CoreModule {}
