import { Component, OnInit } from '@angular/core'
import { ViewDidEnter } from '@ionic/angular'
import { AnalyticsService } from 'app/core/services'
import { OnboardingService } from 'app/modules/onboarding/services/onboarding'

@Component({
	templateUrl: './tutorial4.component.html',
	styleUrls: ['./tutorial4.component.scss'],
})
export class Tutorial4Component implements ViewDidEnter {
	constructor(private onboardingService: OnboardingService, private analytics: AnalyticsService) {}

	ionViewDidEnter() {
		this.analytics.logOnboardingEvent('onb_tutor4')
	}

	get videoSrc() {
		return this.onboardingService.getVideo('stories')
	}

	features = [
		'onboarding.tutorial-4.features.0',
		'onboarding.tutorial-4.features.1',
		'onboarding.tutorial-4.features.2',
	]

	async navigateNext() {
		this.onboardingService.navigateNext()
	}
}
