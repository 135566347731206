import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { StorageService } from 'app/core/services'
import { I18N_ALLOWED_LANGS, I18N_DEFAULT_LANG } from 'app/i18n'
import { Device } from 'app/plugins'

@Injectable({
	providedIn: 'root',
})
export class LangService {
	languages = I18N_ALLOWED_LANGS
	default = I18N_DEFAULT_LANG

	constructor(
		private translate: TranslateService,
		private storage: StorageService,
		private device: Device,
	) {}

	async changeLanguage(language: string) {
		if (language === this.translate.currentLang) {
			return
		}

		await this._save(language)
		window.location.reload() // TODO remove this
	}

	async init() {
		let prefferedLang = (await this.device.getLanguageCode()).value.split('-')[0] || this.default

		const savedLang = await this._load()
		if (savedLang) {
			prefferedLang = savedLang
		} else {
			await this._save(prefferedLang)
		}

		if (!this.languages.includes(prefferedLang)) {
			prefferedLang = this.default
		}

		this.translate.setDefaultLang(this.default)
		await this.translate.use(prefferedLang).toPromise()
	}

	private _load() {
		return this.storage.get<string>('language').toPromise()
	}

	private _save(lang: string) {
		return this.storage.set('language', lang).toPromise()
	}
}
