import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ContinueGuard } from './guards'
import { OnboardingComponent } from './onboarding.component'
import { IntroRoutes, FinishRoutes, RegistrationRoutes, OnboardingFinishComponent } from './stages'

export const onboardingRoutes: Routes = [
	{
		path: '',
		data: { root_config: true },
		canActivate: [ContinueGuard],
		canActivateChild: [ContinueGuard],
		component: OnboardingComponent,
		children: [
			{
				path: 'intro',
				data: { stage: 'intro' },
				children: IntroRoutes,
			},
			{
				path: 'registration',
				data: { stage: 'registration' },
				children: RegistrationRoutes,
			},
			{
				path: 'finish',
				data: { stage: 'finish' },
				component: OnboardingFinishComponent,
				children: FinishRoutes,
			},
		],
	},
]

@NgModule({
	imports: [RouterModule.forChild(onboardingRoutes)],
	exports: [RouterModule],
})
export class OnboardingRoutingModule {}
