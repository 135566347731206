import { Injectable } from '@angular/core'
import { CanActivate, CanActivateChild, Router, CanLoad } from '@angular/router'
import { StorageService } from 'app/core/services'
import { OnboardingService } from 'app/modules/onboarding/services/onboarding'
import { map } from 'rxjs/operators'

@Injectable({
	providedIn: 'root',
})
export class OnboardingGuard implements CanActivate, CanActivateChild, CanLoad {
	constructor(private onboardingService: OnboardingService, private router: Router) {}

	canActivate() {
		return this.onboardingService.isCompleted().pipe(
			map(isOnboardingComplete => {
				if (isOnboardingComplete) return true

				return this.router.createUrlTree(['/onboarding'])
			}),
		)
	}
	canActivateChild() {
		return this.canActivate()
	}

	canLoad() {
		return this.canActivate()
	}
}
