<div
	class="wrapper position-{{_getPositionFromTag()}}"
	[style.--fit-scale]="getFitScale()"
	#content
>
	<div class="title" *ngIf="!!title">{{title}}</div>
	<ng-container *ngFor="let planet of planets">
		<div class="detail color-{{planet.name}}">
			<hum-bodygraph-gate
				*ngIf="!!planet.black"
				[gate]="planet.black?.gate"
				[line]="planet.black?.line"
				class="gate gate-black"
			></hum-bodygraph-gate>

			<div class="icon-wrapper">
				<hum-icon size="16" icon="{{planet.name}}_16" class="icon"></hum-icon>
			</div>

			<hum-bodygraph-gate
				*ngIf="!!planet.red"
				[gate]="planet.red?.gate"
				[line]="planet.red?.line"
				class="gate gate-red"
			></hum-bodygraph-gate>
		</div>
	</ng-container>
</div>
