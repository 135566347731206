import { StartupError } from './app/setup.service'
import { environment } from 'environments/environment'

import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { init as InitSentry, captureException } from '@sentry/capacitor'
import { init as InitSentryAngular } from '@sentry/angular'
import { AppModule } from './app/app.module'
import { Capacitor } from '@capacitor/core'
import 'hammerjs'

// TODO remove after upgrading to TS 4.6
declare global {
	type GlobalCompositeOperation = any
}

if (environment.production) {
	enableProdMode()

	InitSentry(
		{
			dsn: 'https://f4670ddb13b04072a218769dcd56f1d7@o928588.ingest.sentry.io/6461465',
			release: `${environment.appName}@${environment.appVersion}`,
			dist: Capacitor.getPlatform(),
		},
		// Forward the init method from @sentry/angular
		InitSentryAngular,
	)
}

// test
platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => {
		if (err instanceof StartupError) return

		console.error(err)
		captureException(err)

		alert(
			"Ehh, an unexpected error has occured while loading the app. \nPlease try again, and send us a screenshot to support@humanify.app if didn't help \nCode: -0",
		)
	})

try {
	;(window as any).login = function (token) {
		localStorage.setItem('token', JSON.stringify(token))
		window.location.assign('/')
	}
} catch (err) {
	console.warn(err)
}
