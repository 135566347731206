<ng-container *ngTemplateOutlet="hasLabel ? labelHeader : defaultHeader"></ng-container>

<div class="content">
	<div class="content-text">
		<ng-content></ng-content>
	</div>
</div>

<ng-template #defaultHeader>
	<div class="header">
		<div class="left">
			<hum-icon *ngIf="icon" [icon]="icon" class="icon" [class.has-badge]="!!badge"></hum-icon>
			<div class="title">{{ title }}</div>
		</div>
		<hum-secondary-button class="button" icon="chevron_left">
			{{ 'short_card.more_button' | translate }}
		</hum-secondary-button>
	</div>
</ng-template>

<ng-template #labelHeader>
	<div class="header">
		<div class="left">
			<ng-content select="hum-label"></ng-content>
		</div>
		<hum-secondary-button class="button" icon="chevron_left">
			{{ 'short_card.more_button' | translate }}
		</hum-secondary-button>
	</div>
	<div class="title">{{ title }}</div>
</ng-template>
