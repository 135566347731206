import { Directive, ElementRef, Input, Renderer2 } from '@angular/core'

@Directive({
	selector: '[humBackgroundImage]',
})
export class BackgroundImageDirective {
	constructor(private renderer: Renderer2, private host: ElementRef<HTMLElement>) {}

	@Input()
	set humBackgroundImage(image: string | undefined | null) {
		if (image) {
			this.renderer.setStyle(this.host.nativeElement, 'backgroundImage', `url('${image}')`)
		} else {
			this.renderer.removeStyle(this.host.nativeElement, 'backgroundImage')
		}
	}
}
