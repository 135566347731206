import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { format, isValid, parse, parseISO } from 'date-fns'
import { ru, enUS, es } from 'date-fns/locale'

@Injectable({
	providedIn: 'root',
})
export class DateFormatService {
	constructor(private translate: TranslateService) {}

	// TODO more locales
	locales = {
		ru: ru,
		en: enUS,
		es: es,
	}

	format(value: string | Date | undefined | null, format_string: string): string {
		if (!value) return ''

		const locale =
			this.locales[this.translate.currentLang] ||
			this.locales[this.translate.defaultLang] ||
			this.locales.en

		let date = typeof value === 'string' ? parseISO(value) : value

		return format(date, format_string, { locale })
	}
}
