import { ViewWillEnter } from '@ionic/angular'
import { OnboardingService } from './services/onboarding'
import { Component, OnDestroy } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter, takeUntil, tap } from 'rxjs/operators'
import { ReplaySubject } from 'rxjs'
import { SpinnerService } from 'app/core/services'

@Component({
	selector: 'hum-onboarding',
	templateUrl: './onboarding.component.html',
	styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements ViewWillEnter, OnDestroy {
	destroyed$: ReplaySubject<boolean> = new ReplaySubject(1)

	constructor(
		private onboardingService: OnboardingService,
		private router: Router,
		private spinner: SpinnerService,
	) {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				tap((event: NavigationEnd) => {
					this.onboardingService.updateCurrentRoute(event.urlAfterRedirects)
				}),
				takeUntil(this.destroyed$),
			)
			.subscribe()
	}

	initialized = this.onboardingService.initialized

	async ionViewWillEnter() {
		if (!this.initialized) {
			this.spinner.show()
			await this.onboardingService.initOnboarding()
			this.initialized = true
			this.spinner.hide()
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true)
		this.destroyed$.complete()
	}
}
