<ion-app>
	<ion-router-outlet></ion-router-outlet>
</ion-app>

<div
	*ngIf="showSpinner | async"
	class="spinner-wrapper hum-spinner-wrapper"
	[class.hum-spinner-wrapper-fullscreen]="fullscreenSpinner"
>
	<hum-spinner class="spinner" size="large"></hum-spinner>
</div>
