import { KeyboardResizeOptions, KeyboardStyleOptions } from '@capacitor/keyboard'
import { Keyboard } from '../keyboard'

function PluginListenerHandleStub() {
	return { remove: () => {} }
}

export class KeyboardWeb extends Keyboard {
	/**
	 * Show the keyboard. This method is alpha and may have issues.
	 *
	 * This method is only supported on Android.
	 *
	 * @since 1.0.0
	 */
	async show(): Promise<void> {}
	/**
	 * Hide the keyboard.
	 *
	 * @since 1.0.0
	 */
	async hide(): Promise<void> {
		const active = document.activeElement as HTMLInputElement
		if (active && 'blur' in active) {
			active.blur()
		}
	}
	/**
	 * Set whether the accessory bar should be visible on the keyboard. We recommend disabling
	 * the accessory bar for short forms (login, signup, etc.) to provide a cleaner UI.
	 *
	 * This method is only supported on iPhone devices.
	 *
	 * @since 1.0.0
	 */
	async setAccessoryBarVisible(options: { isVisible: boolean }): Promise<void> {}
	/**
	 * Programmatically enable or disable the WebView scroll.
	 *
	 * This method is only supported on iOS.
	 *
	 * @since 1.0.0
	 */
	async setScroll(options: { isDisabled: boolean }): Promise<void> {}
	/**
	 * Programmatically set the keyboard style.
	 *
	 * This method is only supported on iOS.
	 *
	 * @since 1.0.0
	 */
	async setStyle(options: KeyboardStyleOptions): Promise<void> {}
	/**
	 * Programmatically set the resize mode.
	 *
	 * This method is only supported on iOS.
	 *
	 * @since 1.0.0
	 */
	async setResizeMode(options: KeyboardResizeOptions): Promise<void> {}

	addListener(eventName: unknown, listenerFunc: unknown) {
		return {} as any
	}
	/**
	 * Remove all native listeners for this plugin.
	 *
	 * @since 1.0.0
	 */
	async removeAllListeners(): Promise<void> {}
}
