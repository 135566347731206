import { ViewDidEnter } from '@ionic/angular'
import { Component, NgZone } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { OnboardingService } from '../../../../services/onboarding'
import { AnalyticsService } from 'app/core/services'
import { requiredWithTrimValidator } from 'app/core/utils'
import { Keyboard } from 'app/plugins'
@Component({
	selector: 'hum-name',
	templateUrl: './name.component.html',
	styleUrls: ['./name.component.scss'],
})
export class NameComponent implements ViewDidEnter {
	constructor(
		private analytics: AnalyticsService,
		private onboardingService: OnboardingService,
		private zone: NgZone,
		private keyboard: Keyboard
	) {}

	name = new FormControl(this.onboardingService.profile.first_name || '', [
		requiredWithTrimValidator,
		Validators.maxLength(150),
	])

	ionViewDidEnter(): void {
		this.analytics.logOnboardingEvent('onb_name')
	}

	navigateNext() {
		if (!this.isFormValid) return

		this.zone.run(() => {
			const first_name = (this.name.value as string).trim()
			this.onboardingService.updateProfile({ first_name })
			this.keyboard.hide()
			this.onboardingService.navigateNext()
		})
	}

	get isFormValid() {
		return this.name.valid
	}

	get defaultHref() {
		return this.onboardingService.getPrevRoute()
	}
}
