import { isPlatformServer } from '@angular/common'
import {
	Component,
	forwardRef,
	Inject,
	InjectionToken,
	Input,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
} from '@angular/core'
import { from, ReplaySubject } from 'rxjs'
import { map, switchMap, take, takeUntil } from 'rxjs/operators'
import type { Engine } from 'tsparticles-engine'

export const PARTICLES_PARENT = new InjectionToken('PARTICLES_PARENT')

@Component({
	selector: 'hum-particles',
	templateUrl: './particles.component.html',
	styleUrls: ['./particles.component.scss'],
	providers: [
		{
			provide: PARTICLES_PARENT,
			useExisting: forwardRef(() => ParticlesComponent),
		},
	],
})
export class ParticlesComponent implements OnDestroy, OnInit {
	private destroy$ = new ReplaySubject(1)

	@Input() particlesInit: (engine: Engine) => Promise<void> = () => Promise.resolve()

	engine = new ReplaySubject<Engine>(1)

	constructor(@Inject(PLATFORM_ID) private platformId: string) {}

	ngOnInit(): void {
		if (isPlatformServer(this.platformId)) {
			return
		}

		from(import('tsparticles-engine'))
			.pipe(
				take(1),
				map(e => e.tsParticles),
				switchMap(tsParticles =>
					from(this.particlesInit(tsParticles)).pipe(map(() => tsParticles)),
				),
				takeUntil(this.destroy$),
			)
			.subscribe(tsParticles => {
				this.engine.next(tsParticles)
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next(true)
		this.destroy$.complete()
		this.engine.complete()
	}
}
