export class AdaptyWeb {
	async presentCodeRedemptionSheet() {}
	async identify(customerUserId: string) {}
	async logout() {}
	async updateProfile(fields: any) {}
	async logShowPaywall(variationId: string) {}
	async getAPNSToken() {
		return null
	}
	async setAPNSToken(token: string) {}
	async getPurchaseInfo(options?: any) {
		return null
	}
	async restorePurchases() {
		return null
	}
	async makePurchase(productId: string, variationId: string | undefined) {
		return null
	}
	async getPromo() {
		return null
	}
	async getPaywalls(options?: any) {
		return null
	}
	async setExternalAnalyticsEnabled(isEnabled: boolean) {}
	async setVariationID(variationId: string, transactionId: string) {}
	async getCustomerUserId() {
		return null
	}
	async updateAttribution(
		attribution: Object,
		source: 'Branch' | 'AppsFlyer' | 'Adjust' | 'Custom' | 'AppleSearchAds',
		networkUserId?: string
	) {}
	addListener: {
		(eventName: 'onPurchaseSuccess', listenerFunc: any): Promise<
			import('@capacitor/core').PluginListenerHandle
		> &
			import('@capacitor/core').PluginListenerHandle
		(eventName: 'onPurchaseFailed', listenerFunc: any): Promise<
			import('@capacitor/core').PluginListenerHandle
		> &
			import('@capacitor/core').PluginListenerHandle
		(eventName: 'onInfoUpdate', listenerFunc: any): Promise<
			import('@capacitor/core').PluginListenerHandle
		> &
			import('@capacitor/core').PluginListenerHandle
		(eventName: 'onPromoReceived', listenerFunc: any): Promise<
			import('@capacitor/core').PluginListenerHandle
		> &
			import('@capacitor/core').PluginListenerHandle
	}
}
