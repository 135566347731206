import { Component, Injector } from '@angular/core'
import { DisableBackService, SpinnerService } from 'app/core/services'

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	constructor(private injector: Injector, private spinnerService: SpinnerService) {
		this.injector.get(DisableBackService)
	}

	showSpinner = this.spinnerService.showSpinner

	get fullscreenSpinner() {
		return this.spinnerService.fullscreen
	}
}
