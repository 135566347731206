import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'hum-sharing-social-tag',
  templateUrl: './sharing-social-tag.component.html',
  styleUrls: ['./sharing-social-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharingSocialTagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
