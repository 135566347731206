import { Directive, HostListener, Input } from '@angular/core'
import { ArticleModalService } from '../services'

@Directive({
	selector: '[humInfo]',
	providers: [ArticleModalService],
})
export class InfoDirective {
	constructor(private articleModal: ArticleModalService) {}

	@Input('humInfo') articleId?: string | number | null

	@HostListener('click')
	onClick() {
		if (!this.articleId) return

		this.articleModal.open(this.articleId)
	}
}
