<hum-top-gradient></hum-top-gradient>
<ion-content [fullscreen]="true">
	<div class="hum-content back-button-wrapper">
		<hum-back-button class="back-button" [defaultHref]="defaultHref"></hum-back-button>
	</div>
	<div class="page hum-content">
		<div class="top">
			<div class="title">{{ 'onboarding.name.title' | translate }}</div>
			<hum-input
				class="field"
				(submit)="navigateNext()"
				enterkeyhint="go"
				[formControl]="name"
				autoFocus
			>
			</hum-input>
		</div>
	</div>
	<div class="footer">
		<hum-button [disabled]="!isFormValid" (click)="navigateNext()" class="hum-content">
			{{ 'onboarding.name.next_button' | translate }}
		</hum-button>
	</div>
	<hum-bottom-gradient></hum-bottom-gradient>
</ion-content>
