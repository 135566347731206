import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'

@Directive({
	selector: '[skeleton]',
})
export class SkeletonDirective implements OnInit {
	constructor(private el: ElementRef<HTMLElement>, private renderer: Renderer2) {}

	ngOnInit(): void {
		this.renderer.addClass(this.el.nativeElement, `skeleton`)
	}

	@Input('skeleton')
	set skeleton(color: 'light' | 'dark') {
		this.renderer.addClass(this.el.nativeElement, `skeleton-color-${color}`)
	}

	@Input('height')
	set height(height: string | number) {
		this.renderer.setStyle(this.el.nativeElement, 'height', height)
	}

	@Input('width')
	set width(width: string | number) {
		this.renderer.setStyle(this.el.nativeElement, 'width', width)
	}

	@Input('bottom')
	set bottom(bottom: string | number) {
		this.renderer.setStyle(this.el.nativeElement, 'margin-bottom', bottom)
	}
	@Input('left')
	set left(left: string | number) {
		this.renderer.setStyle(this.el.nativeElement, 'margin-left', left)
	}
	@Input('right')
	set right(right: string | number) {
		this.renderer.setStyle(this.el.nativeElement, 'margin-right', right)
	}

	@Input('radius')
	set radius(radius: string | number) {
		this.renderer.setStyle(this.el.nativeElement, 'border-radius', radius)
	}
}
