<ion-content [fullscreen]="true">
	<div class="page hum-content">
		<div class="content">
			<div class="image-wrapper">
				<img class="image-blur" src="/assets/images/heart.svg" />
				<img class="image" src="/assets/images/heart.svg" />
			</div>
			<div class="title">
				{{ 'tracking.title' | translate }}
			</div>
			<div class="text">
				{{ 'tracking.text.0' | translate }}
				<br />
				<br />
				{{ 'tracking.text.1' | translate }}
			</div>
		</div>
	</div>
	<div class="footer">
		<hum-button (click)="requestTracking()" class="hum-content">
			{{ 'tracking.next_button' | translate }}
		</hum-button>
	</div>
	<hum-bottom-gradient></hum-bottom-gradient>
</ion-content>
