import { Component } from '@angular/core'
import { ViewWillEnter } from '@ionic/angular'
import { OnboardingService } from '../../services/onboarding'
import { AlertService, SpinnerService } from 'app/core/services'
import * as Sentry from '@sentry/capacitor'

@Component({
	selector: 'hum-onboarding-finish',
	templateUrl: './onboarding-finish.component.html',
	styleUrls: ['./onboarding-finish.component.scss'],
})
export class OnboardingFinishComponent implements ViewWillEnter {
	constructor(
		private onboardingService: OnboardingService,
		private alertService: AlertService,
		private spinner: SpinnerService,
	) {}

	loaded = !this.onboardingService.needFetchIntro()

	async ionViewWillEnter() {
		if (!this.loaded) {
			this.spinner.show()

			try {
				await this.onboardingService.fetchIntro()
				this.loaded = true
			} catch (err) {
				this.alertService.alert(-11, err)
			} finally {
				this.spinner.hide()
			}
		}
	}
}
