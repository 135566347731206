import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@env'

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let version = environment.appVersion

		request = request.clone({
			headers: request.headers.set('X-User-App-Version', version),
		})

		return next.handle(request)
	}
}
