import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BillingFactoryProvider } from './billing-providers'

@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [BillingFactoryProvider],
})
export class BillingModule {}
