import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { isWeb } from 'app/core/utils'

@Directive({
	selector: '[isWeb]',
})
export class IsWebDirective implements OnInit {
	constructor(
		private templateRef: TemplateRef<HTMLElement>,
		private viewContainer: ViewContainerRef
	) {}

	ngOnInit(): void {
		if (isWeb) {
			this.viewContainer.createEmbeddedView(this.templateRef)
		} else {
			this.viewContainer.clear()
		}
	}
}
