export class FirebaseAnalyticsWeb {
	async logEvent(name: string, parameters?: object): Promise<void> {}
	async setEnabled(enabled: boolean): Promise<void> {}
	async setScreen(screenName: string, screenClass?: string): Promise<void> {}
	async setUserProperty(name: string, value: string): Promise<void> {}
	async setUserId(userId: string): Promise<void> {}
	async setDefaultEventParameters(parameters: object): Promise<void> {}
	async appInstanceId() {
		return null
	}
	async resetAnalyticsData(): Promise<void> {}
}
