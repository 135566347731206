export type Center = {
	id: number
	c: string
	p: Array<[number, number]>
	d: string
}

const centers: Array<Center> = [
	{
		id: 1,
		c: '#FFCC80',
		p: [
			[47, 64],
			[24, 61],
			[4, 63],
		],
		d: 'M141.027 12.3957C144.086 6.95853 151.914 6.95853 154.973 12.3957L177.294 52.0779C180.294 57.4107 176.44 64 170.321 64H125.679C119.56 64 115.706 57.4107 118.706 52.0779L141.027 12.3957Z',
	},
	{
		id: 2,
		c: '#DCEDC8',
		p: [
			[47, 64],
			[24, 61],
			[4, 63],
			[17, 62],
			[11, 56],
			[23, 43],
		],
		d: 'M141.027 131.604C144.086 137.041 151.914 137.041 154.973 131.604L177.294 91.9221C180.294 86.5893 176.44 80 170.321 80H125.679C119.56 80 115.706 86.5893 118.706 91.9221L141.027 131.604Z',
	},
	{
		id: 3,
		c: '#B2DFDB',
		p: [
			[17, 62],
			[11, 56],
			[23, 43],
			[7, 31],
			[1, 8],
			[13, 33],
			[21, 45],
			[12, 22],
			[35, 36],
			[16, 48],
			[20, 57],
			[10, 20],
			[20, 34],
		],
		d: 'M116 176C116 171.582 119.582 168 124 168H172C176.418 168 180 171.582 180 176V224C180 228.418 176.418 232 172 232H124C119.582 232 116 228.418 116 224V176Z',
	},
	{
		id: 4,
		c: '#FFCC80',
		p: [
			[7, 31],
			[1, 8],
			[13, 33],
			[25, 51],
			[5, 15],
			[2, 14],
			[29, 46],
			[10, 34],
			[10, 57],
			[10, 20],
		],
		d: 'M142.343 261.657C145.467 258.533 150.533 258.533 153.657 261.657L181.343 289.343C184.467 292.467 184.467 297.533 181.343 300.657L153.657 328.343C150.533 331.467 145.467 331.467 142.343 328.343L114.657 300.657C111.533 297.533 111.533 292.467 114.657 289.343L142.343 261.657Z',
	},
	{
		id: 5,
		c: '#FFAB91',
		p: [
			[25, 51],
			[26, 44],
			[21, 45],
			[37, 40],
		],
		d: 'M204.054 316.607C207.232 313.076 212.768 313.076 215.946 316.607L233.983 336.648C238.617 341.796 234.963 350 228.037 350H191.963C185.037 350 181.383 341.796 186.017 336.648L204.054 316.607Z',
	},
	{
		id: 6,
		c: '#DCEDC8',
		p: [
			[16, 48],
			[26, 44],
			[27, 50],
			[32, 54],
			[28, 38],
			[18, 58],
			[10, 57],
			[34, 57],
			[20, 57],
		],
		d: 'M51.6043 414.973C57.0415 411.914 57.0415 404.086 51.6043 401.027L11.9221 378.706C6.58926 375.706 0 379.56 0 385.679V430.321C0 436.44 6.58926 440.294 11.9221 437.294L51.6043 414.973Z',
	},
	{
		id: 7,
		c: '#FFAB91',
		p: [
			[6, 59],
			[37, 40],
			[12, 22],
			[35, 36],
			[19, 49],
			[39, 55],
			[30, 41],
		],
		d: 'M244.396 414.973C238.959 411.914 238.959 404.086 244.396 401.027L284.078 378.706C289.411 375.706 296 379.56 296 385.679V430.321C296 436.44 289.411 440.294 284.078 437.294L244.396 414.973Z',
	},
	{
		id: 8,
		c: '#FFAB91',
		p: [
			[5, 15],
			[2, 14],
			[29, 46],
			[6, 59],
			[27, 50],
			[42, 53],
			[3, 60],
			[9, 52],
			[34, 57],
			[34, 10],
			[34, 20],
		],
		d: 'M116 406C116 401.582 119.582 398 124 398H172C176.418 398 180 401.582 180 406V454C180 458.418 176.418 462 172 462H124C119.582 462 116 458.418 116 454V406Z',
	},
	{
		id: 9,
		c: '#FFAB91',
		p: [
			[42, 53],
			[3, 60],
			[9, 52],
			[32, 54],
			[28, 38],
			[18, 58],
			[19, 49],
			[39, 55],
			[30, 41],
		],
		d: 'M116 494C116 489.582 119.582 486 124 486H172C176.418 486 180 489.582 180 494V542C180 546.418 176.418 550 172 550H124C119.582 550 116 546.418 116 542V494Z',
	},
]

export default centers
