import { Component, OnInit } from '@angular/core'
import { AnalyticsService } from 'app/core/services'
import { ViewDidEnter } from '@ionic/angular'
import { OnboardingService } from '../../../../services/onboarding'

@Component({
	selector: 'hum-tutorial3',
	templateUrl: './tutorial3.component.html',
	styleUrls: ['./tutorial3.component.scss'],
})
export class Tutorial3Component implements ViewDidEnter {
	constructor(private onboardingService: OnboardingService, private analytics: AnalyticsService) {}

	ionViewDidEnter() {
		this.analytics.logOnboardingEvent('onb_tutor3')
	}

	get videoSrc() {
		return this.onboardingService.getVideo('transits')
	}

	features = [
		'onboarding.tutorial-3.features.0',
		'onboarding.tutorial-3.features.1',
		'onboarding.tutorial-3.features.2',
	]

	async navigateNext() {
		this.onboardingService.navigateNext()
	}
}
