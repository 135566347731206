import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class SpinnerService {
	fullscreen = true
	showSpinner = new BehaviorSubject(false)

	show(fullscreen = true) {
		this.fullscreen = fullscreen
		this.showSpinner.next(true)
	}

	hide() {
		this.showSpinner.next(false)
	}
}
