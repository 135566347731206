import { animate, style, transition, trigger } from '@angular/animations'
import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core'
import { AnalyticsService } from 'app/core/services'

type Stage = 'initial' | 'agree' | 'disagree'

@Component({
	selector: 'hum-feedback-card',
	templateUrl: './feedback-card.component.html',
	styleUrls: ['./feedback-card.component.scss'],
	animations: [
		trigger('grow', [
			transition(':enter', [
				style({ height: 0 }),
				animate('300ms ease-out', style({ height: '*' })),
			]),
			transition(':leave', [animate('300ms ease-out', style({ height: 0 }))]),
		]),
	],
})
export class FeedbackCardComponent implements OnInit {
	stage: Stage = 'initial'

	@Output('feedback') feedbackEvent = new EventEmitter()
	@Output('close') closeEvent = new EventEmitter()
	@Output('rate') rateEvent = new EventEmitter()

	constructor(private analytics: AnalyticsService) {}

	@HostBinding('@grow') get getGrow() {
		return true
	}

	ngOnInit(): void {}

	initial_yes() {
		this.stage = 'agree'
		this.analytics.logEvent('review_engage', {
			title: 'Вам нравится Humanify?',
			reaction: 'yes',
		})
	}
	initial_no() {
		this.stage = 'disagree'
		this.analytics.logEvent('review_engage', {
			title: 'Вам нравится Humanify?',
			reaction: 'no',
		})
	}

	agree_yes() {
		this.rateEvent.emit()
		this.analytics.logEvent('review_engage', {
			title: 'Тогда поставьте нам оценку!',
			reaction: 'yes',
		})
	}
	agree_no() {
		this.stage = 'disagree'
		this.analytics.logEvent('review_engage', {
			title: 'Тогда поставьте нам оценку!',
			reaction: 'no',
		})
	}

	disagree_yes() {
		this.feedbackEvent.emit()
		this.analytics.logEvent('review_engage', {
			title: 'Расскажите, что не так?',
			reaction: 'yes',
		})
	}

	disagree_no() {
		this.closeEvent.emit()
		this.analytics.logEvent('review_engage', {
			title: 'Расскажите, что не так?',
			reaction: 'no',
		})
	}
}
