<hum-top-gradient></hum-top-gradient>
<ion-content [fullscreen]="true">
	<div class="page hum-content">
		<div class="top">
			<div class="title">
				{{ screen?.title }}
			</div>
			<div class="subtitle">
				{{ screen?.subtitle }}
			</div>
			<div class="selector">
				<hum-selector-item
					class="selector-item"
					*ngFor="let goal of screen?.choices"
					[text]="goal.value"
					(click)="onGoalClick(goal.id)"
					[checked]="isGoalActive(goal.id)"
				>
				</hum-selector-item>
			</div>
		</div>
	</div>
	<div class="footer">
		<hum-button [disabled]="!isFormValid" (click)="navigateNext()" class="button hum-content">
			{{ screen?.button_text }}
		</hum-button>
	</div>
	<hum-bottom-gradient></hum-bottom-gradient>
</ion-content>
