import {
	HapticsPlugin,
	ImpactOptions,
	NotificationOptions,
	VibrateOptions,
} from '@capacitor/haptics'

export abstract class Haptics implements HapticsPlugin {
	/**
	 * Trigger a haptics "impact" feedback
	 *
	 * @since 1.0.0
	 */
	abstract impact(options?: ImpactOptions): Promise<void>
	/**
	 * Trigger a haptics "notification" feedback
	 *
	 * @since 1.0.0
	 */
	abstract notification(options?: NotificationOptions): Promise<void>
	/**
	 * Vibrate the device
	 *
	 * @since 1.0.0
	 */
	abstract vibrate(options?: VibrateOptions): Promise<void>
	/**
	 * Trigger a selection started haptic hint
	 *
	 * @since 1.0.0
	 */
	abstract selectionStart(): Promise<void>
	/**
	 * Trigger a selection changed haptic hint. If a selection was
	 * started already, this will cause the device to provide haptic
	 * feedback
	 *
	 * @since 1.0.0
	 */
	abstract selectionChanged(): Promise<void>
	/**
	 * If selectionStart() was called, selectionEnd() ends the selection.
	 * For example, call this when a user has lifted their finger from a control
	 *
	 * @since 1.0.0
	 */
	abstract selectionEnd(): Promise<void>
}
