import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core'

@Component({
	selector: 'hum-label',
	templateUrl: './label.component.html',
	styleUrls: ['./label.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}

	@Input() type: string

	@HostBinding('class')
	get hostClass() {
		return `type-${this.type}`
	}
}
