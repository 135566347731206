import { Subscription } from 'rxjs'
import { Platform } from '@ionic/angular'
import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

// TODO удалить этот ебаный хардкодный костыль
@Injectable({
	providedIn: 'root',
})
export class DisableBackService {
	private whitelist: string[] = [
		// TODO refactoring
		'/onboarding/reason-to-join',
		'/onboarding/when-to-notify',
		'/auth/login',
		'/auth/registration',
		'/auth/reset',
		'/home/tabs/library/article',
		'/home/tabs/library/favorites',
		'/home/tabs/main/planet-transitions',
		'/home/tabs/main/article',
		'/home/tabs/main/profile',
		'/settings',
	]

	sub = new Subscription()
	constructor(private router: Router, private platform: Platform) {
		// call every have change page
		this.router.events.subscribe((ev) => {
			if (ev instanceof NavigationEnd) {
				const canBack = this.whitelist.find((el) => ev.url.startsWith(el))
				if (canBack) {
					this.enableBack()
				} else {
					this.disableBack()
				}
			}
		})
	}

	disableBack() {
		this.sub = this.platform.backButton.subscribeWithPriority(9999, () => {})
	}

	enableBack() {
		if (!this.sub.closed) {
			this.sub.unsubscribe()
		}
	}
}
