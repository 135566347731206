import { TranslateModule } from '@ngx-translate/core'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ArticleTemplateComponent } from './article-template'
import { ThemeModule } from '../theme/theme.module'
import { BodygraphTemplateComponent } from './bodygraph-template/bodygraph-template.component'
import { BodygraphModule } from 'app/bodygraph/bodygraph.module'
import { CompositeTemplateComponent } from './composite-template/composite-template.component'
import { TransitTemplateComponent } from './transit-template/transit-template.component'
import { PlanetTemplateComponent } from './planet-template/planet-template.component'
import { StoryTemplateComponent } from './story-template/story-template.component'

@NgModule({
	declarations: [
		ArticleTemplateComponent,
		BodygraphTemplateComponent,
		CompositeTemplateComponent,
		TransitTemplateComponent,
		PlanetTemplateComponent,
		StoryTemplateComponent,
	],
	imports: [CommonModule, ThemeModule, TranslateModule.forChild({ extend: true }), BodygraphModule],
})
export class ShareImagesTemplatesModule {}
