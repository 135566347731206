import { Component, HostBinding, Input } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import * as icons from './icons'

export type IconType = keyof typeof icons

@Component({
	selector: 'hum-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
	iconHtml: SafeHtml

	@HostBinding('innerHTML') get _iconHtml() {
		return this.iconHtml
	}

	@Input() set icon(value: IconType) {
		if (icons[value]) this.iconHtml = this.sanitizer.bypassSecurityTrustHtml(icons[value])
	}
	@Input() size: '24' | '16' | '18' | '72' | '96' = '24'
	@Input() active: boolean

	@HostBinding('class') get hostClasses() {
		return `size-${this.size} ${this.active && 'active'}`
	}

	constructor(private sanitizer: DomSanitizer) {}
}
