import { DetectEnvironment } from 'capacitor-detect-environment'
import { Injectable } from '@angular/core'
import configs, { ConfigName, IConfig, IServerConfig } from '../../../configs'
import { isNative } from '../utils'
import * as Sentry from '@sentry/capacitor'

@Injectable({
	providedIn: 'root',
})
export class ConfigService implements IConfig {
	name: ConfigName
	config: IConfig

	serverConfig: IServerConfig | null = null

	constructor(private env: DetectEnvironment) {}

	webQueryParams = {}

	// TODO Переписать на фабрику конфигов в DI
	async initialise() {
		if (isNative) {
			const isSandbox = await this.env.isSandbox()
			const isDebug = await this.env.isDebug()
			this.name = isSandbox || isDebug ? 'development' : 'production'
		} else {
			this.tryParseServerConfig()
			this.name = this.serverConfig.environment as ConfigName

			// parse query-params
			this.tryParseQueryParams()
		}

		this.config = configs[this.name]
		try {
			Sentry.setTag('env', this.name)
			Sentry.setTag('environment', this.name)
		} catch (err) {
			console.error(`[Sentry] — Cannot set environment`, err)
		}
	}

	get production() {
		return this.config?.production
	}
	get apiVersion() {
		return this.config.apiVersion
	}
	get apiUrl() {
		return this.config.apiUrl
	}
	get isTesting() {
		return this.config.isTesting
	}
	get oauth2() {
		return this.config.oauth2
	}
	get appMetricaApiKey() {
		return this.config.appMetricaApiKey
	}
	get analyticsEnabled() {
		return this.config.analyticsEnabled
	}
	get adapty() {
		return this.config.adapty
	}
	get sentry() {
		return this.config.sentry
	}
	get deeplinkSchema() {
		return this.config.deeplinkSchema
	}

	get amplitudeApiKey() {
		return this.config.amplitudeApiKey
	}

	get oneSignalAppId() {
		return this.config.oneSignalAppId
	}

	get facebookAppId() {
		return this.config.facebookAppId
	}

	get sharingUrl() {
		return this.config.sharingUrl
	}

	get webSubscriptionGroup() {
		if (!this.webQueryParams) return null

		return this.webQueryParams['s_group'] || this.webQueryParams['subscription_group'] || null
	}

	get webOnboardingGroup() {
		if (!this.webQueryParams) return null

		return this.webQueryParams['o_group'] || this.webQueryParams['onboarding_group'] || null
	}

	get webFromOnboarding() {
		return this.webQueryParams['from_onboarding'] === 'true'
	}

	get webCustomerId() {
		return this.webQueryParams['customer_id']
	}
	

	private tryParseServerConfig() {
		try {
			const config = JSON.parse(document.getElementById('config')?.innerHTML)
			this.serverConfig = config
		} catch (err) {
			console.warn('Failed to parse configuration', err)
		}
	}

	private tryParseQueryParams() {
		try {
			const params = new URLSearchParams(window.location.search)
			this.webQueryParams = Object.fromEntries(params)
			console.debug(`[ConfigService] — webQueryParams`, this.webQueryParams)
		} catch (err) {
			console.warn(`[ConfigService] — Failed to parse query params`, err)
		}
	}
}
