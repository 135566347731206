import { Injectable, Injector } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { ApiService } from 'app/core/api'
import { BillingResult, PaywallWithConfig, Product, TokenResponse } from '../interfaces'
import { Billing } from './billing-factory'
import { PriceFormatService } from '../utils'
import { ConfigService } from 'app/core/services'

type CreateSubscriptionResponse = {
	client_secret: string
	publishable_key: string
	intent_type: 'setup' | 'payment'
	user_country_code: string
	business_country_code: string
}

@Injectable({ providedIn: 'root' })
export class StripeBilling implements Billing {
	constructor(
		private api: ApiService,
		private injector: Injector,
		private translate: TranslateService,
		private formatter: PriceFormatService,
		private config: ConfigService,
	) {}

	private get modalCtrl() {
		return this.injector.get(ModalController)
	}

	async getPaywall(): Promise<PaywallWithConfig> {
		const rawProducts = await this.api
			.get<Array<StripeProduct>>(['billing', 'stripe', 'subscriptions'], {
				group: this.config.webSubscriptionGroup,
			})
			.toPromise()

		const products: Product[] = rawProducts
			.filter(p => !!p.details.active)
			.map(p => {
				const price = p.details.unit_amount / 100 // unit_amount in cents
				const currency_code = p.details.currency

				const interval_count = p.details.recurring.interval_count
				const interval = p.details.recurring.interval
				const period_localized = this.getLocalizedPeriod(interval, interval_count)

				return {
					id: p.vendor_id,
					title: p.title,

					price,
					currency_code,
					currency: this.formatter.currencyByCode(currency_code),
					price_localized: this.formatter.formatPrice(price, currency_code),
					discount_percent: p.discount || 0,

					period_unit: interval,
					period_number_of_units: interval_count,
					period_localized,
					discount: this.getProductDiscount(p),
				}
			})

		return {
			products,
			config: {},
		} as PaywallWithConfig
	}

	private getProductDiscount(product: StripeProduct): Product['discount'] | null {
		if (!!product.trial_period_days) {
			return this.getProductTrial(product)
		}
		if (!!product.first_period_price) {
			return this.getProductIntro(product)
		}

		return null
	}

	private getProductTrial(product: StripeProduct): Product['discount'] | null {
		const period = product.trial_period_days
		const period_unit = 'day'

		const price = 0

		const currency_code = product.details.currency
		const price_localized = this.formatter.formatPrice(price, currency_code)

		// console.log(product.trial_period_days)

		return {
			type: 'trial',
			period_unit: period_unit,
			period_number_of_units: period,
			period_localized: this.getLocalizedPeriod(period_unit, period),
			price: price,
			price_localized: price_localized,
			currency: this.formatter.currencyByCode(currency_code),
			currency_code: currency_code,
		}
	}

	private getProductIntro(product: StripeProduct): Product['discount'] | null {
		const period = 1
		const period_unit = product.details.recurring.interval

		const price = product.first_period_price / 100
		const currency_code = product.details.currency
		const price_localized = this.formatter.formatPrice(price, currency_code)

		return {
			type: 'promo',
			period_unit: period_unit,
			period_number_of_units: period,
			period_localized: this.getLocalizedPeriod(period_unit, period),
			price: price,
			price_localized: price_localized,
			currency: this.formatter.currencyByCode(currency_code),
			currency_code: currency_code,
		}
	}

	async order(productId: string, paywall: PaywallWithConfig): Promise<BillingResult> {
		try {
			const res = await this.api
				.post<CreateSubscriptionResponse>(['billing', 'stripe', 'create-subscription'], {
					price_id: productId,
					group: this.config.webSubscriptionGroup,
				})
				.toPromise()

			const modalComponent = await import('app/modules/subscription/modals/stripe-card-modal').then(
				m => m.StripeCardModalComponent,
			)

			const modal = await this.modalCtrl.create({
				cssClass: 'hum-stripe-modal',
				componentProps: {
					clientSecret: res.client_secret,
					publishableKey: res.publishable_key,
					intentType: res.intent_type,
					userCountryCode: res.user_country_code,
					businessCountryCode: res.business_country_code,
					product: paywall.products.find(p => p.id === productId),
				},
				component: modalComponent,
				swipeToClose: false,
				animated: true,
				backdropDismiss: false,
			})

			await modal.present()

			const { data } = await modal.onDidDismiss()

			const { status, method } = data || {}

			if (status === 'success') {
				return { status: 'success', method }
			} else {
				return { status: 'canceled', method }
			}
		} catch (err) {
			const message = -7
			return {
				status: 'failed',
				error: { original_error: err, message, code: null },
			}
		}
	}

	async restore(): Promise<BillingResult<TokenResponse>> {
		try {
			const modalComponent = await import('app/modules/subscription/modals').then(
				m => m.EmailRestoreModalComponent,
			)

			const modal = await this.modalCtrl.create({
				component: modalComponent,
				swipeToClose: false,
				animated: true,
				backdropDismiss: false,
			})

			await modal.present()

			const { data } = await modal.onDidDismiss()

			if (data.status === 'success') {
				return data
			} else {
				return { status: 'canceled' }
			}
		} catch (err) {
			const message = -6
			return {
				status: 'failed',
				error: { original_error: err, message, code: null },
			}
		}
	}

	getLocalizedPeriod(period: string, period_count: number) {
		return this.translate.instant(`billing.period.${period}`, {
			count: period_count,
		})
	}
}

type StripeProduct = {
	id: number
	vendor_id: string
	title: string
	trial_period_days?: number | null
	first_period_price?: number
	discount?: number
	details: {
		id: string
		object: string
		active: boolean
		billing_scheme: string
		created: number
		currency: string
		custom_unit_amount: null | string
		livemode: boolean
		lookup_key: null | string
		metadata: object
		nickname: null | string
		product: string
		recurring: {
			aggregate_usage: null | unknown
			interval: string
			interval_count: number
			trial_period_days: null | number
			usage_type: string
		}
		tax_behavior: string
		tiers_mode: null | unknown
		transform_quantity: null | unknown
		type: string
		unit_amount: number
		unit_amount_decimal: string
	}
}
