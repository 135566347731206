import { Directive, HostListener, Input } from '@angular/core'
import { AnalyticsService } from 'app/core/services'

@Directive({
	selector: '[trackClick]',
})
export class TrackClickDirective {
	constructor(private analytics: AnalyticsService) {}

	@Input('trackClick') eventName: string
	@Input('trackParams') eventParams?: object

	@HostListener('click')
	onClick() {
		this.analytics.logEvent(this.eventName, this.eventParams)
	}
}
