import { Component, HostBinding, Input, OnInit } from '@angular/core'

@Component({
	selector: 'hum-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
	@Input() color: string = 'gold'
	@Input() disabled: boolean = false
	@Input() loading = false
	@Input() type: string

	@HostBinding('class') get hostClasses() {
		return `color-${this.color} ${this.disabled && 'disabled'} ${this.loading && 'loading'}`
	}

	constructor() {}

	ngOnInit(): void {}
}
