export class DetectEnvironmentWeb {
	async isDebug() {
		return true
	}
	async isTestFlight() {
		return false
	}
	async isAdHoc() {
		return false
	}
	async isSimulator() {
		return false
	}
	async isAppStore() {
		return false
	}
	async isSandbox() {
		return false
	}
}
