import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Adapty } from 'capacitor-adapty'
import { DetectEnvironment } from 'capacitor-detect-environment'
import { AppTrackingTransparency } from 'capacitor-app-tracking-transparency'
import { Appsflyer } from 'capacitor-appsflyer'
import { RemoteConfig } from 'capacitor-remote-config'
import { FirebaseAnalytics } from 'capacitor-plugin-firebase-analytics'
import { StoreReview } from 'capacitor-store-review'
import { Amplitude } from 'capacitor-amplitude'
import { OneSignal } from 'capacitor-onesignal'
import {
	AdaptyWeb,
	DetectEnvironmentWeb,
	FirebaseAnalyticsWeb,
	HapticsWeb,
	InAppBrowserWeb,
	KeyboardWeb,
	OneSignalWeb,
	PushNotificationsWeb,
	RemoteConfigWeb,
	SafariViewControllerWeb,
} from './web'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { Facebook } from 'capacitor-facebook'
import { Haptics } from './haptics'
import { Keyboard } from './keyboard'
import { PushNotifications } from './push-notifications'
import { Device } from './device'
import { isNative } from 'app/core/utils'
import { Haptics as HapticsPlugin } from '@capacitor/haptics'
import { Keyboard as KeyboardPlugin } from '@capacitor/keyboard'
import { PushNotifications as PushNotificationsPlugin } from '@capacitor/push-notifications'
import { Device as DevicePlugin } from '@capacitor/device'
import { DetectScreenCapture } from 'capacitor-detect-screen-capture'
import { Sharing } from '@rediska1114/capacitor-sharing'

@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [
		{
			provide: Haptics,
			useValue: isNative ? HapticsPlugin : new HapticsWeb(),
		},
		{
			provide: Keyboard,
			useValue: isNative ? KeyboardPlugin : new KeyboardWeb(),
		},
		{
			provide: Adapty,
			useClass: isNative ? Adapty : AdaptyWeb,
		},
		{
			provide: DetectEnvironment,
			useClass: isNative ? DetectEnvironment : DetectEnvironmentWeb,
		},
		{
			provide: RemoteConfig,
			useClass: isNative ? RemoteConfig : RemoteConfigWeb,
		},
		{
			provide: OneSignal,
			useClass: isNative ? OneSignal : OneSignalWeb,
		},
		{
			provide: PushNotifications,
			useValue: isNative ? PushNotificationsPlugin : new PushNotificationsWeb(),
		},
		{
			provide: SafariViewController,
			useClass: isNative ? SafariViewController : SafariViewControllerWeb,
		},
		{
			provide: InAppBrowser,
			useClass: isNative ? InAppBrowser : InAppBrowserWeb,
		},
		{
			provide: FirebaseAnalytics,
			useClass: isNative ? FirebaseAnalytics : FirebaseAnalyticsWeb,
		},
		{ provide: Amplitude, useFactory: () => Amplitude.getInstance() },
		{ provide: Device, useValue: DevicePlugin },
		DetectScreenCapture,
		SplashScreen,
		AppTrackingTransparency,
		StoreReview,
		Appsflyer,
		Sharing,
		Facebook,
	],
})
export class PluginsModule {}
