import { Component, Input, OnInit } from '@angular/core';
import { IconType } from '../icon/icon.component';



@Component({
  selector: 'hum-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {

  @Input() icon: IconType
  constructor() { }

  ngOnInit(): void {
  }

}
