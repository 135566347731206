import { Component } from '@angular/core'
import { AnalyticsService } from 'app/core/services'
import { ViewDidEnter, ViewWillEnter } from '@ionic/angular'
import {
	OnboardingProfileInfo,
	OnboardingService,
} from 'app/modules/onboarding/services/onboarding'

@Component({
	selector: 'hum-summary-new',
	templateUrl: './summary-new.component.html',
	styleUrls: ['./summary-new.component.scss'],
})
export class SummaryNewComponent implements ViewWillEnter, ViewDidEnter {
	info: OnboardingProfileInfo | null

	constructor(private onboardingService: OnboardingService, private analytics: AnalyticsService) {
		this.onboardingService.info.subscribe((info) => {
			this.info = info
		})
	}

	ionViewWillEnter(): void {}

	ionViewDidEnter() {
		this.analytics.logEvent('onb_summary_new')
	}

	get profile() {
		return this.info?.profile_info
	}
	get type() {
		return this.info?.tag_info
	}
	get summary() {
		return this.info?.summary
	}

	navigateNext() {
		this.onboardingService.navigateNext()
	}
}
