import { BodygraphDetailsComponent, BODYGRAPH_DETAIL } from '../bodygraph-details'
import { BehaviorSubject, combineLatest, merge, ReplaySubject } from 'rxjs'
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostBinding,
	HostListener,
	InjectionToken,
	Input,
	OnDestroy,
	QueryList,
} from '@angular/core'
import { timing } from '../bodygraph-details/animation'
import { skip, takeUntil } from 'rxjs/operators'
import {
	BodygraphGroups,
	Planet,
	BODYGRAPH_ROOT_HEIGHT,
	BODYGRAPH_ROOT_WIDTH,
	BodygraphColor,
} from '../bodygraph-root'
import { transition, trigger } from '@angular/animations'

export const BODYGRAPH_VIEW = new InjectionToken('BODYGRAPH_VIEW')

@Component({
	selector: 'hum-bodygraph-view',
	templateUrl: './bodygraph-view.component.html',
	styleUrls: ['./bodygraph-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: BODYGRAPH_VIEW,
			useExisting: forwardRef(() => BodygraphViewComponent),
		},
	],
	animations: [
		trigger('parentDisable', [transition('void => true', []), transition(':leave', [])]),
	],
})
export class BodygraphViewComponent implements AfterContentInit, OnDestroy {
	private destroyed$ = new ReplaySubject(1)

	constructor(private cdr: ChangeDetectorRef, public host: ElementRef<HTMLElement>) {}

	@ContentChildren(BODYGRAPH_DETAIL) details?: QueryList<BodygraphDetailsComponent>

	@Input() gates: { red_gates: number[]; black_gates: number[] } = null
	@Input() centers: number[] | null = null // TODO remove null
	@Input() groups: BodygraphGroups | null = null
	@Input() color: BodygraphColor = 'default'
	@Input() startAnimation = false

	redPlanets: Planet[]
	blackPlanets: Planet[]
	detailsOpen = new BehaviorSubject(true)
	maxWidth = '100%'

	rootMargin = 4

	isAnimationDisabled = true

	@HostBinding('@parentDisable')
	get parentDisable() {
		return this.isAnimationDisabled
	}

	ngOnInit() {
		if (this.startAnimation) {
			this.isAnimationDisabled = false

			this.cdr.detectChanges()
		}
	}

	ngAfterContentInit(): void {
		if (this.isAnimationDisabled) {
			setTimeout(() => {
				this.isAnimationDisabled = false
				this.cdr.markForCheck()
			})
		}

		merge(this.details.changes, this.detailsOpen.pipe(skip(1)))
			.pipe(takeUntil(this.destroyed$))
			.subscribe(() => {
				console.debug('[bodygraph-view] — change', this.calcMaxWidth())
			})
	}

	ngOnDestroy() {
		this.destroyed$.next(true)
		this.destroyed$.complete()
	}

	@HostListener('click')
	onClick() {
		this.detailsOpen.next(!this.detailsOpen.getValue())
	}

	getTransition() {
		return timing
	}

	private calcMaxWidth() {
		const freeSpace = this._getFreeSpace()

		const maxWidth = this._calcSvgWidth(freeSpace) - this.rootMargin * 2

		this.maxWidth = `${maxWidth}px`
		this.cdr.detectChanges()

		return maxWidth
	}

	private _getFreeSpace() {
		const hostWidth = this.host.nativeElement.getBoundingClientRect().width

		if (!this.details?.length || !this.detailsOpen.getValue()) {
			return hostWidth
		}

		const detailsWidth = this.details
			.map(d => d.getDetailWidth())
			.reduce((all, cur) => all + cur, 0)

		return hostWidth - detailsWidth
	}

	private _calcSvgWidth(widthFreeSpace: number) {
		const hostHeight = this.host.nativeElement.getBoundingClientRect().height

		const hostRatio = widthFreeSpace / hostHeight
		const svgRatio = BODYGRAPH_ROOT_WIDTH / BODYGRAPH_ROOT_HEIGHT
		let scale = 1

		if (hostRatio > svgRatio) {
			scale = hostHeight / BODYGRAPH_ROOT_HEIGHT
		} else {
			scale = widthFreeSpace / BODYGRAPH_ROOT_WIDTH
		}

		return BODYGRAPH_ROOT_WIDTH * scale
	}
}
