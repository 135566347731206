import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ModalController } from '@ionic/angular'
import { CitySearchService } from 'app/core/services/city-search.service'
import { ReplaySubject } from 'rxjs'
import { switchMap, takeUntil } from 'rxjs/operators'

@Component({
	selector: 'hum-city-search-modal',
	templateUrl: './city-search-modal.component.html',
	styleUrls: ['./city-search-modal.component.scss'],
	providers: [CitySearchService],
	host: {
		class: 'hum-modal',
	},
})
export class CitySearchModalComponent implements OnInit, OnDestroy {
	private destroyed$ = new ReplaySubject(1)
	constructor(private modal: ModalController, private citySearch: CitySearchService) {}

	search = new FormControl('')

	ngOnInit(): void {
		this.subscribeToSearchChanges()
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true)
		this.destroyed$.complete()
	}

	private subscribeToSearchChanges() {
		this.search.valueChanges
			.pipe(
				switchMap(location => this.citySearch.search(location)),
				takeUntil(this.destroyed$),
			)
			.subscribe()
	}

	get tips() {
		return this.citySearch.tips
	}

	get loading() {
		return this.citySearch.loading
	}

	get state() {
		if (this.citySearch.notFound) {
			return 'not-found'
		}
		if (!this.search.value) {
			return 'placeholder'
		}
		return 'tips'
	}

	onTipClick(tip) {
		this.modal.dismiss(tip)
	}

	dismiss() {
		this.modal.dismiss({})
	}
}
