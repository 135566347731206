<label class="wrapper">
	<input
		[type]="type"
		class="input"
		[class.centered]="centered"
		[class.focused]="focused"
		[placeholder]="placeholder"
		[attr.enterkeyhint]="enterkeyhint"
		[attr.inputmode]="inputmode"
		[class.error]="error"
		[class.with-label]="isShowLabel"
		[class.loading]="loading !== undefined"
		[ngModel]="value"
		(keypress)="onSubmit($event)"
		(ngModelChange)="updateValue($event)"
		#input
	/>
	<label *ngIf="isShowLabel" class="label" [class.active]="active">{{ label }}</label>
	<hum-spinner *ngIf="loading" class="spinner"></hum-spinner>
</label>
