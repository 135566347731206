import { SpinnerComponent } from './../components/spinner/spinner.component'
import {
	ChangeDetectorRef,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Directive,
	Input,
	OnInit,
	Renderer2,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core'

const SPINNER_SHOW_DELAY_MS = 400

@Directive({
	selector: '[humLoading]',
})
export class LoadingDirective {
	private timer = null
	private componentFactory: ComponentFactory<SpinnerComponent>
	private spinnerComponent: ComponentRef<SpinnerComponent>

	constructor(
		private container: ViewContainerRef,
		private template: TemplateRef<any>,
		private componentFactoryResolver: ComponentFactoryResolver,
		private renderer2: Renderer2,
		private cdr: ChangeDetectorRef,
	) {
		this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(SpinnerComponent)
	}

	_loading = true
	@Input('humLoading')
	set loading(loading: boolean) {
		this._loading = loading
		this.updateLoading()
	}

	// ngOnInit() {
	// 	if (!this.timer) this.updateLoading()
	// }

	private updateLoading() {
		if (this.timer) {
			clearTimeout(this.timer)
		}

		this.container.clear()

		if (this._loading) {
			this.timer = setTimeout(() => {
				this.spinnerComponent = this.container.createComponent(this.componentFactory)
				this.renderer2.addClass(this.spinnerComponent.location.nativeElement, 'hum-page-spinner')
				this.spinnerComponent.instance.size = 'large'
			}, SPINNER_SHOW_DELAY_MS)
		} else {
			this.container.createEmbeddedView(this.template)
		}

		this.cdr.detectChanges()
	}
}
