import {
	Component,
	ChangeDetectionStrategy,
	HostBinding,
	ChangeDetectorRef,
	Input,
} from '@angular/core'

@Component({
	selector: 'hum-like-button',
	templateUrl: './like-button.component.html',
	styleUrls: ['./like-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LikeButtonComponent {
	constructor(private cdr: ChangeDetectorRef) {}

	@HostBinding('class.is-liked')
	private _isLiked = false

	@Input()
	set isLiked(liked: boolean) {
		this._isLiked = liked
		this.cdr.markForCheck()
	}

	get isLiked() {
		return this._isLiked
	}
}
