import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core'
import {
	BrowserModule,
	HammerGestureConfig,
	HammerModule,
	HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { ThemeModule } from './theme/theme.module'
import { CoreModule } from 'app/core/core.module'
import { DisableBackService, ConfigService } from 'app/core/services'
import { GlobalErrorHandler } from 'app/core/errors/global-error-handler'
import { PluginsModule } from './plugins/plugins.module'
import { customIosTransitionAnimation } from 'app/core/transitions/defaultPageTransition.transition'
import { SetupClass } from './setup.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BillingModule } from 'app/core/billing'
import { I18nModule } from './i18n'
import { LottieModule, LottieCacheModule } from 'ngx-lottie'
// import player from 'lottie-web/build/player/lottie_svg'
import { ShareImagesTemplatesModule } from './share-images-templates'

export function playerFactory() {
	return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web')
}

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
	overrides = {
		pan: {
			direction: Hammer.DIRECTION_ALL,
		},
		pinch: {
			// enable: false,
		},
		rotate: {
			enable: false,
		},
	}
}

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		CoreModule,
		IonicModule.forRoot({ navAnimation: customIosTransitionAnimation, mode: 'ios' }),
		AppRoutingModule,
		ThemeModule,
		PluginsModule,
		BrowserAnimationsModule,
		I18nModule.forRoot(),
		LottieModule.forRoot({ player: playerFactory }),
		LottieCacheModule,
		BillingModule,
		HammerModule,
		ShareImagesTemplatesModule,
	],
	providers: [
		DisableBackService, // TODO remove
		SetupClass,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: ErrorHandler,
			useFactory: configService => new GlobalErrorHandler(configService),
			deps: [ConfigService],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (setup: SetupClass) => () => setup.initializeApp(),
			deps: [SetupClass],
			multi: true,
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: CustomHammerConfig,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
