import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	HostListener,
	ChangeDetectorRef,
} from '@angular/core'
import { ModalController } from '@ionic/angular'
import { SharingTarget } from 'app/core/services'
import { IconType } from 'app/theme/components'

@Component({
	selector: 'hum-sharing-modal',
	templateUrl: './sharing-modal.component.html',
	styleUrls: ['./sharing-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharingModalComponent implements OnInit {
	@Input() targets: SharingTarget[]
	@Input() share: (target: SharingTarget) => void
	@Input() id?: string

	shareLoadingTarget: SharingTarget | null = null

	constructor(private modal: ModalController, private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {}

	@HostListener('click')
	onHostClick() {
		this.dismiss()
	}

	dismiss() {
		this.modal.dismiss({}, null, this.id)
	}

	shareTo(target: SharingTarget) {
		this.shareLoadingTarget = target
		this.cdr.detectChanges()

		this.share(target)
	}

	hasTarget(target: SharingTarget) {
		return this.targets.includes(target)
	}

	targetsMap: Record<SharingTarget, { icon: IconType }> = {
		instagramStories: {
			icon: 'instagram',
		},
		native: {
			icon: 'more',
		},
	}

	stopPropagation(event: Event) {
		event.stopPropagation()
	}
}
