import { takeUntil } from 'rxjs/operators'
import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	InjectionToken,
	forwardRef,
	ContentChildren,
	QueryList,
	OnDestroy,
	ChangeDetectorRef,
	AfterContentInit,
	Input,
	Output,
	EventEmitter,
} from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { HUM_TAB, TabComponent } from '../tab'

export const HUM_TABS = new InjectionToken<typeof TabsComponent>('HUM_TABS')

@Component({
	selector: 'hum-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: HUM_TABS,
			useExisting: forwardRef(() => TabsComponent),
		},
	],
})
export class TabsComponent implements OnDestroy, AfterContentInit {
	private destroyed$ = new ReplaySubject(1)

	private _selected: string | null = null
	@Input()
	set selected(value: string | null) {
		this._selected = value
		this.cdr.detectChanges()
	}
	get selected() {
		return this._selected
	}

	@Output() selectedChange = new EventEmitter<string>()

	@ContentChildren(HUM_TAB, { read: TabComponent }) tabs?: QueryList<TabComponent>

	constructor(private cdr: ChangeDetectorRef) {}

	ngAfterContentInit(): void {
		if (!this.selected && this.tabs.length > 0) {
			this.selected = this.tabs.first.id
			this.selectedChange.emit(this.selected)
		}

		this.tabs.changes.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.cdr.detectChanges()
		})
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true)
		this.destroyed$.complete()
	}

	onTabClick(tabId: string) {
		this.selected = tabId
		this.selectedChange.emit(tabId)
		this.cdr.detectChanges()
	}

	get activeTabIndex() {
		return this.tabs?.toArray()?.findIndex(tab => tab.id === this.selected)
	}

	get tabsCount() {
		return this.tabs?.length || 0
	}

	get selectedLeft() {
		return this.activeTabIndex * this.selectedWidth
	}

	get selectedWidth() {
		if (!this.tabsCount) return 0

		return 100 / this.tabsCount
	}
}
