<hum-top-gradient></hum-top-gradient>
<ion-content [fullscreen]="true">
	<div class="page hum-content">
		<div class="video-wrapper">
			<video
				[src]="videoSrc"
				loop
				autoplay
				muted
				playsinline
				class="video"
				preload="auto"
				#video
			></video>
		</div>

		<div class="top">
			<div class="title">{{ 'onboarding.tutorial-1.title' | translate }}</div>
			<div class="list">
				<div *ngFor="let feature of features" class="list-item">
					<hum-icon icon="check" size="24" class="list-item-icon"></hum-icon>
					{{ feature | translate }}
				</div>
			</div>
		</div>
	</div>
	<div class="footer">
		<hum-button class="button hum-content" (click)="navigateNext()">
			{{ 'onboarding.tutorial-1.next_button' | translate }}
		</hum-button>
	</div>
	<hum-bottom-gradient></hum-bottom-gradient>
</ion-content>
