import { RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { OverlayModule } from '@angular/cdk/overlay'

import {
	ContactUsModalComponent,
	DatepickerModalComponent,
	CitySearchModalComponent,
	ArticleModalComponent,
	SharingModalComponent,
	ScreenshotModalComponent,
} from './modals'
import { TranslateModule } from '@ngx-translate/core'

import {
	IconButtonComponent,
	TitleComponent,
	ShortCardComponent,
	IconComponent,
	SecondaryButtonComponent,
	CardArticleComponent,
	TabButtonComponent,
	SearchInputComponent,
	InputComponent,
	ButtonComponent,
	CollectionCardComponent,
	ArticleCardComponent,
	TextComponent,
	SelectorItemComponent,
	BackButtonComponent,
	ToolbarComponent,
	SpinnerComponent,
	TextareaComponent,
	FeedbackCardComponent,
	BottomDrawerComponent,
	LoadingComponent,
	LikeButtonComponent,
	ShortCardSkeletonComponent,
	CollectionCardSkeletonComponent,
	CardArticleSkeletonComponent,
	LabelComponent,
	SharingSocialTagComponent,
	ShareButtonComponent,
	BackButtonDirective,
	TabsComponent,
	TabComponent,
	BodygraphAspectCardComponent,
	ParticlesComponent,
	ParticleDirective,
	DatetimeComponent,
	DynamicSvgComponent,
	CitySearchComponent,
	CitySearchDirective,
	TooltipComponent,
} from './components'

import {
	AutoFocusDirective,
	TrackClickDirective,
	IsWebDirective,
	IsNativeDirective,
	LoadingDirective,
	SkeletonDirective,
	CountUpDirective,
	BackgroundImageDirective,
	OutsideClickIgnoreDirective,
	ClickStopPropagationDirective,
	InfoDirective,
	PitchToZoom2Directive,
	TextFillDirective,
	TooltipDirective,
} from './directives'
import { LottieModule } from 'ngx-lottie'
import { DateFormatPipe, GradientImagePipe, UrlBackgroundPipe } from './pipes'
import { DatePickerService } from './services'

const COMPONENTS = [
	IconButtonComponent,
	TitleComponent,
	ShortCardComponent,
	ShortCardSkeletonComponent,
	IconComponent,
	SecondaryButtonComponent,
	CardArticleComponent,
	TabButtonComponent,
	SearchInputComponent,
	InputComponent,
	ButtonComponent,
	CollectionCardComponent,
	CollectionCardSkeletonComponent,
	ArticleCardComponent,
	TextComponent,
	SelectorItemComponent,
	BackButtonComponent,
	ToolbarComponent,
	SpinnerComponent,
	TextareaComponent,
	FeedbackCardComponent,
	BottomDrawerComponent,
	LoadingComponent,
	LikeButtonComponent,
	CardArticleSkeletonComponent,
	LabelComponent,
	SharingSocialTagComponent,
	ShareButtonComponent,
	TabsComponent,
	TabComponent,
	BodygraphAspectCardComponent,
	ParticlesComponent,
	BackButtonDirective,
	DatetimeComponent,
	DynamicSvgComponent,
	CitySearchComponent,
	TooltipComponent,
]

const DIRECTIVES = [
	AutoFocusDirective,
	TrackClickDirective,
	IsWebDirective,
	IsNativeDirective,
	LoadingDirective,
	SkeletonDirective,
	CountUpDirective,
	BackgroundImageDirective,
	ParticleDirective,
	OutsideClickIgnoreDirective,
	ClickStopPropagationDirective,
	InfoDirective,
	PitchToZoom2Directive,
	TextFillDirective,
	CitySearchDirective,
	TooltipDirective,
]

const MODALS = [
	ContactUsModalComponent,
	DatepickerModalComponent,
	CitySearchModalComponent,
	ArticleModalComponent,
	SharingModalComponent,
	ScreenshotModalComponent,
]

const PIPES = [DateFormatPipe, GradientImagePipe, UrlBackgroundPipe]

@NgModule({
	declarations: [...COMPONENTS, ...DIRECTIVES, ...MODALS, ...PIPES],
	providers: [DatePickerService],
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		TranslateModule.forChild({ extend: true }),
		LottieModule,
		OverlayModule,
	],
	exports: [...COMPONENTS, ...DIRECTIVES, ...MODALS, ...PIPES],
})
export class ThemeModule {}
