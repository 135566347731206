<div class="header">
	<div class="left">
		<div class="title">{{ collection?.title }}</div>
	</div>
</div>
<div class="content">
	<div class="articles">
		<hum-article-card
			class="article"
			*ngFor="let article of collection?.articles; trackBy: trackById"
			[article]="article"
			(click)="articleClick.emit(article)"
		>
		</hum-article-card>
		<div class="article more-card" *ngIf="hasMore" (click)="moreClick.emit(collection)">
			<div class="more-card-arrow-wrapper">
				<hum-icon class="more-card-arrow" icon="arrow_right" size="24"></hum-icon>
			</div>
			<div class="more-card-text">{{ 'collection_card.more_text' | translate }}</div>
		</div>
	</div>
</div>
