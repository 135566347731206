import { AnalyticsService } from 'app/core/services'
import { Component, NgZone } from '@angular/core'
import { FormControl } from '@angular/forms'
import { OnboardingService } from '../../../../services/onboarding'
import { ViewDidEnter } from '@ionic/angular'
import { DEFAULT_DATE } from 'app/contants'

@Component({
	selector: 'hum-birthday-date',
	templateUrl: './birthday-date.component.html',
	styleUrls: ['./birthday-date.component.scss'],
})
export class BirthdayDateComponent implements ViewDidEnter {
	constructor(
		private analytics: AnalyticsService,
		private onboardingService: OnboardingService,
		private zone: NgZone,
	) {}

	birthday_date = new FormControl(this.onboardingService.profile.birthdate || DEFAULT_DATE)

	ionViewDidEnter(): void {
		this.analytics.logOnboardingEvent('onb_age')
	}

	navigateNext() {
		this.zone.run(async () => {
			await this.onboardingService.updateProfile({ birthdate: this.birthday_date.value })
			await this.onboardingService.navigateNext()
		})
	}

	get defaultHref() {
		return this.onboardingService.getPrevRoute()
	}

	maxDate = new Date()
}
