import { ToastService } from './../services/toast.service'
import { Injectable } from '@angular/core'
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http'
import { Observable, from, of, throwError, timer } from 'rxjs'
import { retryWhen, mergeMap } from 'rxjs/operators'

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
	constructor() {}

	private readonly RETRY_STATUSES = [0, 502, 503, 504]
	private readonly maxRetryAttempts = 8
	private readonly delay = 1000

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let retriesCount = 0

		return next.handle(request).pipe(
			retryWhen(errors => {
				return errors.pipe(
					mergeMap((error: any) => {
						if (!this.RETRY_STATUSES.includes(error.status)) {
							return throwError(error)
						}

						const delayMs = this.getDelay(retriesCount, this.delay)

						if (retriesCount++ < this.maxRetryAttempts) {
							console.log(`Attempt ${retriesCount}: retrying in ${delayMs}ms`)

							return timer(delayMs)
						}

						console.warn(`Retries limit reached for ${error.url}`)
						return throwError(error)
					}),
				)
			}),
		)
	}

	private getDelay(retries: number, delayDefault: number) {
		if (retries < 2) {
			return delayDefault
		} else {
			const backOff = 0.3
			const backOffLimit = 10000
			return Math.min(backOffLimit, backOff * 2 ** retries * 1000)
		}
	}
}
