import { TranslateService } from '@ngx-translate/core'
import { Component, Input } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { IFocusable } from 'app/core/interfaces'
import { AlertService, AnalyticsService } from 'app/core/services'
import { FeedbackService } from 'app/core/services/feedback.service'
import { ModalController } from '@ionic/angular'
import { isApiError, requiredWithTrimValidator } from 'app/core/utils'
import { Router } from '@angular/router'

@Component({
	selector: 'hum-contact-us-modal',
	templateUrl: './contact-us-modal.component.html',
	styleUrls: ['./contact-us-modal.component.scss'],
	host: {
		class: 'hum-modal',
	},
})
export class ContactUsModalComponent {
	loading = false
	constructor(
		private modal: ModalController,
		private fb: FormBuilder,
		private feedback: FeedbackService,
		private analytics: AnalyticsService,
		private translate: TranslateService,
		private alertService: AlertService,
		private router: Router,
	) {}

	@Input() showFaq = true

	feedbackForm = this.fb.group({
		text: this.fb.control('', [requiredWithTrimValidator]),
		email: this.fb.control(''),
	})

	dismiss() {
		this.modal.dismiss({})
	}

	async submit() {
		let { text = '', email } = this.feedbackForm.getRawValue()
		text = text.trim()

		if (email) {
			email = email.trim()
		}

		if (!text) return

		this.loading = true
		this.analytics.logEvent('feedback_sent', { text, email })
		try {
			await this.feedback.sendFeedback(text, email)
			this.modal.dismiss({})

			const message = await this.translate
				.get('contact_us.success_alert', { email: email || null })
				.toPromise()

			// TODO use custom popup
			alert(message)
		} catch (err) {
			let message: string | number = -14

			if (isApiError(err)) {
				message = err.error.error_message
			}
			this.alertService.alert(message)
		}
		this.loading = false
	}

	goToFaq() {
		this.modal.dismiss()

		this.router.navigate(['/settings/faq'])
	}

	// TODO move to directive
	nextInput(nextElement: IFocusable) {
		nextElement.setFocus()
	}
}
