import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Optional,
	forwardRef,
	Inject,
	HostListener,
	HostBinding,
	InjectionToken,
	Input,
} from '@angular/core'
import { HUM_TABS, TabsComponent } from '../tabs'
import { v4 as uuid } from 'uuid'

export const HUM_TAB = new InjectionToken<unknown>('HUM_TAB')

@Component({
	selector: 'hum-tab',
	templateUrl: './tab.component.html',
	styleUrls: ['./tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: HUM_TAB,
			useExisting: forwardRef(() => TabComponent),
		},
	],
})
export class TabComponent implements OnInit {
	constructor(@Inject(HUM_TABS) private tabs: TabsComponent) {}

	@Input() id = uuid()

	ngOnInit(): void {}

	@HostListener('click')
	onClick() {
		this.tabs.onTabClick(this.id)
	}

	@HostBinding('class.is-active')
	get isActive() {
		return this.id === this.tabs.selected
	}
}
