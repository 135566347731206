<ng-template
	#overlay="cdkConnectedOverlay"
	[cdkConnectedOverlay]
	[cdkConnectedOverlayOrigin]="origin"
	[cdkConnectedOverlayOpen]="showTooltip"
	[cdkConnectedOverlayPositions]="positions"
	[cdkConnectedOverlayPush]="true"
	[cdkConnectedOverlayHasBackdrop]="true"
	(positionChange)="positionChange($event)"
	(backdropClick)="closeTooltip()"
>
	<div class="tooltip-container" (click)="closeTooltip()">
		{{'tooltips.' + tooltipId | translate}}
	</div>
</ng-template>
