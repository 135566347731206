import { Component } from '@angular/core'
import { AnalyticsService } from 'app/core/services'
import { ViewDidEnter } from '@ionic/angular'
import { OnboardingService } from '../../../../services/onboarding'

@Component({
	selector: 'hum-tutorial1',
	templateUrl: './tutorial1.component.html',
	styleUrls: ['./tutorial1.component.scss'],
})
export class Tutorial1Component implements ViewDidEnter {
	constructor(private onboardingService: OnboardingService, private analytics: AnalyticsService) {}

	ionViewDidEnter() {
		this.analytics.logOnboardingEvent('onb_tutor1')
	}

	get videoSrc() {
		return this.onboardingService.getVideo('bodygraph')
	}

	features = [
		'onboarding.tutorial-1.features.0',
		'onboarding.tutorial-1.features.1',
		'onboarding.tutorial-1.features.2',
	]

	async navigateNext() {
		this.onboardingService.navigateNext()
	}
}
