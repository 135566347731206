import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core'
import { DomController, Gesture, GestureController } from '@ionic/angular'

@Directive({
	selector: '[humPitchToZoom2]',
})
export class PitchToZoom2Directive{ // implements AfterViewInit {
	constructor(
		private renderer: Renderer2,
		private gestureController: GestureController,
		private host: ElementRef<HTMLElement>,
		private domController: DomController,
	) {}

	@Input('humPitchToZoom2') _: unknown

	// adjustScale = 1
	// adjustDeltaX = 0
	// adjustDeltaY = 0

	// currentScale = null
	// currentDeltaX = null
	// currentDeltaY = null

	// transition = 'transform 0.2s ease-out'

	// ngAfterViewInit() {
	// 	// Prevent long press saving on mobiles.
	// 	// this.container.addEventListener('touchstart', function (e) {
	// 	// 	e.preventDefault()
	// 	// })
	// }

	// @HostListener('pan', ['$event'])
	// @HostListener('pinch', ['$event'])
	// onPanOrPinch(event: HammerInput) {
	// 	// Adjusting the current pinch/pan event properties using the previous ones set when they finished touching
	// 	this.currentScale = this.adjustScale * event.scale
	// 	this.currentDeltaX = this.adjustDeltaX + event.deltaX / this.currentScale
	// 	this.currentDeltaY = this.adjustDeltaY + event.deltaY / this.currentScale

	// 	this.boundCurrentValues()

	// 	this.domController.write(() => {
	// 		this.setTransition(null)
	// 		this.setTransform([
	// 			scale(this.currentScale),
	// 			translate(this.currentDeltaX, this.currentDeltaY),
	// 		])
	// 	})
	// }

	// @HostListener('doubletap', ['$event'])
	// onDoubleTap(event: HammerInput) {
	// 	event.preventDefault()

	// 	this.adjustScale += 1

	// 	if (this.adjustScale >= 3) {
	// 		this.adjustScale = 1
	// 	}

	// 	this.boundCurrentValues()

	// 	this.domController.write(() => {
	// 		this.setTransition(this.transition)
	// 		this.setTransform([scale(this.adjustScale), translate(event.center.x, event.center.y)])
	// 	})
	// }

	// @HostListener('panend', ['$event'])
	// @HostListener('pinchend', ['$event'])
	// onPanOrPinchEnd(event: HammerInput) {
	// 	// Saving the final transforms for adjustment next time the user interacts.
	// 	const velX = event.velocityX
	// 	const velY = event.velocityY
	// 	let mul = (300 / 16) * 2

	// 	this.adjustScale = this.currentScale
	// 	this.adjustDeltaX = this.currentDeltaX + velX * mul
	// 	this.adjustDeltaY = this.currentDeltaY + velY * mul

	// 	this.boundCurrentValues()

	// 	this.domController.write(() => {
	// 		this.setTransition(this.transition)
	// 		this.setTransform([scale(this.adjustScale), translate(this.adjustDeltaX, this.adjustDeltaY)])
	// 	})
	// }

	// private boundCurrentValues() {
	// 	if (this.currentScale <= 1) {
	// 		this.currentScale = 1
	// 		this.currentDeltaX = 0
	// 		this.currentDeltaY = 0
	// 	}

	// 	if (this.adjustScale <= 1) {
	// 		this.adjustScale = 1
	// 		this.adjustDeltaX = 0
	// 		this.adjustDeltaY = 0
	// 	}

	// 	//TODO bound deltas
	// }

	// private setTransform(transforms: string[]) {
	// 	this.renderer.setStyle(this.host.nativeElement, 'transform', transforms.join(' '))
	// }

	// private setTransition(transition: string | null) {
	// 	this.renderer.setStyle(this.host.nativeElement, 'transition', transition)
	// }
}

function scale(scale: number) {
	return `scale(${scale})`
}

function translate(x: number, y: number) {
	return `translate(${x}px, ${y}px)`
}
