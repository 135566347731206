<hum-top-gradient></hum-top-gradient>
<ion-content [fullscreen]="true">
	<div class="page hum-content">
		<div class="top">
			<div class="image-container" *ngIf="!!type?.image">
				<img class="image" [src]="type?.image" />
			</div>
			<div class="content">
				<div class="block">
					<div class="block__title">{{ 'onboarding.summary.tag_title' | translate }}</div>
					<div class="block__text gradient-text">{{ type?.title }}</div>
				</div>
				<div class="block">
					<div class="block__text">{{type?.text}}</div>
				</div>
				<div class="block">
					<div class="block__title block__title-big">{{ type?.famous_people_title }}:</div>
					<div class="block__text">{{ type?.famous_people | famousPeople | async }}</div>
					<div class="block__people">
						<img
							*ngFor="let person of type?.famous_people"
							[src]="person.image"
							class="person-image"
						/>
					</div>
				</div>
				<div class="block">
					<div class="block__title">{{ 'onboarding.summary.profile_title' | translate }}</div>
					<div class="block__text gradient-text">{{ profile?.title }}</div>
				</div>
				<div class="block">
					<div class="block__text">{{profile?.text}}</div>
				</div>
				<div class="block">
					<div class="block__title block__title-big">{{ profile?.famous_people_title }}:</div>
					<div class="block__text">{{ profile?.famous_people | famousPeople | async }}</div>
					<div class="block__people">
						<img
							*ngFor="let person of profile?.famous_people"
							[src]="person.image"
							class="person-image"
						/>
					</div>
				</div>
				<div class="block">
					<div class="block__text">{{summary?.text}}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="footer">
		<hum-button class="button hum-content" (click)="navigateNext()">
			{{ summary?.button_text }}
		</hum-button>
	</div>
	<hum-bottom-gradient></hum-bottom-gradient>
</ion-content>
