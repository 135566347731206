import { UsersService } from '../services'
import { AuthService, ConfigService } from 'app/core/services'
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/internal/Observable'
import { catchError, switchMap } from 'rxjs/operators'
import { TokenService } from '../services/token.service'
import { of, throwError } from 'rxjs'
import * as Sentry from '@sentry/capacitor'
import { Router } from '@angular/router'

// TODO split token interceptor and error interceptor
@Injectable({
	providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
	constructor(
		private tokenService: TokenService,
		private config: ConfigService,
		private authService: AuthService,
		private router: Router,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		if (!request.withCredentials || !request.url.startsWith(this.config.apiUrl)) {
			return next.handle(request)
		}

		return this.tokenService.get().pipe(
			switchMap(token => {
				const headers: {
					authorization?: string
				} = {}
				if (token) {
					headers.authorization = `Token ${token}`
				}
				request = request.clone({ setHeaders: headers, withCredentials: false })

				return next.handle(request).pipe(
					catchError((e, caught) => {
						if (e instanceof HttpErrorResponse) {
							try {
								Sentry.setContext('http_error', {
									status: e.status,
									url: e.url,
									data: e.error,
									message: e.message,
								})
							} catch (err) {
								console.warn(`[Sentry] — Cannot set http context`)
							}

							switch (e.status) {
								case 401:
									return this.authService.logout().pipe(switchMap(() => of(null)))
								case 402:
									console.warn('apiError', 402)
									this.router.navigate(['/subscription/expired'])
									break
								case 403:
									console.warn('apiError', 403)
									break
								case 0:
								case 502:
								case 503:
								case 504:
									console.warn('internet error', e.status)
									break
							}
							return throwError(e)
						}
						return caught
					}),
				)
			}),
		)
	}
}
