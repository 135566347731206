import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'

export type StorageKeys =
	| 'token'
	| 'user'
	| 'onboarding'
	| 'when_to_notify'
	| 'reason_to_join'
	| 'fcm_token'
	| 'user_id'
	| 'level'
	| 'goals'
	| 'unfinished_step'
	| 'registration_info'
	| 'registration'
	| 'feedback_show'
	| 'composite_popup'
	| 'language'
	| 'customer_id'
	| string

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	get<T>(key: StorageKeys): Observable<T | null> {
		const value = localStorage.getItem(key)
		try {
			const obj = JSON.parse(value) as T | null
			return of(obj)
		} catch {
			return of(null) // TODO reject
		}
	}

	set<T>(key: StorageKeys, value: T): Observable<T> {
		localStorage.setItem(key, JSON.stringify(value))
		return of(value)
	}

	clear(key: StorageKeys): Observable<string> {
		localStorage.removeItem(key)
		return of(undefined)
	}
}
