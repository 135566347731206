import { Component, Directive, HostListener, Input, NgZone, Optional } from '@angular/core'
import { ActivatedRoute, Router, UrlTree } from '@angular/router'
import { IonRouterOutlet, NavController } from '@ionic/angular'
import { IconType } from '../icon/icon.component'

@Directive({
	selector: '[hum-back-button]',
})
export class BackButtonDirective {
	@Input() defaultHref?: string | UrlTree
	@Input('hum-back-button') _: unknown

	constructor(
		@Optional() private routerOutlet: IonRouterOutlet,
		private router: Router,
		private route: ActivatedRoute,
		private zone: NgZone,
		private navCtrl: NavController,
	) {}

	@HostListener('click', ['$event'])
	async onClick() {
		if (this.routerOutlet && this.routerOutlet.canGoBack()) {
			this.navCtrl.setDirection('back')
			return this.routerOutlet.pop()
		}

		this.zone.run(() => {
			let route: UrlTree
			if (this.defaultHref instanceof UrlTree) {
				route = this.defaultHref
			} else {
				const relativeTo = this.defaultHref.startsWith('/') ? undefined : this.route
				route = this.router.createUrlTree([this.defaultHref], { relativeTo })
			}

			return this.navCtrl.navigateBack(route)
		})
	}
}

// Взято https://stackoverflow.com/a/66504406
@Component({
	selector: 'hum-back-button',
	templateUrl: './back-button.component.html',
	styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent extends BackButtonDirective {
	@Input() icon: IconType = 'arrow_left'
}
