import {
	ComponentFactoryResolver,
	ComponentRef,
	ElementRef,
	Injectable,
	ViewContainerRef,
} from '@angular/core'
import { TooltipPosition, TooltipRef } from 'app/theme/components'
import { BehaviorSubject, ReplaySubject, forkJoin, of } from 'rxjs'
import { distinctUntilKeyChanged, filter, first, map, switchMap, tap } from 'rxjs/operators'
import { StorageService } from './storage.service'
import { RemoteConfigService } from './remote-config.service'
import { AnalyticsService } from './analytics.service'

export class Tooltip {
	id: string
	position: TooltipPosition
	viewContainerRef: ViewContainerRef
	origin: ElementRef<HTMLElement>
	tooltipAwaiter: TooltipAwaiter
}

const STORAGE_KEY = 'shown_tooltips'

@Injectable({
	providedIn: 'root',
})
export class TooltipService {
	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private storage: StorageService,
		private remoteConfig: RemoteConfigService,
		private analytics: AnalyticsService,
	) {
		this.subscribeToQueue()
	}

	shownTooltips: string[] = []

	currentTooltip?: ComponentRef<TooltipRef>
	tooltipQueue = new BehaviorSubject<Tooltip[]>([])

	isTooltipsEnabled = false

	async init() {
		return forkJoin({
			tooltips: this.storage.get<string[]>(STORAGE_KEY),
			enabled: this.remoteConfig.getBool('tooltips_enabled'),
		})
			.pipe(
				tap(({ tooltips, enabled }) => {
					this.isTooltipsEnabled = enabled
					this.shownTooltips = tooltips || []

					this.analytics.updateUserProperties({
						ab_tooltips_enabled: enabled,
					})
				}),
			)
			.toPromise()
	}
	addToQueue(tooltip: Tooltip): void {
		this.tooltipQueue.next([...this.tooltipQueue.getValue(), tooltip])
	}

	removeTooltip(tooltipId: string): void {
		if (this.currentTooltip?.instance.tooltipId === tooltipId) {
			this.currentTooltip.destroy()
			this.currentTooltip = undefined
		}

		this._removeTooltipFromQueue(tooltipId)
	}

	private subscribeToQueue() {
		this.tooltipQueue
			.pipe(
				map(tooltipQueue => tooltipQueue[0]),
				filter(v => !!v),
				distinctUntilKeyChanged('id'),
				switchMap(tooltip => {
					let ready$ = of(true)
					if (tooltip.tooltipAwaiter) {
						ready$ = tooltip.tooltipAwaiter.ready$
					}

					return ready$.pipe(
						filter(v => !!v),
						first(),
						map(() => tooltip),
					)
				}),
			)
			.subscribe(tooltip => {
				this.showTooltip(tooltip)
			})
	}

	private async showTooltip(tooltip: Tooltip) {
		const tooltipComponent = await import('app/theme/components/tooltip').then(
			m => m.TooltipComponent,
		)
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(tooltipComponent)

		this.currentTooltip = tooltip.viewContainerRef.createComponent(componentFactory)
		const component = this.currentTooltip.instance

		component.tooltipId = tooltip.id
		component.setPosition(tooltip.position)
		component.setOverlayOrigin({ elementRef: tooltip.origin })

		this.currentTooltip.changeDetectorRef.detectChanges()

		await this.saveTooltipShown(tooltip.id)
	}

	private _removeTooltipFromQueue(tooltipId: string) {
		this.tooltipQueue.next(this.tooltipQueue.getValue().filter(tooltip => tooltip.id !== tooltipId))
	}

	checkTooltipAlreadyShown(tooltipId: string) {
		return this.shownTooltips.includes(tooltipId)
	}

	saveTooltipShown(tooltipId: string) {
		this.shownTooltips.push(tooltipId)
		return this.storage.set(STORAGE_KEY, this.shownTooltips).toPromise()
	}
}

@Injectable()
export class TooltipAwaiter {
	ready$ = new ReplaySubject<boolean>(1)

	ready() {
		setTimeout(() => this.ready$.next(true))
	}
}
