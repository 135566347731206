import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
	selector: 'hum-textarea',
	templateUrl: './textarea.component.html',
	styleUrls: ['./textarea.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TextareaComponent),
			multi: true,
		},
	],
})
export class TextareaComponent implements OnInit, ControlValueAccessor {
	@Input() rows = 1
	@Input() placeholder = ''
	@Input() enterkeyhint?: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send'

	@Output() submit = new EventEmitter()
	@Output() next = new EventEmitter()

	value: string
	private onChange = (value: any) => {}
	private onTouched = () => {}
	constructor() {}

	ngOnInit(): void {}

	registerOnChange(fn: any) {
		this.onChange = fn
	}

	registerOnTouched(fn: () => {}): void {
		this.onTouched = fn
	}

	writeValue(outsideValue: string) {
		this.value = outsideValue
	}

	updateValue(insideValue: string) {
		this.value = insideValue
		this.onChange(insideValue)
		this.onTouched()
	}

	onSubmit(event: KeyboardEvent) {
		const key = event.key || event.keyCode
		if (key !== 'Enter' && key !== 13) return
		if (this.enterkeyhint === 'next') {
			this.next.emit()
		} else {
			this.submit.emit()
		}
	}
}
