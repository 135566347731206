const points = {
	43: { id: 43, x: 148, y: 127 },
	17: { id: 17, x: 137, y: 108 },
	11: { id: 11, x: 159, y: 108 },
	4: { id: 4, x: 170, y: 88 },
	24: { id: 24, x: 148, y: 88 },
	47: { id: 47, x: 126, y: 88 },
	63: { id: 63, x: 170, y: 56 },
	61: { id: 61, x: 148, y: 56 },
	64: { id: 64, x: 126, y: 56 },
	26: { id: 26, x: 192, y: 342 },
	51: { id: 51, x: 201, y: 332 },
	21: { id: 21, x: 210, y: 322 },
	40: { id: 40, x: 228, y: 342 },
	2: { id: 2, x: 148, y: 323 },
	46: { id: 46, x: 162, y: 309 },
	25: { id: 25, x: 176, y: 295 },
	13: { id: 13, x: 162, y: 281 },
	1: { id: 1, x: 148, y: 267 },
	7: { id: 7, x: 134, y: 281 },
	10: { id: 10, x: 120, y: 295 },
	15: { id: 15, x: 134, y: 309 },
	8: { id: 8, x: 148, y: 224 },
	33: { id: 33, x: 162, y: 224 },
	31: { id: 31, x: 134, y: 224 },
	20: { id: 20, x: 124, y: 208 },
	16: { id: 16, x: 124, y: 192 },
	62: { id: 62, x: 134, y: 176 },
	23: { id: 23, x: 148, y: 176 },
	56: { id: 56, x: 162, y: 176 },
	35: { id: 35, x: 172, y: 186 },
	12: { id: 12, x: 172, y: 200 },
	45: { id: 45, x: 172, y: 214 },
	30: { id: 30, x: 288, y: 430 },
	55: { id: 55, x: 275, y: 423 },
	49: { id: 49, x: 262, y: 415.5 },
	6: { id: 6, x: 249, y: 408 },
	37: { id: 37, x: 262, y: 400.5 },
	22: { id: 22, x: 275, y: 393 },
	36: { id: 36, x: 288, y: 386 },
	27: { id: 27, x: 124, y: 438 },
	34: { id: 34, x: 124, y: 422 },
	5: { id: 5, x: 134, y: 406 },
	14: { id: 14, x: 148, y: 406 },
	29: { id: 29, x: 162, y: 406 },
	59: { id: 59, x: 172, y: 438 },
	9: { id: 9, x: 162, y: 454 },
	3: { id: 3, x: 148, y: 454 },
	42: { id: 42, x: 134, y: 454 },
	58: { id: 58, x: 124, y: 542 },
	38: { id: 38, x: 124, y: 528 },
	54: { id: 54, x: 124, y: 514 },
	53: { id: 53, x: 134, y: 494 },
	60: { id: 60, x: 148, y: 494 },
	52: { id: 52, x: 162, y: 494 },
	41: { id: 41, x: 172, y: 542 },
	39: { id: 39, x: 172, y: 528 },
	19: { id: 19, x: 172, y: 514 },
	48: { id: 48, x: 8, y: 386 },
	57: { id: 57, x: 21, y: 393 },
	44: { id: 44, x: 34, y: 400.5 },
	50: { id: 50, x: 47, y: 408 },
	32: { id: 32, x: 34, y: 415.5 },
	28: { id: 28, x: 21, y: 423 },
	18: { id: 18, x: 8, y: 430 },
}

export default points
