import { Router } from '@angular/router'
import { AnalyticsService } from './../services/analytics.service'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs'
import { StorageService } from './../services/storage.service'
import { ApiService } from './../api/api.service'
import { EventEmitter, Injectable } from '@angular/core'
import { Profile, ProfileUpdate } from 'app/core/models'
import { v4 as uuid } from 'uuid'
import { TokenService } from './token.service'
import * as Sentry from '@sentry/capacitor'
import { ConfigService } from './config.service'

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	private _customerId: string | null = null
	private _profile: BehaviorSubject<Profile | null> = new BehaviorSubject(null)

	profileUpdate = new EventEmitter()

	constructor(
		private api: ApiService,
		private storageService: StorageService,
		private analytics: AnalyticsService,
		private router: Router,
		private tokenService: TokenService,
		private configService: ConfigService,
	) {}

	async initialise() {
		let customerId = await this.getCustomerId()

		console.debug(`[userService] — customer_id=${customerId}`)

		if (!customerId) {
			customerId = await this.anonymousRegistration()
		} else {
			const token = await this.tokenService.get().toPromise()
			if (!token) {
				await this.registerWithCustomerId(customerId)
			}
		}

		await this.updateCustomerId(customerId)

		await this.fetchProfile().toPromise()
	}

	async getCustomerId() {
		if (!this._customerId) {
			let customerId = await this.storageService.get<null | string>('customer_id').toPromise()
			if (customerId) {
				this._customerId = customerId
			}
		}

		return this._customerId
	}

	private async anonymousRegistration() {
		console.debug('[userService] — anonymousRegistration')

		let customerId: string

		// if user from onboardings in web
		if (this.configService.webCustomerId) {
			console.debug('[userService] — use customerId from query')
			customerId = this.configService.webCustomerId
		} else {
			customerId = uuid()
		}

		// =================== Миграция существующих юзеров ===================
		// По соглашению с бэкэндом, для всех существующих пользователей
		// customer_id = user_id
		// TODO удалить спустя кучу времени, когда у всех юзеров будет customer_id
		const savedUserId = await this.storageService.get<string>('user_id').toPromise()
		if (savedUserId) {
			customerId = savedUserId
			console.warn('[userService] — migrate user_id to customer_id. customer_id=', customerId)
			return customerId
		}
		// ====================================================================
		await this.registerWithCustomerId(customerId)

		return customerId
	}

	private async registerWithCustomerId(customerId: string) {
		console.debug(`[userService] — register with curstomer_id=${customerId}`)
		const { token } = await this.api
			.post<{ token: string }>(['accounts', 'register'], { customer_id: customerId })
			.toPromise()

		await this.tokenService.set(token).toPromise()
	}

	async updateCustomerId(customerId: string) {
		console.debug(`[userService] — updateCustomerId`, customerId)
		await this.storageService.set('customer_id', customerId).toPromise()
		this._customerId = customerId

		this.analytics.updateCustomerId(customerId)
		try {
			Sentry.setUser({ id: customerId })
		} catch (err) {
			console.error(`[Sentry] — Cannot update customer_id`, err)
		}
	}

	deleteAccount() {
		return this.api.delete(['accounts'])
	}

	// TODO refactoring
	getProfile(force = false) {
		if (!this._profile.getValue() || force) {
			return this.fetchProfile().pipe(switchMap(() => this._profile.asObservable()))
		}

		return this._profile.asObservable()
	}

	fetchProfile(): Observable<Profile | null> {
		return this.storageService.get('token').pipe(
			switchMap(token => {
				if (!token) return of(null)

				return this.api.get<Profile>(['accounts', 'profile']).pipe(
					switchMap(profile =>
						forkJoin([
							this.storageService.set('user_id', profile.id),
							this.storageService.set('user', profile),
						]).pipe(map(() => profile)),
					),
					tap(profile => this._profile.next(profile)),
					tap(profile => this.analytics.setProfile(profile)),
					tap(profile => {
						if (!profile) return

						try {
							Sentry.setUser({
								id: this._customerId,
								first_name: profile.first_name,
								type: profile.tag?.name,
								authority: profile.authority,
								birthdate: profile.birthdate,
								birthplace: profile.birthplace,
								profile: profile.profile?.name,
							})
						} catch (err) {
							console.error(`[Sentry] — Cannot update user profile`, err)
						}
					}),
					tap(() => this.profileUpdate.emit()),
				)
			}),
		)
	}

	hasBodygraph() {
		return this.getProfile().pipe(
			filter(p => !!p),
			map(profile => !!profile.is_bodygraph_calculated),
		)
	}

	updateProfile(profile: ProfileUpdate) {
		return this.api
			.patch<Profile>(['accounts', 'profile'], profile)
			.pipe(switchMap(() => this.fetchProfile()))
	}

	getEmail() {
		return this.api.get<{ status: boolean }>(['accounts', 'email'])
	}

	updateEmail(email: string) {
		return this.api.post<never>(['accounts', 'email'], { email })
	}
	confirmEmailCode(code: number) {
		return this.api.post<StatusResponse>(['accounts', 'email', 'confirm'], { code })
	}

	restoreViaEmail(email: string) {
		return this.api.post<StatusResponse>(['accounts', 'restore'], { email })
	}

	restoreViaEmailConfirmCode(email: string, code: number) {
		return this.api.post<StatusResponse<{ token: string }>>(['accounts', 'restore', 'confirm'], {
			code,
			email,
		})
	}
}

type StatusResponse<T extends object = {}> =
	| ({ success: true } & T)
	| { success: false; error: string }
