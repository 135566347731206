import {
	CdkConnectedOverlay,
	CdkOverlayOrigin,
	ConnectionPositionPair,
	HorizontalConnectionPos,
	VerticalConnectionPos,
} from '@angular/cdk/overlay'
import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	AfterContentInit,
	ChangeDetectorRef,
	OnDestroy,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core'
import { TooltipService } from 'app/core/services'
import { ReplaySubject } from 'rxjs'

export type TooltipPosition = 'top' | 'bottom'

const TIP_HEIGHT = 8
const TIP_OFFSET = 12
const TIP_WIDTH = 20
const TOOLTIP_OFFSET = 8

export interface TooltipRef {
	tooltipId: string
	setPosition(position: TooltipPosition): void
	setOverlayOrigin(origin: CdkOverlayOrigin): void
	onClose?(): void
}

@Component({
	selector: 'hum-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	host: {
		class: "hum-tooltip"
	}
})
export class TooltipComponent implements AfterContentInit, OnInit, OnDestroy, TooltipRef {
	private destroyed$ = new ReplaySubject(1)

	@Input() tooltipId: string

	constructor(private tooltipService: TooltipService, private cdr: ChangeDetectorRef) {}

	@ViewChild('overlay', { static: false }) overlay!: CdkConnectedOverlay

	origin!: CdkOverlayOrigin
	positions = []
	overlayRef: any

	showTooltip = true

	ngOnInit(): void {}

	ngAfterContentInit() {
		this.cdr.markForCheck()
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true)
		this.destroyed$.complete()
	}

	setPosition(position: TooltipPosition) {
		let originX: HorizontalConnectionPos = 'center'
		let originY: VerticalConnectionPos
		let overlayY: VerticalConnectionPos

		let offsetY = 0
		let offsetX = TIP_OFFSET + TIP_WIDTH / 2

		switch (position) {
			case 'top':
				originY = 'top'
				overlayY = 'bottom'
				offsetY = -(TIP_HEIGHT + TOOLTIP_OFFSET)
				break
			case 'bottom':
				originY = 'bottom'
				overlayY = 'top'
				offsetY = TIP_HEIGHT + TOOLTIP_OFFSET
				break
		}

		this.positions = [
			new ConnectionPositionPair(
				{ originX, originY },
				{ overlayX: 'center', overlayY },
				0,
				offsetY,
				[`tooltip-position-center`, `tooltip-position-${position}`],
			),
			new ConnectionPositionPair(
				{ originX, originY },
				{ overlayX: 'start', overlayY },
				-offsetX,
				offsetY,
				[`tooltip-position-start`, `tooltip-position-${position}`],
			),
			new ConnectionPositionPair(
				{ originX, originY },
				{ overlayX: 'end', overlayY },
				offsetX,
				offsetY,
				[`tooltip-position-end`, `tooltip-position-${position}`],
			),
		]
	}

	setOverlayOrigin(origin: CdkOverlayOrigin): void {
		this.origin = origin
	}

	positionChange($event) {}

	closeTooltip() {
		this.tooltipService.removeTooltip(this.tooltipId)
	}
}
