import { Component, Input, OnInit } from '@angular/core';
import { IconType } from '../icon/icon.component';

@Component({
  selector: 'hum-tab-button',
  templateUrl: './tab-button.component.html',
  styleUrls: ['./tab-button.component.scss']
})
export class TabButtonComponent implements OnInit {

  @Input() icon: IconType
  @Input() name: string
  constructor() { }

  ngOnInit(): void {
  }

}
