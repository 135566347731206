import { Directive, HostListener, Input } from '@angular/core'

@Directive({
	selector: '[humClickStopPropagation]',
})
export class ClickStopPropagationDirective {
	constructor() {}

  @Input('humClickStopPropagation') _

	@HostListener('click', ['$event'])
	public onClick(event: Event): void {
		event.stopPropagation()
	}
}
