import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export function toISO8601(date: Date) {
	return format(date, `yyyy-MM-dd'T'HH:mm:ssXXX`)
}

export function utcAsLocal(date: Date | string) {
	return utcToZonedTime(date, 'UTC')
}

// export function localAsUTC(date: Date | string) {
// 	return zonedTimeToUtc(date, 'UTC')
// }
