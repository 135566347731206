import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Output,
	EventEmitter,
	Input,
	ChangeDetectorRef,
	HostBinding,
} from '@angular/core'
import centers, { Center } from 'app/bodygraph/data/centers'
import lines from 'app/bodygraph/data/lines'
import points from 'app/bodygraph/data/points'

export const BODYGRAPH_ROOT_HEIGHT = 550
export const BODYGRAPH_ROOT_WIDTH = 296

let bodygraphUniqueId = 0

@Component({
	selector: 'hum-bodygraph-root',
	templateUrl: './bodygraph-root.component.html',
	styleUrls: ['./bodygraph-root.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodygraphRootComponent implements OnInit {
	_id = `bodygraph-${bodygraphUniqueId++}`

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {}

	private redGates = new Set<number>()
	private blackGates = new Set<number>()
	private allGates = new Set<number>()

	@Input('gates')
	set gates(gates: { red_gates: number[]; black_gates: number[] }) {
		this.redGates = new Set(gates.red_gates || [])
		this.blackGates = new Set(gates.black_gates || [])

		this.allGates = new Set([...this.redGates, ...this.blackGates])

		this.cdr.markForCheck()
	}
	@Input() groups: BodygraphGroups | null = null
	@Input('centers') _centers: number[] | null = null // TODO remove null
	@Input() color: BodygraphColor = 'default'

	@HostBinding('class')
	get hostClass() {
		return `color-${this.color}`
	}

	// data
	get viewBox() {
		return `0 0 ${BODYGRAPH_ROOT_WIDTH} ${BODYGRAPH_ROOT_HEIGHT}`
	}

	get lines() {
		return lines.map(line => this.getLineContext(line))
	}

	centers = centers

	get points() {
		return this.getPoints()
	}

	getCenterFill(center: Center) {
		return this.isCenterActive(center) ? center.c : '#ffffff'
	}

	isCenterActive(center: Center) {
		if (this._centers === null) {
			// TODO remove this fallback after composites bodygraph update
			return center.p.some(([p1, p2]) => this.allGates.has(p1) && this.allGates.has(p2))
		}

		return this._centers.includes(center.id)
	}

	getLineContext(line) {
		const { p1, p2 } = line

		return {
			p1: this.getLinePoint(line.p1),
			p2: this.getLinePoint(line.p2),
			len: this.getLineLength(p1.x, p1.y, p2.x, p2.y),
			angle: this.getLineAngle(p1.x, p1.y, p2.x, p2.y) - 90,
			mx: (p1.x + p2.x) / 2,
			my: (p1.y + p2.y) / 2,
		}
	}

	getLinePoint(point: LinePoint) {
		return {
			...point,
			red: this.redGates.has(point.id),
			black: this.blackGates.has(point.id),
		}
	}

	getPoints() {
		const R = 5.75

		return Object.keys(points).map(id => {
			const { x, y } = points[id]
			const hasRed = this.redGates.has(Number(id))
			const hasBlack = this.blackGates.has(Number(id))
			const active = hasBlack || hasRed

			return {
				id,
				x,
				y,
				active,
				fillGradient: this.getGradient(hasRed, hasBlack),
				R,
				stroke: active ? '#C7C7C7' : 'rgba(33, 33, 33, 0.25)',
				textColor: active ? 'white' : '#212121',
				strokeWidth: active ? 2 : 0.5,
			}
		})
	}

	getTransform(angle, x, y) {
		return `rotate(${angle} ${x} ${y})`
	}

	getGradient(red: boolean, black: boolean): Gradients {
		if (black && red) return 'black-red'
		if (black) return 'black'
		if (red) return 'red'
		return 'none'
	}

	getGroupsBlocks() {
		if (!this.groups) return []

		return Object.entries(this.groups)
			.filter(([_, v]) => !!v)
			.map(([key, value]) => ({
				position: key,
				...value,
			}))
	}

	getGradientUrl(name: Gradients) {
		return `url(#${this.getGradientId(name)})`
	}

	getGradientId(name: Gradients) {
		return `${this._id}-${name}`
	}

	private getLineLength(x1, y1, x2, y2) {
		return Math.sqrt(x1 * x1 - 2 * x1 * x2 + x2 * x2 + y1 * y1 - 2 * y1 * y2 + y2 * y2)
	}

	private getLineAngle(x1, y1, x2, y2) {
		return Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI)
	}
}

type Gradients = 'none' | 'red' | 'black' | 'black-red'

export type BodygraphColor = 'default' | 'composite' | 'transit'

export type LinePoint = {
	x: number
	y: number
	id: number
}

export type Group = Planet & {
	arrow: 'right' | 'left'
}

export type BodygraphGroups = {
	left_top: Group | null
	left_bottom: Group | null
	right_top: Group | null
	right_bottom: Group | null
}

export interface Planet {
	name: string
	gate: number
	line: number
	color: number
	tone: number
	base: number
}
