import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'urlBackground',
})
export class UrlBackgroundPipe implements PipeTransform {
	transform(value: string): string {
		return `url(${value})`
	}
}
