import { ReplaySubject } from 'rxjs'
import { PLANETS } from './../../data/planets'
import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	HostBinding,
	Input,
	ElementRef,
	InjectionToken,
	forwardRef,
	Inject,
	OnDestroy,
	ChangeDetectorRef,
	ViewChild,
	Renderer2,
	AfterContentInit,
} from '@angular/core'
import { BodygraphViewComponent, BODYGRAPH_VIEW } from '../bodygraph-view'
import { takeUntil } from 'rxjs/operators'
import { animations } from './animation'

export const BODYGRAPH_DETAIL = new InjectionToken('BODYGRAPH_DETAIL')

type Planet = {
	gate: number
	line?: number
	name: string
}

@Component({
	selector: 'hum-bodygraph-details-left, hum-bodygraph-details-right',
	templateUrl: './bodygraph-details.component.html',
	styleUrls: ['./bodygraph-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [animations.details],
	providers: [
		{
			provide: BODYGRAPH_DETAIL,
			useExisting: forwardRef(() => BodygraphDetailsComponent),
		},
	],
})
export class BodygraphDetailsComponent implements OnInit, OnDestroy, AfterContentInit {
	private destroyed$ = new ReplaySubject(1)

	constructor(
		public host: ElementRef<HTMLElement>,
		private cdr: ChangeDetectorRef,
		@Inject(BODYGRAPH_VIEW) private bodygraph: BodygraphViewComponent,
		private renderer: Renderer2,
	) {
		this.isOpened = this.bodygraph.detailsOpen.getValue()
	}

	scaleToFit = 1

	isOpened: boolean

	@ViewChild('content', { static: true }) content: ElementRef<HTMLElement>

	@Input()
	@HostBinding('class.is-reversed')
	reverse = false

	@Input() redPlanets: Planet[] = []
	@Input() blackPlanets: Planet[] = []

	@Input() title?: string

	ngOnInit(): void {
		this.bodygraph.detailsOpen.pipe(takeUntil(this.destroyed$)).subscribe(open => {
			this.isOpened = open
			this.cdr.markForCheck()
		})
	}

	ngAfterContentInit(): void {
		setTimeout(() => {
			// force recalculation of scale
			this.cdr.markForCheck()
		})
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true)
		this.destroyed$.complete()
	}

	@HostBinding('@detailsHide')
	get detailsHideAnimation() {
		return this.isOpened ? this._getPositionFromTag() : 'hide'
	}

	getDetailWidth() {
		return this.content.nativeElement.getBoundingClientRect().width
	}

	get planets() {
		return PLANETS.map(name => ({
			name,
			red: this.redPlanets.find(p => p.name === name) || null,
			black: this.blackPlanets.find(p => p.name === name) || null,
		}))
	}

	getFitScale() {
		const hostHeight = this.host.nativeElement.getBoundingClientRect().height
		const contentHeight = this.content.nativeElement.offsetHeight

		let scale = Math.min(1, hostHeight / contentHeight)

		return scale
	}

	_getPositionFromTag() {
		const tag = this.host.nativeElement.tagName.toLowerCase()
		return tag.endsWith('left') ? 'left' : 'right'
	}
}
