import { Injectable } from '@angular/core'
import { App } from '@capacitor/app'
import { environment } from '@env'
import { TranslateService } from '@ngx-translate/core'
import { isNative } from '../utils'
import { StorageService } from './storage.service'

@Injectable({
	providedIn: 'root',
})
export class AlertService {
	constructor(private translate: TranslateService, private storage: StorageService) {}

	async alert(code: number | null, err?: Error): Promise<void>
	async alert(message: string, err?: Error): Promise<void>
	// https://stackoverflow.com/a/66510061
	async alert(messageOrCode: string | number | null, err?: Error)
	async alert(messageOrCode: string | number | null, err?: Error) {
		let message = null
		if (typeof messageOrCode === 'number') {
			const _message = await this.translate.get(`errors.code.${messageOrCode}`).toPromise()
			message = `${_message}\nCode: ${messageOrCode}`
		} else if (messageOrCode === null) {
			message = await this.translate.get(`errors.unknown`).toPromise()
		} else {
			message = messageOrCode
		}

		await this._alert(message, err)
	}

	private async _alert(message: string, err?: Error) {
		const customerId = await this.storage.get<string>('customer_id').toPromise()
		const appVersion = await this.getAppVersion()

		const messageWithAdditionalInfo = [
			message,
			`user_id: ${customerId}`,
			`app_version: ${appVersion}`,
		].join('\n')

		console.error(`[Error] — ${messageWithAdditionalInfo}`, err)

		alert(messageWithAdditionalInfo)
	}

	private async getAppVersion() {
		if (isNative) {
			const appInfo = await App.getInfo()
			return appInfo.version + ' (' + appInfo.build + ')'
		} else {
			return environment.appVersion
		}
	}
}
