<hum-top-gradient></hum-top-gradient>
<ion-content [fullscreen]="true">
	<div class="page hum-content">
		<div class="top">
			<div class="title">
				{{ 'onboarding.notifications.title' | translate }}
			</div>

			<div class="selector">
				<hum-selector-item
					class="selector-item"
					*ngFor="let time of times"
					[text]="'onboarding.notifications.times.' + time.text | translate"
					(click)="onTimeClick(time.value)"
					[checked]="isTimeActive(time.value)"
				>
				</hum-selector-item>
			</div>
		</div>
	</div>
	<div class="footer">
		<hum-button [disabled]="!isFormValid" class="button hum-content" (click)="navigateNext()">
			{{ 'onboarding.notifications.next_button' | translate }}
		</hum-button>
	</div>
	<hum-bottom-gradient></hum-bottom-gradient>
</ion-content>
