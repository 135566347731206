import { Component } from '@angular/core'
import { AnalyticsService } from 'app/core/services'
import { Onboarding, OnboardingService } from '../../../../services/onboarding'
import { ViewDidEnter } from '@ionic/angular'

@Component({
	selector: 'hum-level',
	templateUrl: './level.component.html',
	styleUrls: ['./level.component.scss'],
})
export class LevelComponent implements ViewDidEnter {
	onboarding: Onboarding | null

	constructor(private onboardingService: OnboardingService, private analytics: AnalyticsService) {
		this.onboardingService.intro_data.subscribe((onboarding) => {
			this.onboarding = onboarding
		})
	}

	ionViewDidEnter() {
		this.analytics.logOnboardingEvent('onb_level')
	}

	get screen() {
		return this.onboarding?.level
	}

	get isFormValid() {
		return this.activeLevel !== null
	}

	activeLevel: number | null = null

	navigateNext() {
		if (!this.isFormValid) return
		this.onboardingService.updateProfile({ level: this.activeLevel })

		this.onboardingService.navigateNext()

		this.analytics.logOnboardingEvent('onb_level_selection', {
			// TODO слать слаг вместо текста
			title: this.screen.choices.find((l) => l.id === this.activeLevel)?.display_text,
		})
	}

	isLevelActive(level: number) {
		return level === this.activeLevel
	}

	onLevelClick(level: number) {
		this.activeLevel = level
	}
}
