import { ViewDidEnter, ViewWillLeave } from '@ionic/angular'
import { Component, NgZone } from '@angular/core'
import { OnboardingService } from '../../../../services/onboarding'
import { AnalyticsService, City } from 'app/core/services'
import { Keyboard } from 'app/plugins'

@Component({
	selector: 'hum-birthday-place',
	templateUrl: './birthday-place.component.html',
	styleUrls: ['./birthday-place.component.scss'],
})
export class BirthdayPlaceComponent implements ViewDidEnter, ViewWillLeave {
	constructor(
		private analytics: AnalyticsService,
		private onboardingService: OnboardingService,
		private zone: NgZone,
		private keyboard: Keyboard,
	) {}

	city: City | null = this.onboardingService.profile?.birthday_place || null

	ionViewDidEnter(): void {
		this.analytics.logOnboardingEvent('onb_place')
	}

	ionViewWillLeave() {}

	onCityChange(city: City) {
		this.city = city
	}

	navigateNext() {
		if (!this.city) return

		this.zone.run(() => {
			this.onboardingService.updateProfile({ birthday_place: this.city })
			this.keyboard.hide()
			this.onboardingService.navigateNext()
		})
	}

	get defaultHref() {
		return this.onboardingService.getPrevRoute()
	}
}
