<!-- TODO move to component -->
<div class="modal-header">
	<div class="modal-close" (click)="dismiss()">
		<hum-icon icon="close" size="16"></hum-icon>
	</div>
</div>

<ion-content>
	<div class="page" *humLoading="!article">
		<div class="header">
			<div class="title">{{ article.title }}</div>
			<a [href]="author.hyperlink" class="author" *ngIf="author">
				<img class="author-image" width="48" height="48" [src]="author.image" />
				<div class="author-info">
					<div class="author-name">{{ author.name }}</div>
					<div class="author-link">
						{{ 'article_detail.author_link' | translate: { name: author.hyperlink_text } }}
						<hum-icon class="author-link-icon" icon="chevron_left" size="16"></hum-icon>
					</div>
				</div>
			</a>
		</div>

		<img [src]="article.image" class="image" />
		<hum-text class="text" [text]="article.text" (deeplink)="dismiss()"></hum-text>
	</div>
</ion-content>
