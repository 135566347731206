import { takeUntil } from 'rxjs/operators'
import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { DetailedArticle } from 'app/core/models'
import { ArticlesService } from 'app/core/services'
import { ReplaySubject } from 'rxjs'
import { ModalController } from '@ionic/angular'

@Component({
	selector: 'hum-article-modal',
	templateUrl: './article-modal.component.html',
	styleUrls: ['./article-modal.component.scss'],
})
export class ArticleModalComponent implements OnInit, OnDestroy {
	private destroyed$ = new ReplaySubject(1)

	@Input() articleId: string

	id: number
	article?: DetailedArticle

	dismiss() {
		this.modal.dismiss({})
	}

	constructor(private modal: ModalController, private articlesService: ArticlesService) {}

	ngOnDestroy(): void {
		this.destroyed$.next(true)
		this.destroyed$.complete()
	}

	ngOnInit(): void {
		this.articlesService
			.getArticle(this.articleId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe(article => {
				this.article = article
			})
	}

	get author() {
		return this.article?.author_full
	}
}
