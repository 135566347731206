import {
	Directive,
	ElementRef,
	Input,
	ViewContainerRef,
	OnDestroy,
	ComponentRef,
	AfterContentInit,
	Optional,
} from '@angular/core'
import { TooltipService, Tooltip, TooltipAwaiter } from 'app/core/services'
import { ReplaySubject } from 'rxjs'
import { TooltipPosition } from '../components'
import type { TooltipRef } from '../components/tooltip'

@Directive({
	selector: '[humTooltip]',
})
export class TooltipDirective implements AfterContentInit, OnDestroy {
	private destroyed$ = new ReplaySubject(1)
	@Input('humTooltip') tooltipId: string

	@Input() position: TooltipPosition = 'bottom'
	@Input() tooltipEnabled = true

	componentRef: ComponentRef<TooltipRef>

	constructor(
		private el: ElementRef<HTMLElement>,
		private viewContainerRef: ViewContainerRef,
		private tooltipService: TooltipService,
		@Optional() private tooltipAwaiter: TooltipAwaiter,
	) {}

	ngAfterContentInit() {
		if (!this.tooltipEnabled || !this.tooltipService.isTooltipsEnabled) return

		if (this.tooltipService.checkTooltipAlreadyShown(this.tooltipId)) {
			return
		}

		this.tooltipService.addToQueue(this._createTooltip())
	}

	ngOnDestroy() {
		this.tooltipService.removeTooltip(this.tooltipId)
		this.destroyed$.next(true)
		this.destroyed$.complete()
	}

	private _createTooltip() {
		const tooltip = new Tooltip()
		tooltip.id = this.tooltipId
		tooltip.position = this.position
		tooltip.viewContainerRef = this.viewContainerRef
		tooltip.origin = this.el
		tooltip.tooltipAwaiter = this.tooltipAwaiter

		return tooltip
	}
}
