import { Injectable, Injector } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { I18N_DEFAULT_LANG } from 'app/i18n'

// https://stackoverflow.com/a/67153551
@Injectable()
export class LangInterceptor implements HttpInterceptor {
	constructor(private readonly injector: Injector) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let lang = I18N_DEFAULT_LANG

		try {
			const translate = this.injector.get(TranslateService)
			lang = translate.currentLang
		} catch {
			// translation service is not yet available
		}

		if (lang) {
			request = request.clone({
				headers: request.headers.set('X-User-Language', lang),
			})
		}
		return next.handle(request)
	}
}
