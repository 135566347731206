import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { ApiInterceptor } from './api.interceptor'
import { ApiService } from './api.service'
import { LangInterceptor } from './lang.interceptor'
import { PlatformInterceptor } from './platform.interceptor'
import { VersionInterceptor } from './version.interceptor'
import { RetryInterceptor } from './retry.interceptor'

@NgModule({
	imports: [CommonModule, HttpClientModule, TranslateModule.forChild({ extend: true })],
	exports: [HttpClientModule],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RetryInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LangInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: PlatformInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: VersionInterceptor,
			multi: true,
		},
		ApiService,
	],
})
export class ApiModule {}

export { ApiService }
