import { forkJoin, from, timer } from 'rxjs'
import { NavController, ViewDidEnter, ViewWillEnter } from '@ionic/angular'
import { OnboardingService } from '../../../../services/onboarding'
import { Component } from '@angular/core'
import { AlertService, AnalyticsService, SpinnerService } from 'app/core/services'
import { TranslateService } from '@ngx-translate/core'
import { switchMap, takeUntil } from 'rxjs/operators'

@Component({
	selector: 'hum-confirm',
	templateUrl: './confirm.component.html',
	styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements ViewDidEnter {
	constructor(
		private onboardingService: OnboardingService,
		private spinner: SpinnerService,
		private navCtrl: NavController,
		private analytics: AnalyticsService,
		private translate: TranslateService,
		private alertService: AlertService,
	) {}

	ionViewDidEnter() {
		this.analytics.logOnboardingEvent('onb_check')
		this.loading = false
	}

	get profile() {
		return this.onboardingService.profile
	}

	loading = false

	async save() {
		this.loading = true

		try {
			const uploadAndLoad$ = from(this.onboardingService.uploadProfile()).pipe(
				switchMap(() => this.onboardingService.fetchIntro()),
			)

			await forkJoin([uploadAndLoad$, timer(2000)]).toPromise()

			this.analytics.logOnboardingEvent('onb_registration', { status: 'success' })

			await this.onboardingService.navigateNext()
		} catch (err) {
			this.analytics.logOnboardingEvent('onb_registration', { status: 'fail', error: JSON.stringify(err) })
			this.alertService.alert(-12, err)
		} finally {
			this.loading = false
		}
	}

	async onError() {
		const route = this.onboardingService.getFirstStageScreenRoute('registration')
		this.navCtrl.navigateBack(route)
	}

	get date_format() {
		return this.translate.instant('onboarding.confirm.date_format')
	}
}
