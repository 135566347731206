import {
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
} from '@angular/core'
import {
	animationFrameScheduler,
	BehaviorSubject,
	combineLatest,
	interval,
	ReplaySubject,
} from 'rxjs'
import {
	distinctUntilChanged,
	endWith,
	finalize,
	map,
	switchMap,
	takeUntil,
	takeWhile,
} from 'rxjs/operators'

function easeInOutSine(x: number): number {
	return -(Math.cos(Math.PI * x) - 1) / 2
}

@Directive({
	selector: '[humCountUp]',
})
export class CountUpDirective implements OnInit, OnDestroy {
	private destroy$ = new ReplaySubject(1)
	private readonly count$ = new BehaviorSubject(0)
	private readonly duration$ = new BehaviorSubject(2000)

	private readonly currentCount$ = combineLatest([this.count$, this.duration$]).pipe(
		switchMap(([count, duration]) => {
			// get the time when animation is triggered
			const startTime = animationFrameScheduler.now()

			return interval(0, animationFrameScheduler).pipe(
				// calculate elapsed time
				map(() => animationFrameScheduler.now() - startTime),
				// calculate progress
				map(elapsedTime => elapsedTime / duration),
				// complete when progress is greater than 1
				takeWhile(progress => progress <= 1),
				// apply quadratic ease-out function
				// for faster start and slower end of counting
				map(easeInOutSine),
				// calculate current count
				map(progress => Math.round(progress * count)),
				// make sure that last emitted value is count
				endWith(count),
				distinctUntilChanged(),
				finalize(() => {
					this.animationEnd.emit()
				}),
			)
		}),
	)

	ngOnDestroy(): void {
		this.destroy$.next(true)
		this.destroy$.complete()
	}

	@Input('humCountUp')
	set count(count: number) {
		this.count$.next(count)
	}

	@Input()
	set duration(duration: number) {
		this.duration$.next(duration)
	}

	@Output()
	animationEnd = new EventEmitter()

	constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {}

	ngOnInit(): void {
		this.displayCurrentCount()
	}

	private displayCurrentCount(): void {
		this.currentCount$.pipe(takeUntil(this.destroy$)).subscribe(currentCount => {
			this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', currentCount)
		})
	}
}
