import { IconType } from 'app/theme/components'
import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core'

@Component({
	selector: 'hum-bodygraph-aspect-card',
	templateUrl: './bodygraph-aspect-card.component.html',
	styleUrls: ['./bodygraph-aspect-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodygraphAspectCardComponent implements OnInit {
	@Input()
	@HostBinding('class.disabled')
	disabled = false
	@Input() title = ''
	@Input() text = ''
	@Input() icon?: IconType

	@Input() color: 'type' | 'profile' | 'authority' | 'strategy'

	@HostBinding('class')
	get hostClass() {
		return `color-${this.color}`
	}

	constructor() {}

	ngOnInit(): void {}
}
